import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import {
  FiDollarSign,
  FiDownload,
  FiEye,
  FiMoreHorizontal,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { maskHandler } from '@helpers/maskHandler';

import { IConvenience, IConvenienceFromApi } from './interfaces';

const ListConveniences: FC = () => {
  const { push } = useHistory();

  const [conveniences, setConveniences] = useState<IConvenience[]>();

  useEffect(() => {
    async function loadConveniences() {
      const { data } = await api.get<IConvenienceFromApi[]>(
        `/bbank-pay/conveniences`,
        {
          params: {
            relations: ['client', 'franchisee'],
          },
        },
      );

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IConvenience>(item);
      });

      setConveniences(parsedData);
    }

    const timeout = setTimeout(() => {
      loadConveniences();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Documento do estabelecimento (CPF)',
        selector: 'search',
      },
      {
        name: 'Nome do cliente',
        selector: 'userName',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!conveniences) {
      return [];
    }

    const data = conveniences.map(convenience => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (convenience.status === 'parado') {
        badgeType = 'danger';
      }

      if (convenience.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: convenience.id,
        search: convenience.cpf,
        infinityId: convenience.infinityId,
        userName: convenience.client.name,
        status: <Badge type={badgeType}>{convenience.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() =>
                push(`/bbank-pay/conveniences/view/${convenience.id}`)
              }
              icon={FiEye}
            />
            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(`/bbank-pay/conveniences/${convenience.id}/transactions`)
              }
              icon={FiDollarSign}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [conveniences, push]);

  const handleExportData = useCallback(() => {
    if (!conveniences) {
      return;
    }

    const merchantsLength = conveniences.length;

    const numberFormatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const worksheetData = Array.from(Array(merchantsLength).keys()).map(
      item => {
        const convenience = conveniences[item];

        const worksheetLines = {
          CPF: maskHandler({ mask: 'cpf', payload: convenience.cpf }),
          'Nome do cliente': convenience.client.name,
          'Nome da indicação': convenience.franchisee.name,
          Status: convenience.status,
          'Valor do pagamento': numberFormatter(convenience.paymentValue),
          'Parcelas do pagamento': convenience.paymentPortions,
          'Porcentagem de cashback': `${convenience.cashbackPercentage}%`,
          'Código postal': maskHandler({
            payload: convenience.client.zipcode,
            mask: 'cep',
          }),
          Endereço: convenience.client.adress,
          Cidade: convenience.client.city,
          Estado: convenience.client.state,
          'Complemento do endereço': convenience.complement,
          'Número do endereço': convenience.addressNumber,
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'estabelecimentos_pf',
    });
  }, [conveniences]);

  return (
    <>
      {!conveniences ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['BbankPay', 'Estabelecimentos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos</h1>

                <Dropdown
                  styles={{
                    textColor: '#fff',
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                  }}
                  icon={FiMoreHorizontal}
                  isDisabled={conveniences.length === 0}
                >
                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CPF..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListConveniences };
