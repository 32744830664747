import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiBell, FiRefreshCw } from 'react-icons/fi';
import Loader from 'react-loader-spinner';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { Notification } from './Notification';
import { Container, Background, Counter } from './styles';
import { IUserNotification } from './interfaces';

export const Notifications: FC = () => {
  const [isShown, setIsShown] = useState(false);
  const [userNotifications, setUserNotifications] = useState<
    IUserNotification[]
  >();

  useEffect(() => {
    async function getUserNotifications() {
      const { data } = await api.get<any[]>('/notifications');

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IUserNotification>(item),
      );

      setUserNotifications(parsedData);
    }

    const timer = setTimeout(() => {
      getUserNotifications();
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const handleVisibility = useCallback(() => {
    setIsShown(oldState => !oldState);
  }, []);

  const handleMarkNotificationsAsRead = useCallback(
    async (notificationId: string) => {
      const { data } = await api.patch<any>(`/notifications/${notificationId}`);

      setUserNotifications(oldState => {
        if (!oldState) {
          return undefined;
        }

        const updatedState = oldState.map(item => {
          if (item.id === notificationId) {
            const parsedData = parseObjectPropertiesToCamelCase<IUserNotification>(
              data,
            );

            return parsedData;
          }

          return item;
        });

        return updatedState;
      });
    },
    [],
  );

  const notificationsNotRead = useMemo(() => {
    if (!userNotifications) {
      return undefined;
    }

    const filteredNotifications = userNotifications.filter(
      item => item.read === false,
    );

    return filteredNotifications.length === 0
      ? undefined
      : filteredNotifications;
  }, [userNotifications]);

  const handleReloadNotifications = useCallback(async () => {
    setUserNotifications(undefined);

    async function refreshNotifications() {
      const { data: refreshedNotifications } = await api.get<any[]>(
        '/notifications',
      );

      const parsedData = refreshedNotifications.map(notification =>
        parseObjectPropertiesToCamelCase<IUserNotification>(notification),
      );

      setUserNotifications(parsedData);
    }

    const timer = setTimeout(() => refreshNotifications(), 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <Background isShown={isShown} onClick={handleVisibility} />
      <Container isShown={isShown}>
        <button type="button" onClick={handleVisibility}>
          {notificationsNotRead && (
            <Counter>{notificationsNotRead.length}</Counter>
          )}

          <FiBell />
        </button>

        <div>
          <header>
            <h1>Suas notificações</h1>

            <button type="button" onClick={handleReloadNotifications}>
              <FiRefreshCw />
            </button>
          </header>

          <main>
            {!userNotifications && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Loader
                  type="TailSpin"
                  color="${({ theme }) => theme.colors.secondary}"
                  width={20}
                  height={20}
                />
              </div>
            )}

            {userNotifications &&
              userNotifications.length !== 0 &&
              userNotifications.map(item => (
                <Notification
                  key={item.id}
                  title={item.title}
                  content={item.content}
                  period={new Date(item.createdAt)}
                  read={item.read}
                  onClick={
                    !item.read
                      ? () => handleMarkNotificationsAsRead(item.id)
                      : undefined
                  }
                />
              ))}

            {userNotifications && userNotifications.length === 0 && (
              <div style={{ textAlign: 'center' }}>
                <span style={{ color: '#050505', fontSize: '14px' }}>
                  Não há novas notificações!
                </span>
              </div>
            )}
          </main>

          <footer>
            <span>Clique para marcar como lido</span>
          </footer>
        </div>
      </Container>
    </>
  );
};
