import styled from 'styled-components';

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 30px;
`;

export const Telemedicine = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  padding: 18px 30px;

  background-color: ${({ theme }) => theme.colors.primary};

  color: white;

  width: calc(100% / 2 - 15px);

  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  cursor: pointer;
`;

export const TelemedicineInfo = styled.div`
  > h1 {
    font-size: 25px;

    color: #fcca43;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 8px;

    font-size: 16px;

    > span {
      color: #f5f5f5;
      font-weight: 600;

      > strong {
        margin-left: 4px;
      }
    }
  }
`;
