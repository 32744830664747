export const keys = [
  { value: 'person', label: 'CPF' },
  { value: 'legalPerson', label: 'CNPJ' },
  { value: 'cellphone', label: 'Celular' },
  { value: 'email', label: 'E-mail' },
  { value: 'random', label: 'Chave Aleatoria' },
];

export const accountType = [
  { value: 'Pessoa jurídica', label: 'Pessoa jurídica' },
  { value: 'Pessoa física', label: 'Pessoa física' },
];
