import { IOption } from '@utils/interfaces';

import { IClient } from './interface';

export const getClientOptions = (clients: IClient[]): IOption[] => {
  return clients.map(item => ({ label: item.name, value: item.id }));
};

export const getClientOldOptions = (clients: IClient[]): IOption[] => {
  return clients.map(item => ({ label: item.name, value: item.id }));
};
