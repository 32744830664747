import {
  FC,
  useCallback,
  useState,
  useRef,
  InputHTMLAttributes,
  useEffect,
} from 'react';
import { FiCheck } from 'react-icons/fi';

import { consoleLog } from '@helpers/consoleLog';

import { Container } from './styles';

type IInputCheckboxProps = InputHTMLAttributes<HTMLInputElement>;

const Checkbox: FC<IInputCheckboxProps> = ({ ...rest }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isChecked, setIsChecked] = useState(!!rest.checked);

  useEffect(() => {
    if (rest.checked !== undefined) {
      setIsChecked(rest.checked);
    }
  }, [rest.checked]);

  const handleChecked = useCallback(() => {
    // if (!checkboxRef.current) {
    //   return;
    // }
    // setIsChecked(!!checkboxRef.current.checked);
  }, []);

  return (
    <Container isChecked={isChecked} onClick={handleChecked}>
      <input type="checkbox" ref={checkboxRef} {...rest} />
      <FiCheck size={20} />
    </Container>
  );
};

export { Checkbox };
