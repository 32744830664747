import { IOption } from '@utils/interfaces';

export const periodOptions: IOption[] = [
  { label: '6 meses', value: 6 },
  { label: '12 meses', value: 12 },
  { label: '18 meses', value: 18 },
  { label: '24 meses', value: 24 },
  { label: '36 meses', value: 36 },
  { label: '48 meses', value: 48 },
  { label: '60 meses', value: 60 },
  { label: '72 meses', value: 72 },
  { label: '84 meses', value: 84 },
  { label: '96 meses', value: 96 },
  { label: '108 meses', value: 108 },
  { label: '120 meses', value: 120 },
  { label: '132 meses', value: 132 },
  { label: '144 meses', value: 144 },
];
