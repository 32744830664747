import { useRouteMatch } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Carousel } from '@components/layouts/Carousel';

import api from '@services/bbankApi';

import { IParams, ISliders } from './interfaces';
import { Container, Content } from './styles';

export const ImageSlider: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [sliders, setSliders] = useState<ISliders[]>();

  useEffect(() => {
    async function loadNewSliders() {
      const { data } = await api.get('/galleries');

      setSliders(data);
    }

    loadNewSliders();
  }, []);

  return (
    <>
      {!sliders ? (
        <LoadingPage />
      ) : (
        <>
          <Container>
            <Content>
              <Carousel
                imagesUrls={sliders.map(
                  slider =>
                    `${process.env.REACT_APP_API_URL}/files/${slider.image}`,
                )}
              />
            </Content>
          </Container>
        </>
      )}
    </>
  );
};
