import { FC, useCallback, useRef, useState, useEffect, useMemo } from 'react';
import {
  FiUser,
  FiMail,
  FiGift,
  FiPhone,
  FiSmartphone,
  FiMapPin,
  FiHome,
  FiMap,
  FiFlag,
  FiTag,
  FiSave,
  FiHash,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { useRouteMatch } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Button } from '@components/elements/Button';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';
import { IZipcodeResponse } from '@services/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import { IFormData, IUser, IParams, IClientStatus } from './interfaces';
import { statesOptions, personTypeOptions } from './selectOptions';
import { formValidation } from './validations';

const SharedClientDetails: FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { user: authUser } = useAuth();
  const { params } = useRouteMatch<IParams>();

  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    async function loadUser() {
      const { data } = await api.get(`/users/${params.userId}`);

      setUser(data);
    }

    const timer = setTimeout(() => loadUser(), 1500);

    return () => clearInterval(timer);
  }, [params]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const valuesWithoutMask = removeInputMask();

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        delete parsedData.confirmPassword;

        await api.put(`/users-inicaded/${authUser.id}`, parsedData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Cliente adicionado com sucesso!',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, authUser.id],
  );

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const cityInputRef = formRef.current?.getFieldRef('city');
        const addressInputRef = formRef.current?.getFieldRef('address');

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro } = data;

        // setAddressInformation({
        //   address: logradouro,
        //   city: localidade,
        //   zipcode: cep,
        // });

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;

        const stateInputRef = formRef.current?.getFieldRef('state');

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  const clientStatus = useMemo(() => {
    return {
      ativo: {
        text: 'Ativo',
        badge: 'success',
      },
      pendente: {
        text: 'Pendente',
        badge: 'warning',
      },
    } as IClientStatus;
  }, []);

  return (
    <>
      {!user ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Clientes', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Dados cadastrais do cliente selecionado</h1>

                <Badge type={clientStatus[user.status].badge}>
                  {clientStatus[user.status].text}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={user}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Nome do cliente</label>
                      <Input
                        name="name"
                        icon={FiUser}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Email do cliente</label>
                      <Input
                        type="email"
                        name="email"
                        icon={FiMail}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de aniversário do cliente</label>
                      <InputMask
                        mask="99/99/9999"
                        name="birthdate"
                        icon={FiGift}
                        noUnmask
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Número de telefone do cliente</label>
                      <InputMask
                        mask="(99) 9999-9999"
                        name="phone"
                        icon={FiPhone}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número de celular do cliente</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        name="cellphone"
                        icon={FiSmartphone}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP (código postal)</label>
                      <InputMask
                        mask="99999-999"
                        name="zipcode"
                        icon={FiMapPin}
                        onBlur={e => handleFindZipcode(e.target.value)}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço do cliente</label>
                      <Input
                        name="address"
                        icon={FiHome}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade residencial do cliente</label>
                      <Input
                        name="city"
                        icon={FiMap}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado residencial do cliente</label>
                      <Select
                        options={statesOptions}
                        name="state"
                        icon={FiFlag}
                        readonly={user.status === 'ativo'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo de documentação (CPF/CNPJ)</label>
                      <Select
                        name="person_type"
                        icon={FiTag}
                        options={personTypeOptions}
                        readonly={user.status === 'ativo'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Documento (CPF/CNPJ) do cliente</label>
                      <InputMask
                        name="document"
                        mask="99999999999999"
                        icon={FiHash}
                        readOnly={user.status === 'ativo'}
                      />
                    </InputGroup>
                  </FormRow>

                  {user.status === 'ativo' && (
                    <FormRow buttonWrapper>
                      <Button styleType="success" type="submit" icon={FiSave}>
                        Salvar alterações
                      </Button>
                    </FormRow>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { SharedClientDetails };
