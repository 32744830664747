import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiDownload, FiEye, FiMoreHorizontal } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';
import { format } from 'date-fns';

import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { maskHandler } from '@helpers/maskHandler';

import { ICreditPerson, IParsedStatus } from './interfaces';

export const ListCreditsPerson: FC = () => {
  const { push } = useHistory();

  const [researchs, setResearchs] = useState<ICreditPerson[]>();

  useEffect(() => {
    async function loadCredits() {
      const { data } = await api.get<any[]>('/bot/credits/person');

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<ICreditPerson>(item),
      );

      setResearchs(parsedData);
    }

    const timer = setTimeout(() => loadCredits(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleGoToDetailsPage = useCallback(
    (creditPersonId: string) => {
      push(`/bots/credits/person/${creditPersonId}/details`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Documento',
        selector: 'search',
      },
      {
        name: 'Valor do crédito',
        selector: 'amount',
      },
      {
        name: 'Período de pagamento',
        selector: 'paymentPeriod',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!researchs) {
      return [];
    }

    const numberFomatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const data = researchs.map(item => {
      return {
        id: item.id,
        search: maskHandler({ payload: item.document, mask: 'cpf' }),
        amount: numberFomatter(item.amount),
        paymentPeriod: `${item.paymentPeriod} meses`,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              icon={FiEye}
              title="Ver detalhes"
              onClick={() => handleGoToDetailsPage(item.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [handleGoToDetailsPage, researchs]);

  const parsedStatus = useMemo<IParsedStatus>(() => {
    return {
      active: {
        badgeStyle: 'success',
        badgeText: 'Ativo',
      },
      pending: {
        badgeStyle: 'warning',
        badgeText: 'Pendente',
      },
      denied: {
        badgeStyle: 'danger',
        badgeText: 'Negado',
      },
      contract_pending: {
        badgeStyle: 'info',
        badgeText: 'Contrato pendente',
      },
    };
  }, []);

  const handleExportData = useCallback(() => {
    if (!researchs) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const worksheetCells = researchs.map(item => {
      return {
        'Valor do crédito': numberFormat(item.amount),
        'Período de pagamento': `${item.paymentPeriod} meses`,
        Documento: maskHandler({ mask: 'cpf', payload: item.document }),
        'Atividade de trabalho': item.workActivity,
        'Renda mensal': numberFormat(item.monthlyIncome),
        'Código postal (CEP)': maskHandler({
          mask: 'cep',
          payload: item.zipcode,
        }),
        Celular: maskHandler({ mask: 'cellphone', payload: item.cellphone }),
        Email: item.email,
        'Banco que se relaciona': item.bank,
        'Propósito do crédito': item.creditPurpose,
        'Tem cartão de crédito': item.creditCard ? 'Sim' : 'Não',
        'Tem conta de luz no nome': item.electricityBillInYourName
          ? 'Sim'
          : 'Não',
        'Garantia necessária': item.guaranteeNeeded,
        Status: parsedStatus[item.status].badgeText,
        'Solicitado em': format(new Date(item.createdAt), 'dd/MM/yyyy'),
      };
    });

    exportFromJSON({
      data: worksheetCells,
      exportType: 'xls',
      fileName: 'Pesquisa BOTs para créditos (PF)',
    });
  }, [researchs, parsedStatus]);

  return (
    <>
      {!researchs ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Pesquisas do bot', 'Lista', 'Pessoa física']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Pesquisas feitas pelo bot (PF)</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c0504d' }}
                  icon={FiMoreHorizontal}
                  isDisabled={researchs.length === 0}
                >
                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
