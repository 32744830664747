import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > button,
  > a {
    padding: 5px 10px;

    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;

    font-size: 16px;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${({ theme }) => shade(0.25, theme.colors.secondary)};
    }
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 30px;
`;

export const Telemedicine = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  padding: 18px 30px;

  background-color: ${({ theme }) => theme.colors.secondary};

  color: white;

  width: calc(100% / 2 - 15px);

  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  cursor: pointer;

  transition: transform 0.4s, background-color 0.4s;

  &:hover {
    transform: scale(1.05);
    background-color: ${({ theme }) => shade(0.25, theme.colors.secondary)};
  }
`;

export const TelemedicineInfo = styled.div`
  > h1 {
    font-size: 25px;

    color: #fcca43;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 8px;

    font-size: 16px;

    > span {
      color: #f5f5f5;
      font-weight: 600;

      > strong {
        margin-left: 4px;
      }
    }
  }
`;
