import { FC, useState, useCallback, MouseEvent } from 'react';
import { createContext } from 'use-context-selector';

interface IMenuContext {
  menuType: string;
  handleChangeOfMenus(menu: string, e: MouseEvent): void;
}

export const MenuContext = createContext<IMenuContext>({} as IMenuContext);

const MenuProvider: FC = ({ children }) => {
  const [menuType, setMenuType] = useState('');

  const handleChangeOfMenus = useCallback((menu: string, e: MouseEvent) => {
    e.preventDefault();
    setMenuType(menu);
  }, []);

  return (
    <MenuContext.Provider value={{ menuType, handleChangeOfMenus }}>
      {children}
    </MenuContext.Provider>
  );
};

export { MenuProvider };
