import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IBillet } from './interfaces';

export const ListBilletsPayed: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [billets, setBillets] = useState<IBillet[]>();

  useEffect(() => {
    async function loadBillets() {
      const { data } = await api.get<any[]>(`/billets/payers/${user.id}`, {
        params: {
          relations: ['assignor'],
        },
      });

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IBillet>(item),
      );

      setBillets(parsedData);
    }

    const timer = setTimeout(() => loadBillets(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Boleto gerado em',
        selector: 'createdAt',
      },
      {
        name: 'Boleto feito por',
        selector: 'assignorName',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleToDetailsPage = useCallback(
    (billetId: string) => {
      push(`/sellers/bwallet/billets/${billetId}/details`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!billets) {
      return [];
    }

    const data = billets.map(billet => {
      return {
        id: billet.integrationId,
        createdAt: format(new Date(billet.createdAt), 'dd/MM/yyyy'),
        assignorName: billet.assignor.name,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="info"
              onClick={() => handleToDetailsPage(billet.integrationId)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [billets, handleToDetailsPage]);

  return (
    <>
      {!billets ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Boletos', 'Boletos pagos/a pagar']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Boletos que paguei ou serão pagos por mim</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
