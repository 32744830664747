import { format } from 'date-fns';
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IProspect, ILoadedProspect, IParams } from './interfaces';

const ListProspectsByFranchiseIndication: FC = () => {
  const { push } = useHistory();
  const { params } = useRouteMatch<IParams>();

  const [prospects, setProspects] = useState<IProspect[]>();
  const [searchProspects, setSearchProspects] = useState<IProspect[]>();

  useEffect(() => {
    async function loadProspects() {
      const { data } = await api.get<ILoadedProspect[]>(
        `/prospects/indications/${params.indicationId}`,
      );

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IProspect>(item),
      );

      setProspects(parsedData);
      setSearchProspects(parsedData);
    }

    const timer = setTimeout(() => {
      loadProspects();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.indicationId]);

  const handleGoToDetailsPage = useCallback(
    (prospectId: string) => {
      push(`/franchisees/prospects/${prospectId}/details`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!prospects) {
      return [];
    }

    const data = prospects.map(prospect => {
      return {
        id: prospect.id,
        search: prospect.name,
        email: prospect.email,
        product: <Badge type="info">{prospect.product}</Badge>,
        createdAt: format(new Date(prospect.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              icon={FiEye}
              onClick={() => handleGoToDetailsPage(prospect.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [prospects, handleGoToDetailsPage]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Produto selecionado',
        selector: 'product',
      },
      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  return (
    <>
      {!searchProspects ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Prospectos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Prospectos cadastrados</h1>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListProspectsByFranchiseIndication };
