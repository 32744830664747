import {
  FC,
  useCallback,
  useRef,
  useState,
  FocusEvent,
  useEffect,
  useMemo,
  KeyboardEvent,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { OptionTypeBase } from 'react-select';
import { FiSave } from 'react-icons/fi';

import { Form } from '@components/elements/Form';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { URLPath } from '@components/layouts/UrlPath';
import { InputFile } from '@components/elements/Form/InputFile';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';
import { IZipcodeResponse } from '@services/interfaces';

import { IOption } from '@utils/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeEmptyFields } from '@helpers/removeEmptyFields';

import {
  rolesOptions,
  personTypesOptons,
  statesOption,
  statusOptions,
  loadFranchiseOptions,
} from './selectOptions';
import { formValidation } from './validations';
import {
  IDocumentState,
  IFormData,
  IFranchise,
  IParsedData,
  ITarget,
} from './interfaces';
import JSONObj from '../../../../../../resources/estados-cidades.json';

const NewUser: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [cpfCnpjMask, setCpfCnpjMask] = useState<IDocumentState>(
    '999.999.999-999',
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [indications, setIndications] = useState<IFranchise[]>([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [nicknameFieldShown, setNicknameFieldShown] = useState(false);
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);

  useEffect(() => {
    async function loadFranchises() {
      const { data: loadedFranchises } = await api.get<IFranchise[]>(
        '/franchises',
      );
      const { data: loadedSelers } = await api.get<IFranchise[]>(
        '/users/sellers',
      );

      setIndications([...loadedFranchises, ...loadedSelers]);
    }

    const timer = setTimeout(() => {
      loadFranchises();
      setLoadingPage(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const franchisesOptions = useMemo(() => {
    return loadFranchiseOptions(indications);
  }, [indications]);

  const handleDocumentInputMask = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as ITarget;

      if (target.value.length >= 15) {
        setCpfCnpjMask('99.999.999/9999-99');
      } else {
        setCpfCnpjMask('999.999.999-999');
      }
    },
    [],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const formData: IParsedData = {
        ...data,
      };

      try {
        formRef.current?.setErrors({});

        await formValidation(formData);

        const parsedValues = removeEmptyFields(formData);

        delete parsedValues.confirmPassword;

        setButtonLoading(true);

        const formDataToRequest = new FormData();

        Object.entries(parsedValues).forEach(([name, value]) => {
          formDataToRequest.append(name, value);
        });

        await api.post('/users', formDataToRequest);

        addToast({
          title: 'Dados cadastrados com sucesso!',
          type: 'success',
        });

        reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const { message } = getClientErrors(err.response);

        addToast({
          title: `Algum erro aconteceu!`,
          type: 'error',
          message,
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  const handleFindZipCode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      const { value } = e.target as HTMLInputElement;

      if (!value) {
        return;
      }

      setButtonLoading(true);

      try {
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get<IZipcodeResponse>(`${cep}/json`);

        if (data.erro) {
          addToast({
            title: 'CEP inválido!',
            type: 'info',
          });

          return;
        }

        const { cep: zipcode, logradouro, uf, complemento } = data;

        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const addressInputRef = formRef.current?.getFieldRef('address');
        const stateInputRef = formRef.current?.getFieldRef('state');
        const complementInputRef = formRef.current?.getFieldRef(
          'addressComplement',
        );

        zipcodeInputRef.value = zipcode;
        addressInputRef.value = logradouro;
        complementInputRef.value = complemento;

        const getSelectStateValue = statesOption.find(
          state => state.value.toLowerCase() === uf.toLowerCase(),
        );

        stateInputRef.select.setValue(getSelectStateValue);

        addToast({
          title: 'Endereço preenchido!',
          type: 'success',
        });
      } catch (err: any) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  const handleRoleFieldChange = useCallback((value: OptionTypeBase | null) => {
    if (value === null) {
      return;
    }

    setNicknameFieldShown(
      value.value === 'master' ||
        value.value === 'business' ||
        value.value === 'seller' ||
        value.value === 'client',
    );
  }, []);

  const handleStateFieldBlur = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const { value } = option;

    const state = JSONObj.estados.find(item => item.sigla === value);

    if (!state) {
      setCityOptions([]);
      return;
    }

    const cities = state.cidades;

    const parsedCities = cities.map(city => ({ value: city, label: city }));

    setCityOptions(parsedCities);
  }, []);

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Usuários', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo</h1>
              </CardHeader>

              <CardContent>
                <Form ref={formRef} onSubmit={handleFormSubmit} noValidate>
                  <FormRow>
                    <InputGroup>
                      <label>
                        Permissões
                        <span>*</span>
                      </label>
                      <Select
                        name="role"
                        options={rolesOptions}
                        onChange={handleRoleFieldChange}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Indicação
                        <span>*</span>
                      </label>
                      <Select name="indication" options={franchisesOptions} />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Status
                        <span>*</span>
                      </label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Nome
                        <span>*</span>
                      </label>
                      <Input name="name" />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        E-mail
                        <span>*</span>
                      </label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="email@domain.com"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Data de aniversário
                        <span>*</span>
                      </label>
                      <InputMask
                        mask="99/99/9999"
                        name="birthdate"
                        noUnmask
                        placeholder="99/99/9999"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Telefone
                        <span>*</span>
                      </label>
                      <InputMask
                        name="phone"
                        mask="(99) 9999-9999"
                        placeholder="(99) 9999-9999"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Celular
                        <span>*</span>
                      </label>
                      <InputMask
                        name="cellphone"
                        mask="(99) 9 9999-9999"
                        placeholder="(99) 9 9999-9999"
                      />
                    </InputGroup>

                    {nicknameFieldShown && (
                      <InputGroup>
                        <label>
                          Apelido (link de indicação)
                          <span>*</span>
                        </label>
                        <Input name="nickname" upperCase={false} />
                      </InputGroup>
                    )}
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Código postal (CEP)
                        <span>*</span>
                      </label>
                      <InputMask
                        name="zipcode"
                        mask="99999-999"
                        placeholder="99999-999"
                        onBlur={e => handleFindZipCode(e)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Endereço
                        <span>*</span>
                      </label>
                      <Input name="address" id="address" maxLength={45} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Estado
                        <span>*</span>
                      </label>
                      <Select
                        name="state"
                        id="state"
                        options={statesOption}
                        onChange={handleStateFieldBlur}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Cidade
                        <span>*</span>
                      </label>
                      <Select name="city" id="city" options={cityOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados de acesso</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Senha
                        <span>*</span>
                      </label>
                      <Input
                        name="password"
                        type="password"
                        id="password"
                        upperCase={false}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Confirmar senha
                        <span>*</span>
                      </label>
                      <Input
                        name="confirmPassword"
                        type="password"
                        upperCase={false}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Tipo de cadastro
                        <span>*</span>
                      </label>
                      <Select
                        name="person_type"
                        id="person_type"
                        options={personTypesOptons}
                        placeholder="Pessoa física ou jurídica"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Documento
                        <span>*</span>
                      </label>
                      <InputMask
                        placeholder="CPF/CNPJ"
                        name="document"
                        mask={cpfCnpjMask}
                        onKeyUp={e => handleDocumentInputMask(e)}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Fotos</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Selfie
                        <span>*</span>
                      </label>
                      <InputFile name="selfie" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      loading={buttonLoading}
                      styleType="success"
                      icon={FiSave}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewUser };
