import styled, { css } from 'styled-components';

interface IContainerProps {
  type: 'success' | 'danger' | 'info' | 'warning';
}

const badgeStyles = {
  success: css`
    color: #10ada6;
    background-color: #c9f7f5;
  `,

  danger: css`
    color: #f64e60;
    background-color: #ffe2e5;
  `,

  info: css`
    color: ${({ theme }) => theme.colors.secondary};
    background-color: #eee5ff;
  `,

  warning: css`
    color: #ffa800;
    background-color: #fff4de;
  `,
};

export const Container = styled.span<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.3rem 0.9rem;

  width: auto;

  border-radius: 0.9rem;

  font-weight: 700;

  ${props => badgeStyles[props.type]}
`;
