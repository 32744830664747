import {
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import {
  FiActivity,
  FiFlag,
  FiGift,
  FiHash,
  FiHome,
  FiKey,
  FiLink2,
  FiLock,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSave,
  FiSmartphone,
  FiSmile,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { OptionTypeBase } from 'react-select';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { FormRow } from '@components/elements/Form/FormRow';
import { Button } from '@components/elements/Button';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useToast } from '@hooks/toast';

import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';

import { rolesTranslated } from '@utils/rolesTranslated';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { removeEmptyFields } from '@helpers/removeEmptyFields';

import {
  loadRolesOptions,
  statusOptions,
  personTypesOptons,
  statesOption,
} from './selectOptions';
import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  NicknameInputContainer,
} from './styles';
import { formValidation } from './validations';
import {
  IFormData,
  IUser,
  IRouteProps,
  IFranchise,
  IRole,
  IInputProps,
  IParsedData,
} from './interfaces';

const EditUser: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [roles, setRoles] = useState<IRole[]>();
  const [user, setUser] = useState<IUser>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [indications, setIndications] = useState<IFranchise[]>([]);

  const { addToast } = useToast();
  const { params } = useRouteMatch<IRouteProps>();
  const { id } = params;
  const nicknameInputContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function loadUser() {
      const { data } = await api.get<IUser>(`/users/${id}`);
      const { data: franchiseData } = await api.get<IFranchise[]>(
        '/franchises',
      );
      const { data: sellersData } = await api.get<IFranchise[]>(
        '/users/sellers',
      );
      const { data: loadedRoles } = await api.get<IRole[]>('/roles');

      setIndications([...franchiseData, ...sellersData]);
      setUser({
        ...data,
      });
      setRoles(loadedRoles);
    }

    const timer = setTimeout(() => {
      loadUser();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [id]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      const formData: IParsedData = {
        ...data,
      };

      try {
        formRef.current?.setErrors({});

        // await formValidation(formData);

        const parsedValues = removeEmptyFields(formData);
        const parsedValuesFromInputMask = removeInputMask();

        parsedValuesFromInputMask.forEach(item => {
          parsedValues[item.name] = item.value;
        });

        delete parsedValues.confirmPassword;

        const { data: userUpdated } = await api.put(
          `/users/${id}`,
          parsedValues,
        );

        setUser(userUpdated);

        addToast({
          title: 'Usuário atualizado com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          addToast({
            title: `[${status}] Algum erro aconteceu`,
            type: 'error',
            message,
          });

          return;
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, id],
  );

  const handleFindZipcode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      setButtonLoading(true);

      try {
        const addressInput = document.getElementById('address') as IInputProps;
        const cityInput = document.getElementById('city') as IInputProps;
        const stateInput = document.getElementById('state') as IInputProps;
        const passwordInput = document.getElementById(
          'password',
        ) as IInputProps;
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get(`${cep}/json`);

        addressInput.value = data.logradouro;
        cityInput.value = data.localidade;
        stateInput.value = data.uf;
        passwordInput.focus();
      } catch (err) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  const rolesOptions = useMemo(() => {
    return roles ? loadRolesOptions(roles) : [];
  }, [roles]);

  const franchisesOptions = useCallback((): Array<{
    value: string;
    label: string;
  }> => {
    return indications.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [indications]);

  const optionss = useMemo(() => {
    return franchisesOptions();
  }, [franchisesOptions]);

  const handleRolesSelectChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option || !nicknameInputContainerRef.current) {
        return;
      }

      const { value } = option;

      if (value === 'master' || value === 'business' || value === 'seller') {
        nicknameInputContainerRef.current.style.display = 'block';
      } else {
        nicknameInputContainerRef.current.style.display = 'none';
      }
    },
    [],
  );

  const formatNumber = (value?: number): string => {
    if (value === undefined) {
      return 'R$ 0,00'; // retorna 0 se o valor for undefined
    }

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };
  return (
    <>
      {!user ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Usuário</li>
              <li>{'>'}</li>
              <li>{user.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>{user.name}</h1>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Mensalidade:</span>
                  <span>{formatNumber(user?.monthly_payment)}</span>
                </div>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleSubmit} ref={formRef} initialData={user}>
                  <FormRow>
                    <Select
                      name="role"
                      icon={FiKey}
                      options={rolesOptions}
                      defaultValue={{
                        label: rolesTranslated[user.role],
                        value: user.role,
                      }}
                      onChange={handleRolesSelectChange}
                    />

                    <Select
                      name="indication"
                      placeholder="Indicação"
                      icon={FiSmile}
                      options={optionss}
                    />

                    <Select
                      name="status"
                      icon={FiActivity}
                      options={statusOptions}
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <Input name="name" placeholder="Nome" icon={FiUser} />

                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      icon={FiMail}
                    />

                    <InputMask
                      name="birthdate"
                      mask="99/99/9999"
                      noUnmask
                      defaultValue={
                        user.birthdate
                          ? format(new Date(user.birthdate), 'dd/MM/yyyy')
                          : ''
                      }
                      placeholder="Data de aniversário"
                      icon={FiGift}
                    />
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="(99) 9999-9999"
                      name="phone"
                      placeholder="Telefone"
                      icon={FiPhone}
                    />

                    <InputMask
                      mask="(99) 9 9999-9999"
                      name="cellphone"
                      placeholder="Celular"
                      icon={FiSmartphone}
                    />

                    <NicknameInputContainer
                      ref={nicknameInputContainerRef}
                      style={
                        user.role === 'business' ||
                        user.role === 'master' ||
                        user.role === 'seller'
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <Input
                        name="nickname"
                        placeholder="Apelido"
                        icon={FiLink2}
                        upperCase={false}
                      />
                    </NicknameInputContainer>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="99999-999"
                      name="zipcode"
                      placeholder="CEP"
                      icon={FiMapPin}
                      onBlur={e => handleFindZipcode(e)}
                    />

                    <Input
                      name="address"
                      id="address"
                      placeholder="Endereço"
                      icon={FiHome}
                      maxLength={45}
                    />

                    <Input
                      name="city"
                      id="city"
                      placeholder="Cidade"
                      icon={FiMap}
                      maxLength={45}
                    />

                    <Select
                      name="state"
                      id="state"
                      icon={FiFlag}
                      options={statesOption}
                      placeholder="Estado"
                      maxLength={45}
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados de acesso</h1>
                  </FormRow>

                  <FormRow>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Senha"
                      id="password"
                      icon={FiLock}
                      upperCase={false}
                    />

                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirmar senha"
                      icon={FiLock}
                      upperCase={false}
                    />
                  </FormRow>

                  <FormRow>
                    <Select
                      name="person_type"
                      icon={FiTag}
                      options={personTypesOptons}
                    />

                    <Input
                      name="document"
                      placeholder="CPF/CNPJ"
                      icon={FiHash}
                    />
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      loading={buttonLoading}
                      styleType="success"
                      icon={FiSave}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditUser };
