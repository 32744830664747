/* eslint-disable jsx-a11y/anchor-is-valid */
import { ComponentType, FC, useCallback, useRef, MouseEvent } from 'react';
import { IconBaseProps } from 'react-icons';

import { useMenuTypes } from '@hooks/menu';
import { useAside } from '@hooks/aside';

import { Card } from './styles';

export interface ICardMenuProps {
  icon: ComponentType<IconBaseProps>;
  title: string;
  menuId: string;
}

const CardMenu: FC<ICardMenuProps> = ({ icon: Icon, menuId, title }) => {
  const cardRef = useRef<HTMLLIElement>(null);
  const { handleChangeOfMenus } = useMenuTypes();
  const { handleAsideVisibility } = useAside();

  const handleActiveCards = useCallback(() => {
    const allActiveElements = document.querySelectorAll('.active');

    if (!cardRef.current) {
      return;
    }

    Array.from(allActiveElements).forEach(element => {
      element.classList.remove('active');
    });

    cardRef.current.classList.add('active');

    handleAsideVisibility();
  }, [handleAsideVisibility]);

  return (
    <Card onClick={handleActiveCards} ref={cardRef}>
      <a
        href="#"
        onClick={(event: MouseEvent) => handleChangeOfMenus(menuId, event)}
      >
        <Icon size={39} />
        <span>{title}</span>
      </a>
    </Card>
  );
};

export { CardMenu };
