import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { FiSave } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { ValidationError } from 'yup';
import * as Yup from 'yup';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { InputMask } from '@components/elements/Form/InputMask';
import { Button } from '@components/elements/Button';
import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';

import { IInvestment, IParams } from './interfaces';
import { statusOptions } from './selectOptions';

export const EditInvestment: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const formRef = useRef<FormHandles>(null);

  const [investment, setInvestment] = useState<IInvestment>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getInvestment() {
      if (!params?.investmentId) return;

      const { data } = await api.get(`/investments/${params.investmentId}`, {
        params: {
          relations: ['client', 'investment_percentage'],
        },
      });

      console.log(data);

      setInvestment(data);
    }

    getInvestment();
  }, [params]);

  const validateFields = useCallback(async data => {
    const shape = Yup.object().shape({
      status: Yup.string()
        .required('Preencha este campo!')
        .oneOf(
          ['pending', 'active', 'canceled', 'contract_sent'],
          'Opção inválida!',
        ),
    });

    await shape.validate(data, {
      abortEarly: false,
    });
  }, []);

  const handleFormSubmit = useCallback(
    async ({ status }) => {
      try {
        await validateFields({ status });

        setLoading(true);

        const { data } = await api.put(`/investments/${params.investmentId}`, {
          status,
        });

        setInvestment(oldState => {
          if (!oldState) return;

          const { client, investment_percentage } = oldState;

          return { ...data, client, investment_percentage };
        });
        setLoading(false);
      } catch (err) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [validateFields, params],
  );

  const BadgeStatus = useMemo(() => {
    if (!investment) return;

    const status = statusOptions.find(item => item.value === investment.status);

    if (!status) return;

    const types: Record<string, 'warning' | 'success' | 'danger' | 'info'> = {
      pending: 'warning',
      active: 'success',
      canceled: 'danger',
      contract_sent: 'info',
    };

    return <Badge type={types[investment.status]}>{status.label}</Badge>;
  }, [investment]);

  return (
    <>
      {!investment ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['BBank Invest', 'Investimentos', 'Editar']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Editar</h1>

                {BadgeStatus}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={investment}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Cliente</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Porcentagem de rendimento</label>
                      <Input
                        name="default"
                        defaultValue={`${
                          investment.investment_percentage.name
                        } - ${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(investment.investment_percentage.value)}`}
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cotas</label>
                      <Select name="quota" items={statusOptions} readonly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data início</label>
                      <InputMask
                        name="dateInit"
                        mask="99/99/9999"
                        readOnly
                        defaultValue={format(
                          parseISO(investment.date_init),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data fim</label>
                      <InputMask
                        name="dateEnd"
                        mask="99/99/9999"
                        readOnly
                        defaultValue={format(
                          parseISO(investment.date_end),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiSave} loading={loading}>
                      Salvar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
