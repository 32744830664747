import { FC } from 'react';

import {
  Card as CardComponent,
  CardContent as CardContentComponent,
  CardHeader as CardHeaderComponent,
} from './styles';
import { ICard, ICardContent, ICardHeader } from './interfaces';

export const Card: FC<ICard> = ({ children, ...rest }) => {
  return <CardComponent {...rest}>{children}</CardComponent>;
};

export const CardHeader: FC<ICardHeader> = ({ children, ...rest }) => {
  return <CardHeaderComponent {...rest}>{children}</CardHeaderComponent>;
};

export const CardContent: FC<ICardContent> = ({ children, ...rest }) => {
  return <CardContentComponent {...rest}>{children}</CardContentComponent>;
};
