import { FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
import Datatable, { TableColumn } from 'react-data-table-component';

import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DownloadButton,
  SelectStatus,
} from './styles';

interface IUserFile {
  id: string;
  title: string;
  fileUrl: string;
  status: string;
  created_at: string;
}

interface IUser {
  name: string;
  status: string;
}

interface IResponse {
  userFiles: Array<IUserFile>;
  user: IUser;
}

interface IParams {
  id: string;
}

interface ITableData {
  title: string;
  created_at: string;
  actions: JSX.Element;
}

const UserFiles: FC = () => {
  const [userFiles, setUserFiles] = useState<Array<IUserFile>>();
  const [, setUser] = useState<IUser>();
  const { params } = useRouteMatch<IParams>();
  const { id } = params;

  // Load files from API
  useEffect(() => {
    async function loadFile() {
      const { data } = await api.get<IResponse>(`/user-files/${id}`);

      setUserFiles(data.userFiles);
      setUser(data.user);
    }

    loadFile();
  }, [id]);

  const statusTranslation: any = {
    pending: 'Pendente',
    denied: 'Negado',
    accepted: 'Aceito',
  };

  // Table columns
  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome do arquivo',
        selector: 'title',
      },
      {
        name: 'Data do upload',
        selector: 'created_at',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Download',
        selector: 'download',
      },
      {
        name: 'Confirmação',
        selector: 'actions',
      },
    ];
  }, []);

  const handleStatusChange = async (fileId: string, newStatus: string) => {
    try {
      await api.put(`/user-files/${fileId}`, { status: newStatus });

      // Após a alteração bem-sucedida, atualize o estado userFiles
      if (userFiles) {
        const updatedUserFiles = userFiles.map(file => {
          if (file.id === fileId) {
            return { ...file, status: newStatus };
          }
          return file;
        });
        setUserFiles(updatedUserFiles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Table data
  const tableData = useMemo<Array<ITableData>>(() => {
    if (userFiles) {
      return userFiles.map(file => {
        return {
          id: file.id,
          title: file.title,
          status: statusTranslation[file.status],
          created_at: format(new Date(file.created_at), 'dd/MM/yyyy'),
          download: (
            <div style={{ display: 'flex', gap: '10px' }}>
              <DownloadButton
                href={file.fileUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FiDownload size={20} />
              </DownloadButton>
            </div>
          ),
          actions: (
            <>
              <SelectStatus
                defaultValue={file.status}
                onChange={e => handleStatusChange(file.id, e.target.value)}
              >
                <option value="pending">Pendente</option>
                <option value="accepted">Aceito</option>
                <option value="denied">Negado</option>
              </SelectStatus>
            </>
          ),
        };
      });
    }

    return [];
  }, [userFiles]);

  return (
    <>
      {!userFiles ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Download</li>
              <li>{'>'}</li>
              <li>Usuários</li>
              <li>{'>'}</li>
              <li>Arquivos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos</h1>

                <Badge type={userFiles.length === 0 ? 'danger' : 'success'}>
                  Arquivos encontrados:&nbsp;
                  {userFiles.length}
                </Badge>
              </CardHeader>

              <CardContent>
                <Datatable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { UserFiles };
