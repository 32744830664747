import {
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  FiFlag,
  FiGift,
  FiHash,
  FiHome,
  FiLock,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiPlusCircle,
  FiSave,
  FiSmartphone,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { Footer } from '@components/layouts/Footer';
import { Button } from '@components/elements/Button';
import { FormRow } from '@components/elements/Form/FormRow';
import { Select } from '@components/elements/Form/Select';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeEmptyFields } from '@helpers/removeEmptyFields';
import { removeInputMask } from '@helpers/removeInputMask';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  Header,
  Container,
  Content,
  Card,
  CardHeader,
  CardContent,
} from './styles';
import { statesOption, personTypesOptons } from './selectOptions';
import { formValidation } from './validations';
import themes, { ThemeNames } from '../../../../styles/themes';

interface IInputProps extends HTMLElement {
  value?: string;
}

interface IRouteParams {
  id: string;
}

interface IUser {
  name: string;
  id: string;
  email: string;
}

interface IParsedData {
  [key: string]: string;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  zipcode: string;
  address: string;
  state: string;
  city: string;
  phone: string;
  cellphone: string;
  person_type: string;
  document: string;
  birthdate: string;
}

type IDocumentState = '999.999.999-99' | '99.999.999/9999-99';

const CreateUsersByIndication: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const currentTheme: ThemeNames = process.env.REACT_APP_THEME as ThemeNames;

  const [cpfCnpjMask, setCpfCnpjMask] = useState<IDocumentState>(
    '999.999.999-99',
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nonExistingIndication, setNonExistingIndication] = useState(false);
  const [user, setUser] = useState<IUser>();

  const { addToast } = useToast();
  const { params } = useRouteMatch<IRouteParams>();
  const { push } = useHistory();

  const { id } = params;

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      const formData: IParsedData = {
        ...data,
        status: 'ativo',
      };
      setButtonLoading(true);

      try {
        formRef.current?.setErrors({});

        await formValidation(formData);

        const parsedValues = removeEmptyFields(formData);
        const valuesWithoutMasks = removeInputMask();

        valuesWithoutMasks.forEach(item => {
          parsedValues[item.name] = item.value;
        });

        delete parsedValues.confirmPassword;

        await api.post(`/users-indicated/${user?.id}`, parsedValues);

        addToast({
          title: 'Dados cadastrados com sucesso!',
          type: 'success',
        });

        setTimeout(() => {
          push('/');
        }, 700);
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: `Solicitação não processada!`,
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Um erro desconhecido aconteceu! Por favor, contate o administrador do sistema e reporte o erro',
            });
          }
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, push, user?.id],
  );

  const handleDocumentInput = useCallback((value: OptionTypeBase | null) => {
    if (value === null) {
      return;
    }

    if (value.value === 'PF') {
      setCpfCnpjMask('999.999.999-99');
    } else {
      setCpfCnpjMask('99.999.999/9999-99');
    }
  }, []);

  const handleFindZipcode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      setButtonLoading(true);

      try {
        const addressInput = document.getElementById('address') as IInputProps;
        const cityInput = document.getElementById('city') as IInputProps;
        const stateInput = document.getElementById('state') as IInputProps;
        const credInput = document.getElementById('person_type') as IInputProps;
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get(`${cep}/json`);

        addressInput.value = data.logradouro.toUpperCase();
        cityInput.value = data.localidade.toUpperCase();
        stateInput.value = data.uf.toUpperCase();
        credInput.focus();
      } catch (err) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    async function loadIndicator() {
      try {
        const { data } = await api.get(`/users/offline/${id}`);

        setUser(_ => {
          return {
            email: data.email,
            name: data.name,
            id: data.id,
          };
        });
      } catch (err) {
        setNonExistingIndication(true);
        addToast({
          title: 'Essa indicação não existe!',
          type: 'error',
        });
      }
    }

    loadIndicator();
  }, [id, addToast]);

  if (nonExistingIndication) {
    return (
      <>
        <Header>
          <Content>
            <img src={themes[currentTheme].images.logo} alt="Logo" />

            <div>
              <FiPlusCircle size={25} />
              <h1>Novo cadastro</h1>
            </div>
          </Content>
        </Header>

        <Container>
          <Content>
            <Row>
              <h1>A indicação informada não existe</h1>
            </Row>
          </Content>
        </Container>

        <Footer />
      </>
    );
  }

  return (
    <>
      <Header>
        <Content>
          <img src={themes[currentTheme].images.logo} alt="Logo" />

          <div>
            <FiPlusCircle size={25} />
            <h1>Novo cadastro</h1>
          </div>
        </Content>
      </Header>

      <Container>
        <Content>
          <Row>
            <h1>Cadastro por indicação</h1>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Indicação: &nbsp;
                  {user?.name}
                </h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <Input name="name" placeholder="Nome" icon={FiUser} />

                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      icon={FiMail}
                    />
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="(99) 9999-9999"
                      name="phone"
                      placeholder="Telefone"
                      icon={FiPhone}
                    />

                    <InputMask
                      mask="(99) 99999-9999"
                      name="cellphone"
                      placeholder="Celular"
                      icon={FiSmartphone}
                    />

                    <InputMask
                      name="birthdate"
                      mask="99/99/9999"
                      placeholder="Data de aniversário"
                      icon={FiGift}
                      noUnmask
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="99999-999"
                      name="zipcode"
                      placeholder="CEP"
                      icon={FiMapPin}
                      onBlur={e => handleFindZipcode(e)}
                    />

                    <Input
                      name="address"
                      id="address"
                      placeholder="Endereço"
                      icon={FiHome}
                      maxLength={45}
                    />

                    <Input
                      name="city"
                      id="city"
                      placeholder="Cidade"
                      icon={FiMap}
                      maxLength={45}
                    />

                    <Select
                      name="state"
                      id="state"
                      placeholder="Estado"
                      icon={FiFlag}
                      options={statesOption}
                      maxLength={45}
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados de acesso</h1>
                  </FormRow>

                  <FormRow>
                    <Select
                      name="person_type"
                      placeholder="Tipo de documentação"
                      icon={FiTag}
                      id="person_type"
                      onChange={handleDocumentInput}
                      options={personTypesOptons}
                    />

                    <InputMask
                      mask={cpfCnpjMask}
                      name="document"
                      placeholder={
                        cpfCnpjMask === '99.999.999/9999-99' ? 'CNPJ' : 'CPF'
                      }
                      icon={FiHash}
                    />
                  </FormRow>

                  <FormRow>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Senha"
                      icon={FiLock}
                    />

                    <Input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirmar senha"
                      icon={FiLock}
                    />
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      loading={buttonLoading}
                      styleType="success"
                      icon={FiSave}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </Content>
      </Container>

      <Footer />
    </>
  );
};

export { CreateUsersByIndication };
