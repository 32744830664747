import { ValidationError } from 'yup';
import { FC, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { FiSave } from 'react-icons/fi';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import { IFormData } from './interfaces';
import { formValidation } from './validations';
import { productsOptions, statesOptions } from './selectOptions';

const NewProspect: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const valuesWithoutMasks = removeInputMask();

        const parsedData: IFormData = {
          ...data,
          indicationId: user.id,
        };

        valuesWithoutMasks.forEach(({ name, value }) => {
          parsedData[name] = value;
        });

        await api.post('/prospects', parsedData);

        reset();
        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Prospecto cadastrado com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, user.id],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Prospectos', 'Novo']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo prospecto</h1>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <FormRow>
                <InputGroup>
                  <label>Nome</label>
                  <Input name="name" />
                </InputGroup>

                <InputGroup>
                  <label>Email</label>
                  <Input name="email" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Telefone</label>
                  <InputMask mask="(99) 9 9999-9999" name="phone" />
                </InputGroup>

                <InputGroup>
                  <label>Estado</label>
                  <Select name="state" options={statesOptions} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Selecione um de nossos produtos</label>
                  <Select name="product" options={productsOptions} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button styleType="success" type="submit" icon={FiSave}>
                  Cadastrar novo prospecto
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewProspect };
