import { useState, FC, useEffect, useMemo, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import { format } from 'date-fns';
import { FiUpload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { ICredit, ITableData, IBadgeTypes } from './interfaces';

const CredCashUploadFiles: FC = () => {
  const { push } = useHistory();

  const [credits, setCredits] = useState<ICredit[]>([]);

  useEffect(() => {
    async function loadCredits() {
      const { data } = await api.get('/indicated-credits');

      setCredits(data);
    }

    loadCredits();
  }, []);

  const handleNavigation = useCallback(
    (creditId: string) => {
      push(`/franchisees/uploads/cred-cash/${creditId}`);
    },
    [push],
  );

  const tableColumns = useMemo((): TableColumn[] => {
    return [
      {
        name: 'CPF/CNPJ',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Solicitado em',
        selector: 'createdAt',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo((): ITableData[] => {
    const data = credits.map(credit => {
      const badgeTypes: IBadgeTypes = {
        pendente: 'warning',
        analise: 'info',
        aprovado: 'success',
        negado: 'danger',
        'pre-aprovado': 'info',
      };

      return {
        id: credit.id,
        search: credit.cnpj || credit.owner.document,
        email: credit.email || credit.owner.email,
        createdAt: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        status: <Badge type={badgeTypes[credit.status]}>{credit.status}</Badge>,
        actions: (
          <Button
            styleType="info"
            icon={FiUpload}
            onClick={() => handleNavigation(credit.id)}
            disabled={credit.status !== 'pendente'}
          />
        ),
      };
    });

    return data;
  }, [credits, handleNavigation]);

  return (
    <>
      <Row>
        <URLPath paths={['Uploads', 'CredCash']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Selecione um dos créditos</h1>
          </CardHeader>

          <CardContent>
            <DataTable
              columns={tableColumns}
              data={tableData}
              filterPlaceholder="Filtrar por CPF/CNPJ"
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { CredCashUploadFiles };
