import { FC, useCallback, useRef, useState } from 'react';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { formValidation } from './validations';
import { IFormData } from './interface';
import { periodOptions, documentType } from './selectOptions';

const NewCardPayment: FC = () => {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);
        // CODE
        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'O seu boleto foi pago com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status >= 400 && status < 499) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Pagamento', 'Novo Pagamento']} />
      </Row>
      <Row>
        <Card>
          <CardHeader>
            <h1>Novo pagamento</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow separator>
                <h1>Preenche os dados para realizar o pagamento</h1>
              </FormRow>
              <FormRow>
                <InputGroup>
                  <label>Link para pagamento</label>
                  <Input name="paymentLink" />
                </InputGroup>
              </FormRow>
              <FormRow>
                <InputGroup>
                  <label>Valor do pagamento</label>
                  <InputCurrency name="paymentValue" />
                </InputGroup>
                <InputGroup>
                  <label>Titular do cartão</label>
                  <Input name="cardholderName" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Número do cartão</label>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    name="cardNumber"
                    placeholder="0000 0000 0000 0000"
                  />
                </InputGroup>
                <InputGroup>
                  <label>Data de vencimento</label>
                  <InputMask
                    mask="99/9999"
                    name="expirationDate"
                    placeholder="MM/YYYY"
                  />
                </InputGroup>
                <InputGroup>
                  <label>Código de segurança</label>
                  <InputMask mask="999" name="securityCode" placeholder="CVV" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Tipo de documento</label>
                  <Select
                    name="identificationType"
                    placeholder="Selecione seu perfil"
                    options={documentType}
                  />
                </InputGroup>
                <InputGroup>
                  <label>Parcelas</label>
                  <Select
                    name="installments"
                    placeholder="Selecione quantas parcelas"
                    options={periodOptions}
                  />
                </InputGroup>
                <InputGroup>
                  <label>E-mail</label>
                  <Input name="cardholderEmail" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Banco emissor</label>
                  <Input name="issuer" />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button styleType="success" loading={loadingRequest}>
                  Realizar pagamento!
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewCardPayment };
