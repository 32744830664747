import { FC, useState, useEffect, useCallback } from 'react';
import { FiEye, FiSearch } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  Prospect,
  CustomButton,
} from './styles';
import { IProspect, IParams, ILoadedProspect } from './interfaces';

const ListProspectsFromFranchisee: FC = () => {
  const { push } = useHistory();
  const { params } = useRouteMatch<IParams>();

  const [prospects, setProspects] = useState<IProspect[]>();
  const [searchString, setSearchString] = useState('');
  const [searchProspects, setSearchProspects] = useState<IProspect[]>();

  useEffect(() => {
    async function loadProspects() {
      const { data } = await api.get<ILoadedProspect[]>(
        `/prospects/indications/${params.indicationId}`,
      );

      const parsedData = data.map(item => ({
        id: item.id,
        name: item.name,
        product: item.product,
        email: item.email,
        state: item.state,
        createdAt: format(new Date(item.created_at), 'dd/MM/yyyy'),
      }));

      setProspects(parsedData);
      setSearchProspects(parsedData);
    }

    const timer = setTimeout(() => {
      loadProspects();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.indicationId]);

  const handleSearchProspects = useCallback(() => {
    if (!prospects) {
      return;
    }

    if (searchString === '') {
      setSearchProspects(prospects);
      return;
    }

    const search = prospects.filter(
      prospect =>
        prospect.email.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.name.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.state.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.product.toLowerCase().search(searchString.toLowerCase()) >=
          0 ||
        prospect.createdAt.toLowerCase().search(searchString.toLowerCase()) >=
          0,
    );

    setSearchProspects(search);
  }, [searchString, prospects]);

  const handleNavigation = useCallback(
    (prospectId: string) => {
      push(`/prospects/${prospectId}/view`);
    },
    [push],
  );

  return (
    <>
      {!searchProspects ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Franquia</li>
              <li>{'>'}</li>
              <li>Prospectos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Prospectos cadastrados</h1>

                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                >
                  <Input
                    name="search"
                    icon={FiSearch}
                    placeholder="Procurar..."
                    onChange={e => setSearchString(e.target.value)}
                    onKeyUp={handleSearchProspects}
                    upperCase={false}
                  />
                </Form>
              </CardHeader>

              <CardContent>
                {searchProspects.length === 0 ? (
                  <Badge type="danger">Nenhum prospecto foi encontrado</Badge>
                ) : (
                  searchProspects.map(prospect => (
                    <Prospect key={prospect.id}>
                      <CustomButton
                        styleType="info"
                        onClick={() => handleNavigation(prospect.id)}
                      >
                        <FiEye />
                      </CustomButton>

                      <h1>{prospect.name}</h1>

                      <div>
                        <span>
                          Email:&nbsp;
                          {prospect.email}
                        </span>
                        <span>
                          Estado:&nbsp;
                          {prospect.state}
                        </span>
                      </div>

                      <span>{prospect.product}</span>
                    </Prospect>
                  ))
                )}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListProspectsFromFranchisee };
