import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FiDownload, FiMoreHorizontal } from 'react-icons/fi';
import { useRouteMatch } from 'react-router-dom';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Badge } from '@components/elements/Badge';
import { Dropdown } from '@components/elements/Dropdown';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Money } from '@components/layouts/Money';
import { URLPath } from '@components/layouts/UrlPath';

import useBank from '@hooks/bank';

import { bankApi } from '@services/bankApi';

import { consoleLog } from '@helpers/consoleLog';

import { Card, CardContent, CardHeader } from '../../Transfer/New/styles';
import { IPayment } from './interfaces';

export const BillPaymentsDetails: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<{ paymentId: string }>();
  const { handleVerifyToken } = useBank();

  const [payment, setPayment] = useState<IPayment>();

  const loadPayment = useCallback(async () => {
    const { data } = await bankApi.get(`/bill-payments/${params.paymentId}`);

    setPayment(data);
  }, [params]);

  useEffect(() => {
    handleVerifyToken().then(response => {
      if (response) {
        loadPayment();
      }
    });
  }, [loadPayment, handleVerifyToken]);

  const handleFormSubmit = useCallback(async () => {
    consoleLog('Easter egg found!');
  }, []);

  return (
    <>
      <AuthModal />

      <URLPath paths={['Pass to Pay', 'Pagamento de contas', 'Detalhes']} />

      <Money initVisible />

      <Card>
        <CardHeader>
          <h1>Detalhes de um pagamento</h1>

          <Dropdown
            styles={{
              textColor: '#fff',
              togglerBackground: '${({ theme }) => theme.colors.primary}',
            }}
            icon={FiMoreHorizontal}
          >
            <li>
              <button type="button">
                <FiDownload />
                Baixar comprovante
              </button>
            </li>
          </Dropdown>
        </CardHeader>

        <CardContent>
          <Form ref={formRef} onSubmit={handleFormSubmit} initialData={payment}>
            {payment?.scheduled && (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 20,
                }}
              >
                <Badge type="info">
                  Agendado para&nbsp;
                  {format(parseISO(payment?.scheduled), 'dd/MM/yyyy HH:mm:ss')}
                </Badge>
              </div>
            )}

            <FormRow>
              <InputGroup>
                <label>Tipo de entrada</label>
                <Select
                  name="inputType"
                  options={[
                    { label: 'Código de barras', value: 'barCode' },
                    { label: 'Linha digitável', value: 'line' },
                  ]}
                  isDisabled
                  defaultValue={
                    payment?.bar_code
                      ? { label: 'Código de barras', value: 'barCode' }
                      : { label: 'Linha digitável', value: 'line' }
                  }
                />
              </InputGroup>

              <InputGroup>
                <label>Valor</label>
                <Input
                  name="amount"
                  disabled
                  defaultValue={
                    payment?.amount
                      ? new Intl.NumberFormat('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                        }).format(payment.amount)
                      : 0
                  }
                />
              </InputGroup>
            </FormRow>

            {payment?.bar_code && (
              <FormRow>
                <InputGroup>
                  <label>Código de barras</label>
                  <Input name="bar_code" upperCase={false} disabled />
                </InputGroup>
              </FormRow>
            )}

            {payment?.insert_line && (
              <FormRow>
                <InputGroup>
                  <label>Linha digitável</label>
                  <Input name="insert_line" upperCase={false} disabled />
                </InputGroup>
              </FormRow>
            )}

            <FormRow>
              <InputGroup textarea>
                <label>Descrição</label>
                <Textarea
                  name="description"
                  disabled
                  readOnly
                  defaultValue={payment?.description}
                />
              </InputGroup>
            </FormRow>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};
