import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit2, FiEye, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { DataTable } from '@components/elements/Datatable';
import { Button } from '@components/elements/Button';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { ITaxesGroup } from './interfaces';

export const ListTaxesGroups = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [taxesGroups, setTaxesGroups] = useState<ITaxesGroup[]>([]);

  useEffect(() => {
    async function loadTaxesGroups() {
      const { data } = await api.get(`/taxes-group`);

      setTaxesGroups(data);
    }

    loadTaxesGroups();
  }, []);

  const handleDeleteTaxesGroup = useCallback(async (id: string) => {
    await api.delete(`/taxes-group/${id}`);

    setTaxesGroups(oldState => {
      const updatedState = oldState.filter(group => group.id !== id);

      return updatedState;
    });
  }, []);

  const tableData = useMemo(() => {
    return taxesGroups.map(group => {
      return {
        id: group.id,
        name: group.name,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEdit2}
              title="Visualizar tabela"
              styleType="success"
              onClick={() => push(`/taxes-groups/details/${group.id}`)}
            />

            <TableButton
              icon={FiEye}
              title="Editar tabela"
              styleType="warning"
              onClick={() => push(`/taxes-groups/create?id=${group.id}`)}
            />

            <TableButton
              icon={FiTrash2}
              title="Deletar tabela"
              styleType="danger"
              disabled={group.created_by !== user?.id}
              onDoubleClick={() => handleDeleteTaxesGroup(group.id)}
            />
          </TableButtonContainer>
        ),
      };
    });
  }, [taxesGroups, user, push, handleDeleteTaxesGroup]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da tabela',
        selector: 'name',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  return (
    <>
      <Row>
        <URLPath paths={['Tabela de taxas', 'Lista']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Lista de tabelas</h1>

            <Button
              styleType="info"
              icon={FiPlus}
              onClick={() => push(`/taxes-groups/create`)}
            />
          </CardHeader>

          <CardContent>
            <DataTable data={tableData} columns={tableColumns} />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
