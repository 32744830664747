import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiShoppingCart, FiUsers } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { ISeller } from './interfaces';

const ListSellers: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [sellers, setSellers] = useState<ISeller[]>();

  useEffect(() => {
    async function loadSellers() {
      const { data } = await api.get(`/users/sellers/indication/${user.id}`);

      setSellers(data);
    }

    const timer = setTimeout(() => loadSellers(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const handleNavigationToSellerIndicationsPage = useCallback(
    (sellerId: string) => {
      push(`/franchisees/sellers/${sellerId}/indications`);
    },
    [push],
  );

  const handleNavigationToSellerProspectsPage = useCallback(
    (indicationId: string) => {
      push(`/franchisees/prospects/indications/${indicationId}/list`);
    },
    [push],
  );

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Nome do vendedor',
        selector: 'search',
      },
      {
        name: 'Email do vendedor',
        selector: 'email',
      },
      {
        name: 'Status do vendedor',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!sellers) {
      return [];
    }

    const data = sellers.map(seller => {
      return {
        id: seller.id,
        search: seller.name,
        email: seller.email,
        status: (
          <Badge type={seller.status === 'ativo' ? 'success' : 'warning'}>
            {seller.status === 'ativo' ? 'Ativo' : 'Pendente'}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handleNavigationToSellerIndicationsPage(seller.id)}
              title="Ver indicações feitas por este vendedor"
              icon={FiUsers}
            />
            <TableButton
              styleType="info"
              onClick={() => handleNavigationToSellerProspectsPage(seller.id)}
              title="Ver prospectos deste vendedor"
              icon={FiShoppingCart}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [
    sellers,
    handleNavigationToSellerIndicationsPage,
    handleNavigationToSellerProspectsPage,
  ]);

  return (
    <>
      {!sellers ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Vendedores', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Meus vendedores</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por nome..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListSellers };
