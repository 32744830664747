import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns/esm';
import { FiDownload, FiEdit, FiMoreHorizontal } from 'react-icons/fi';
import exportFromJSON from 'export-from-json';

import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { maskHandler } from '@helpers/maskHandler';

import {
  IColumns,
  IState,
  IStatusParsedObject,
  ITableData,
} from './interfaces';

const AllCredits: FC = () => {
  const { push } = useHistory();
  const [credits, setCredits] = useState<IState[]>();

  useEffect(() => {
    async function loadCredits() {
      const {
        data: { credits: data },
      } = await api.get<any>('/credits');

      setCredits(_ => {
        return data.map((item: any) => {
          return {
            ...item,
            email: item.email === null ? item.owner.email : item.email,
            cnpj: item.cnpj,
            cpf: item.owner.document,
            user_name: item.owner.name,
            social_reason: item.social_reason,
          };
        });
      });
    }

    loadCredits();
  }, []);

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pendente: {
        text: 'Pendente',
        badgeType: 'warning',
      },
      aprovado: {
        text: 'Aprovado',
        badgeType: 'success',
      },
      'pre-aprovado': {
        text: 'Pre aprovado',
        badgeType: 'info',
      },
      analise: {
        text: 'Em análise',
        badgeType: 'info',
      },
      negado: {
        text: 'Negado',
        badgeType: 'danger',
      },
      'contrato-pendente': {
        text: 'Contrato pendente',
        badgeType: 'info',
      },
    };
  }, []);

  const tableData = useMemo<ITableData[]>(() => {
    if (!credits) {
      return [];
    }

    const data = credits.map<ITableData>(credit => {
      const link =
        credit.cnpj === null
          ? `/cred-cash/pf/${credit.id}`
          : `/cred-cash/pj/${credit.id}`;

      return {
        id: credit.id,
        search: credit.social_reason || credit.user_name,
        email: credit.email,
        average_of_revenues: credit.average_of_revenues,
        status: (
          <Badge type={statusParsedObject[credit.status].badgeType}>
            {statusParsedObject[credit.status].text}
          </Badge>
        ),
        created_at: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              onClick={() => push(link)}
              icon={FiEdit}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [credits, push, statusParsedObject]);

  const tableColumns = useMemo<IColumns>(() => {
    return [
      {
        name: 'Referente à',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Data da solicitação',
        selector: 'created_at',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleExportData = useCallback(
    (type: 'PF' | 'PJ') => {
      if (!credits || credits.length === 0) {
        return;
      }

      const filteredCredits = credits.filter(credit => credit.type === type);

      const creditsListLength = filteredCredits.length;

      const numberFormatter = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;

      const workSheetCells = Array.from(Array(creditsListLength).keys()).map(
        item => {
          const credit = filteredCredits[item];

          let workSheetCell = {};

          if (credit.type === 'PJ') {
            workSheetCell = {
              'Nome fantasia': credit.name_fantasy,
              'Razão social': credit.social_reason,
              CNPJ: maskHandler({ mask: 'cnpj', payload: credit.cnpj }),
              Email: credit.email,
              Telefone: credit.phone
                ? maskHandler({
                    mask: 'phone',
                    payload: credit.phone,
                  })
                : 'Não informado',
              'Faturamento médio': numberFormatter(
                Number(credit.average_of_revenues),
              ),
              'Valor do crédito': numberFormatter(credit.amount),
              'Nome do cliente': credit.owner.name,
              'Nome do franqueado': credit.franchisee.name,
              bank_code: credit.bank_code,
              agency: credit.agency,
              account: credit.account,
              zipcode: maskHandler({ mask: 'cep', payload: credit.zipcode }),
              state: credit.state,
              city: credit.city,
              address: credit.address,
              neighborhood: credit.neighborhood,
              complement: credit.complement,
              responsible_name: credit.responsible_name,
              responsible_cpf: maskHandler({
                mask: 'cpf',
                payload: credit.responsible_cpf,
              }),
              responsible_cellphone: maskHandler({
                mask: 'cellphone',
                payload: credit.responsible_cellphone,
              }),
              services: credit.services,
              modality: credit.modality,
              contract_percentage: credit.contract_percentage
                ? `${credit.contract_percentage}%`
                : 'Não informado',
              product: credit.product,
              employees_number: credit.employees_number
                ? credit.employees_number
                : 'Não informado',
              Status: credit.status,
              'Solicitado em': format(
                new Date(credit.created_at),
                'dd/MM/yyyy',
              ),
            };
          } else {
            workSheetCell = {
              'Faturamento médio': credit.average_of_revenues,
              'Valor do crédito': numberFormatter(credit.amount),
              'Cod do banco': credit.bank_code,
              'Agência bancária': credit.agency,
              'Conta bancária': credit.account,
              Telefone: credit.phone
                ? maskHandler({
                    mask: 'phone',
                    payload: credit.phone,
                  })
                : 'Não informado',
              Modalidade: credit.modality,
              Produto: credit.product,
              'Nome do cliente': credit.owner.name,
              'Nome do franqueado': credit.franchisee.name,
              Status: credit.status,
              'Solicitado em': format(
                new Date(credit.created_at),
                'dd/MM/yyyy',
              ),
            };
          }

          return workSheetCell;
        },
      );

      exportFromJSON({
        data: workSheetCells,
        exportType: 'xls',
        fileName: `cred_cash_${type}`,
      });
    },
    [credits],
  );

  return (
    <>
      {!credits ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Solicitações']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Créditos solicitados</h1>

                <Dropdown
                  styles={{
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                    textColor: '#fff',
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button
                      type="button"
                      onClick={() => handleExportData('PF')}
                    >
                      <FiDownload />
                      Exportar dados PF
                    </button>
                  </li>

                  <li>
                    <button
                      type="button"
                      onClick={() => handleExportData('PJ')}
                    >
                      <FiDownload />
                      Exportar dados PJ
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  filterPlaceholder="Filtrar pela coluna 'referente à'"
                  data={tableData}
                  pagination
                  striped
                  subHeader
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { AllCredits };
