import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiClock, FiSearch } from 'react-icons/fi';
import { differenceInHours, format } from 'date-fns';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Row } from '@components/layouts/Grid/Row';
import { Modal } from '@components/elements/Modal';
import { Badge, Badge as BadgeComponent } from '@components/elements/Badge';
import { Input } from '@components/elements/Form/Input';
import { Form } from '@components/elements/Form';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Money } from '@components/layouts/Money';

import { useAuth } from '@hooks/auth';
import { useTermsOfUse } from '@hooks/termsOfUse';

import api from '@services/bbankApi';

import avatarGirl from '@assets/avatars/002-girl.svg';
import avatarBoy004 from '@assets/avatars/004-boy-1.svg';
import avatarBoy007 from '@assets/avatars/007-boy-2.svg';
import avatarBoy008 from '@assets/avatars/008-boy-3.svg';
import avatarBoy009 from '@assets/avatars/009-boy-4.svg';

import {
  CardContainer,
  CardHeader,
  UsersListContainer,
  UsersListHeader,
  UsersList,
  UserListItem,
  News,
  NewsImageWrapper,
  NewsInfo,
} from './styles';
import { IIndication, INews, ITermsOfUse } from './interfaces';
import { ImageSlider } from '../News/imageSlider';

const Dashboard: FC = () => {
  // const simulationResultRef = useRef<HTMLParagraphElement>(null);
  const { user } = useAuth();

  const [indications, setIndications] = useState<IIndication[]>([]);
  const [termsOfUse, setTermsOfUse] = useState<ITermsOfUse>();
  const [news, setNews] = useState<INews[]>();
  const [searchNews, setSearchNews] = useState<INews[]>([]);
  const [searchNewsString, setSearchNewsString] = useState('');

  useEffect(() => {
    async function loadIndications() {
      const { data } = await api.get('/users-indicated');
      const parsedData: IIndication[] = [];

      const arrayToControlIndicationsQuantity = Array.from(Array(5).keys());

      arrayToControlIndicationsQuantity.forEach((_, index) => {
        if (data[index] !== undefined) {
          const createdAtDate = new Date(data[index].created_at);

          if (differenceInHours(new Date(), createdAtDate) <= 24) {
            parsedData.push(data[index]);
          }
        }
      });

      setIndications(parsedData);
    }

    async function loadNews() {
      const { data: newsFromApi } = await api.get('/news');
      const reversedNewsArray = newsFromApi.reverse();

      // Set the array quantity to control how much news are going to be displayed
      // Four news are going to be displayed
      const arrayToControlNewsQuantity = Array.from(Array(4).keys());

      const parsedData = arrayToControlNewsQuantity
        .map((_, index) => {
          if (reversedNewsArray[index] === undefined) {
            return;
          }

          return reversedNewsArray[index];
        })
        .filter(item => item !== undefined) as INews[];

      setNews(parsedData);
      setSearchNews(parsedData);
    }

    const timer = setTimeout(async () => {
      await loadIndications();
      loadNews();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    async function getTermsOfUse() {
      const { data } = await api.get('/terms-of-use');

      setTermsOfUse(data);
    }

    const timer = setTimeout(() => getTermsOfUse(), 200);

    return () => clearInterval(timer);
  }, []);

  const handleMarkTermsOfUseAsConcluded = useCallback(async () => {
    const {
      data: { ip },
    } = await axios.get('https://api.ipify.org?format=json');

    const { data: terms } = await api.post('/terms-of-use', {
      ipAddress: ip,
    });

    setTermsOfUse(terms);
  }, []);

  const photos = useMemo(() => {
    return [avatarGirl, avatarBoy004, avatarBoy007, avatarBoy008, avatarBoy009];
  }, []);

  const randomPhotos = useMemo(() => {
    const getPhotos = photos.map(_ => {
      return photos[Math.floor(Math.random() * 5)];
    });

    return getPhotos;
  }, [photos]);

  const handleSearchNews = useCallback(() => {
    if (!news) {
      return;
    }

    if (searchNewsString === '') {
      setSearchNews(news);
      return;
    }

    const updatedSearchNews = news.filter(
      item =>
        item.title.toLowerCase().search(searchNewsString.toLowerCase()) >= 0 ||
        item.description.toLowerCase().search(searchNewsString.toLowerCase()) >=
          0,
    );

    setSearchNews(updatedSearchNews);
  }, [searchNewsString, news]);

  // const handlePriceSelectChange = useCallback(
  //   (value: OptionTypeBase | null) => {
  //     if (!value) {
  //       return;
  //     }

  //     const optionValue = value.value as number;

  //     const portions = credluzSimulationPortionsByPrice[optionValue];

  //     const portionOptions = Object.entries(portions).map(
  //       ([portionNumber, portionPrice]) => {
  //         return {
  //           value: portionPrice.toString(),
  //           label: portionNumber,
  //         };
  //       },
  //     );

  //     setPortionsOptions(portionOptions);
  //   },
  //   [],
  // );

  // const handleSimulation = useCallback((data: ISimulationData) => {
  //   if (!simulationResultRef.current) {
  //     return;
  //   }

  //   const numberFormat = new Intl.NumberFormat('pt-BR', {
  //     style: 'currency',
  //     currency: 'brl',
  //   }).format;

  //   const price = Number(data.price);
  //   const portion = Number(data.portion);

  //   const htmlText =
  //     `` +
  //     `A simulação com o preço de&nbsp;` +
  //     `<strong>${numberFormat(price)}</strong>` +
  //     `&nbsp;com 12 parcelas recebe um resultado de:&nbsp;` +
  //     `<strong>${numberFormat(portion)}</strong>`;

  //   simulationResultRef.current.innerHTML = htmlText;
  // }, []);

  return (
    <>
      {!news ? (
        <LoadingPage />
      ) : (
        <>
          <Money />

          <Row>
            <h1>Quadro de notícias</h1>

            <Form onSubmit={handleSearchNews}>
              <Input
                name="search"
                icon={FiSearch}
                placeholder="Filtrar notícias"
                onChange={e => setSearchNewsString(e.target.value)}
                onKeyUp={handleSearchNews}
                upperCase={false}
              />
            </Form>
          </Row>

          <Row style={{ gap: '32px', justifyContent: 'normal' }}>
            {news.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 2,
                  borderBottomColor: '#ebedf3',
                  padding: 10,
                }}
              >
                <Badge type="info">Não há notícias recentes</Badge>
              </div>
            )}

            {news.length !== 0 &&
              searchNews.map(item => (
                <News key={item.id}>
                  <NewsImageWrapper>
                    <img src={item.image_urls[0]} alt={item.title} />
                  </NewsImageWrapper>

                  <NewsInfo>
                    <Link to={`/shared/news/${item.id}`}>{item.title}</Link>

                    <p>{item.description}</p>

                    <span>
                      <FiClock size={18} />
                      Postado em:&nbsp;
                      {format(new Date(item.created_at), 'dd/MM/yyyy')}
                    </span>
                  </NewsInfo>
                </News>
              ))}
          </Row>

          <Row>
            <CardContainer>
              <CardHeader>
                <ImageSlider />
              </CardHeader>
            </CardContainer>

            {user.role !== 'client' && (
              <UsersListContainer>
                <UsersListHeader>
                  <h1>Novas indicações nas últimas 24h</h1>
                </UsersListHeader>

                <UsersList>
                  {indications.length === 0 ? (
                    <BadgeComponent type="info">
                      Não há indicações recentes
                    </BadgeComponent>
                  ) : (
                    indications.map((item, index) => (
                      <UserListItem key={item.id}>
                        <div>
                          <img src={randomPhotos[index]} alt={item.name} />
                        </div>

                        <div>
                          <Link to="/">{item.name}</Link>
                          <span>{item.email}</span>
                        </div>
                      </UserListItem>
                    ))
                  )}
                </UsersList>
              </UsersListContainer>
            )}
          </Row>

          {/* <Row>
            <SimulationContainer>
              <SimulationHeader>
                <h1>Simulação do CredLuz</h1>
              </SimulationHeader>

              <SimulationContent>
                <p ref={simulationResultRef} />

                <Form onSubmit={handleSimulation}>
                  <FormRow>
                    <Select
                      name="price"
                      options={credluzSimulationPriceValues}
                      placeholder="Selecione um valor"
                      onChange={handlePriceSelectChange}
                    />
                  </FormRow>

                  <FormRow>
                    <Select
                      name="portion"
                      options={portionsOptions}
                      placeholder="Selecione as parcelas"
                    />
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="info"
                      type="submit"
                      icon={FiCheckCircle}
                      style={{ backgroundColor: `${({ theme }) => theme.colors.secondary}`, color: '#fff' }}
                    >
                      Simular
                    </Button>
                  </FormRow>
                </Form>
              </SimulationContent>
            </SimulationContainer>
          </Row> */}

          <Row>
            {!termsOfUse && (
              <Modal
                headerText="Termos de uso"
                visible
                hideButton
                onOkClick={handleMarkTermsOfUseAsConcluded}
              >
                <div style={{ textAlign: 'center' }}>
                  <p> POLÍTICA DE PRIVACIDADE Plataforma PASS TO PAY</p>
                  <p>(atualizado pela última vez em 14 de junho de 2023)</p>
                </div>

                <p>
                  Esta Política de Privacidade (“Política de Privacidade”)
                  regulamenta a forma com que os Dados fornecidos por Você,
                  Usuário, em decorrência da utilização da Plataforma, serão
                  geridos pela PASS TO PAY MEIOS DE PAGAMENTOS LTDA., pessoa
                  jurídica de direito privado com sede em São Paulo/SP, avenida
                  Giovanni Gronchi, 6195 – Sala 1608 – Vila Andrade SP CEP
                  05724-003 inscrita no CNPJ/MF sob nº 45.423.864/0001-54 e
                  registrada na Junta Comercial do Estado de São Paulo (JUCESP)
                  sob o NIRE n.º 35238650129 (“PASS TO PAY”). É nesta Política
                  de Privacidade que, Você, Usuário irá compreender quais Dados
                  coletamos, bem como a forma com que eles são utilizados,
                  armazenados e compartilhados, além de esclarecer e assegurar
                  quais são os seus direitos em relação a tais Dados. Quando
                  Você, Usuário, faz uso ou tem acesso ao conteúdo da
                  Plataforma, seja por meio de nosso Aplicativo, seja por meio
                  do acesso ao nosso Site e Plataforma via browser de internet,
                  você concorda com nossos Termos de Uso e Política de
                  Privacidade, declarando, desde já, ter lido e compreendido
                  todo o seu conteúdo e condições. A leitura da Política de
                  Privacidade da Plataforma e de seus Termos de Uso é essencial
                  para assegurar a compreensão de seu funcionamento, além de
                  garantir a compreensão dos direitos, obrigações e
                  responsabilidades assumidas mutuamente tanto pela PASS TO PAY,
                  como por Você, Usuário, ao utilizar a Plataforma ou em razão
                  dela. IMPORTANTE AO UTILIZAR A PLATAFORMA, VOCÊ, USUÁRIO,
                  DECLARA ESTAR CIENTE E CONCORDAR, SEM RESSALVAS, COM AS
                  DISPOSIÇÕES CONTIDAS NESTA POLÍTICA DE PRIVACIDADE E NOS
                  TERMOS DE USO DA PASS TO PAY. CASO VOCÊ, USUÁRIO, NÃO CONCORDE
                  COM QUALQUER CONDIÇÃO EXPOSTA NESTES DOCUMENTOS, NÃO DEVERÁ
                  FAZER USO DA PLATAFORMA. Finalidade A presente Política de
                  Privacidade tem por objetivo esclarecer e assegurar que Você,
                  Usuário, tenha plena consciência e compreensão em relação à
                  forma como a PASS TO PAY coleta, armazena, processa, associa,
                  compartilha, utiliza e divulga os Dados de seus Usuários,
                  definindo todas as restrições impostas à PASS TO PAY em
                  relação ao uso de tais Dados e, naturalmente, todos os
                  direitos dos Usuários em relação aos mesmos. Parágrafo
                  Primeiro. A solicitação e tratamento de Dados dos Usuários,
                  que nada mais é do que a forma como as informações fornecidas
                  pelos Usuários para a Plataforma ou por meio dela serão
                  tratadas, conforme melhor define PASS TO PAY nesta Política de
                  Privacidade, tem por finalidade viabilizar o adequado
                  funcionamento da Plataforma e, também, assegurar o constante
                  aprimoramento da interação dos Usuários com a Plataforma a fim
                  de garantir que os mesmos tenham, sempre, uma experiência
                  agradável, completa e personalizada. Parágrafo Segundo. A
                  presente Política de Privacidade é parte integrante dos Termos
                  de Uso da Plataforma. Os termos e conceitos aqui utilizados,
                  quando grafados em letras maiúsculas, se não possuírem
                  definição expressa no presente instrumento, seguirão a
                  definição que lhes é atribuída nos Termos de Uso da
                  Plataforma. Parágrafo Terceiro. Ao utilizar a Plataforma,
                  Você, Usuário, declara ter lido detidamente a presente
                  Política de Privacidade e os Termos de Uso da Plataforma,
                  concordando, sem ressalvas, com as disposições contidas em
                  tais documentos. Dados Coletados Ao utilizar a Plataforma,
                  Você, Usuário, tem plena ciência e concorda em fornecer alguns
                  de seus dados pessoais (“Dados”), seja de forma direta, por
                  meio da simples imputação de dados, juntada de documentos
                  etc., seja de maneira indireta ou automática. Você, Usuário,
                  também tem plena ciência e concorda que, para os fins
                  específicos desta Política de Privacidade e para o adequado
                  funcionamento da Plataforma, a PASS TO PAY poderá receber
                  outros dados pessoais seus enviados por parceiros contratados
                  para finalidades específicas, para o cumprimento de obrigações
                  legais ou com a regulamentação aplicável, além de outras
                  situações melhor detalhadas adiante. Ao aceitar os termos da
                  presente Política de Privacidade, Você, Usuário, concorda em
                  fornecer apenas Dados verdadeiros, atualizados e precisos,
                  comprometendo-se a não alterar a sua identidade ou seus dados
                  pessoais de qualquer forma no acesso e na utilização da
                  Plataforma, certo que Você, Usuário, será o único responsável
                  por eventuais informações falsas, desatualizadas ou imprecisas
                  fornecidas no âmbito da Plataforma. Para os fins da presente
                  Política de Privacidade, Você, Usuário, concorda que o
                  conceito de “Dados”, ainda que de forma meramente
                  exemplificativa e não-exaustiva, compreende: Dados pessoais de
                  qualificação, como nome, documentos de identificação,
                  nacionalidade, endereço, data de nascimento, filiação e
                  gênero; Dados de contato, como telefone e e-mail; Dados
                  biométricos, tais como a fotografia do seu rosto; Dados
                  pessoais passíveis de serem coletados com terceiros, tais como
                  nome, filiação, data de nascimento, CPF, número de telefone,
                  endereço etc.; informações sobre histórico de crédito; score
                  gerado por bureaus de crédito; informação se Você, Usuário, se
                  enquadra como Pessoa Politicamente Exposta (PPE) ou faz
                  integra lista de restrição (como, OFAC, CSNU e outras listas
                  internacionais); informações constantes da base de dados do
                  Sistema de Informações de Crédito (SCR), mediante
                  consentimento; Dados de navegação e do dispositivo, tais como
                  endereço IP do dispositivo móvel utilizado para acessar os
                  Serviços ou Produtos; interações realizadas e perfil de uso de
                  sites, blogs e aplicativos; dados técnicos, como informações
                  de URL, de conexão de rede, do provedor, e do dispositivo;
                  cookies; atributos do dispositivo, tais como ID, sistema
                  operacional, navegador e modelo; dados de geolocalização do
                  dispositivo caso você autorize a coleta a partir do seu
                  dispositivo; Dados pessoais originados do uso dos nossos
                  produtos e serviços; Dados de contratação de nossos produtos e
                  serviços; Dados de transações e movimentações financeiras em
                  sua Carteira Digital, como histórico de débitos, transações,
                  pagamentos e transferências, incluindo também informações dos
                  remetentes e beneficiários; Data de início do uso da
                  Plataforma; Histórico de atendimento ao cliente; Dados médicos
                  e de saúde, tais como informações sobre exames e diagnósticos,
                  histórico e antecedentes médicos, informações sobre
                  internações, consultas, conduta médica, histórico familiar,
                  queixas de saúde, informações de prontuário médico, recibos
                  médicos, quando fornecidos diretamente por Você, Usuário, ou
                  quando decorrentes de serviços prestados por Credenciados no
                  âmbito da Plataforma; Dados públicos (informações sobre Você,
                  Usuário, que estejam disponíveis publicamente ou que foram
                  tornadas públicas por Você); Informações sobre menções ou
                  interações com a PASS TO PAY; Depoimentos referentes à
                  Plataforma e/ou a PASS TO PAY postados em perfis e páginas nas
                  redes sociais, juntamente com seu nome e imagem (incluindo
                  fotos de perfil). Forma de tratamento dos Dados A PASS TO PAY
                  utilizará seus Dados para poder prestar um serviço de alta
                  qualidade e oferecer os melhores serviços e produtos a Você,
                  Usuário, de forma completa e personalizada. Dessa forma, seus
                  Dados serão utilizados para as seguintes finalidades: Dados
                  pessoais informados pelo titular: prestação dos Serviços e
                  oferecimento dos Produtos contratados; identificação,
                  autenticação e verificação de requisitos para contratação dos
                  serviços e produtos; autenticação de transações financeiras
                  realizadas via Carteira Digital; atendimento de solicitações e
                  dúvidas; contato por telefone, e-mail, SMS, WhatsApp, ou
                  outros meios de comunicação, inclusive para envio de
                  notificações ou push de uso dos Serviços; envio de
                  comunicações em geral; aprimoramento dos serviços prestados
                  com o cruzamento de informações sobre produtos contratados por
                  pessoa física e por pessoa jurídica para oferecimento de novos
                  produtos e serviços; marketing, prospecção, pesquisas de
                  mercado, de opinião e promoção dos nossos produtos e serviços,
                  ou de nossos parceiros, inclusive com viabilização de ofertas
                  e envio de informações sobre produtos, serviços, novidades,
                  funcionalidades, conteúdos, notícias e demais eventos
                  relevantes para a manutenção do relacionamento com você;
                  consultas sobre suas informações na base de dados do Sistema
                  de Informações de Crédito (SCR), mediante a obtenção do seu
                  consentimento com esse uso; prevenção e resolução de problemas
                  técnicos ou de segurança; investigações e medidas de prevenção
                  e combate a ilícitos, fraudes, crimes financeiros e garantia
                  da segurança dos Usuários e do sistema financeiro; exercício
                  regular de direitos da PASS TO PAY, inclusive apresentando
                  documentos em processos judiciais e administrativos, se
                  necessário; colaboração ou cumprimento de ordem judicial, de
                  autoridade competente ou de órgão fiscalizador; cumprimento de
                  obrigação legal ou regulatória; Dados pessoais coletados por
                  meio de terceiros: melhoria de nossos produtos e serviços;
                  marketing, prospecção, pesquisas de mercado e de opinião;
                  prevenção e resolução de problemas técnicos ou de segurança;
                  investigações e medidas de prevenção e combate a ilícitos,
                  fraudes, crimes financeiros e garantia da segurança dos
                  Usuários e do sistema financeiro; cumprimento de obrigação
                  legal ou regulatória, por exemplo, para manutenção do seu
                  cadastro atualizado ou cumprimento de obrigações legais e/ou
                  regulatórias impostas, incluindo normas de “Conheça seu
                  Cliente”, “Prevenção à Lavagem de Dinheiro”, “Financiamento ao
                  Terrorismo” entre outras; Dados biométricos: prevenção à
                  fraude e garantia da sua segurança nos processos de
                  identificação e autenticação de cadastro e de novo
                  dispositivo; Dados de navegação e do dispositivo: prestação
                  dos serviços, oferecimento dos produtos contratados e
                  aperfeiçoamento do uso e experiência com o aplicativo e sites;
                  operacionalização de novos produtos e serviços; recomendação
                  de novos serviços, produtos ou funcionalidades do aplicativo,
                  inclusive serviços de parceiros que possam te interessar;
                  exibição de publicidade, seja no nosso site, redes sociais ou
                  em sites de terceiros; geração de estatísticas, estudos,
                  pesquisas e levantamentos pertinentes às atividades e
                  comportamento no uso dos produtos ou serviços; prevenção e
                  resolução de problemas técnicos ou de segurança; dados de
                  geolocalização para sua segurança; investigações e medidas de
                  prevenção e combate a ilícitos, fraudes, crimes financeiros e
                  garantia da segurança dos Usuários e do sistema financeiro;
                  exercício regular de direitos da PASS TO PAY, inclusive
                  apresentando documentos em processos judiciais e
                  administrativos, se necessário; cumprimento de ordem judicial,
                  de autoridade competente ou de órgão fiscalizador; cumprimento
                  de obrigação legal ou regulatória; Dados pessoais originados
                  do uso da Plataforma: prestação dos serviços e oferecimento
                  dos produtos contratados; autenticação de transações,
                  atendimento e suporte ao cliente; aprimoramento dos serviços
                  prestados, inclusive com o cruzamento de informações sobre
                  produtos contratados por pessoa física e por pessoa jurídica
                  para oferecimento de novos produtos e serviços;
                  desenvolvimento de novos produtos e serviços a serem
                  oferecidos pela empresa e geração de conhecimento para
                  inovação ou desenvolvimento de novos produtos; testes para
                  aprimoramento dos modelos de serviços e produtos; marketing,
                  prospecção, pesquisas de mercado, de opinião e promoção dos
                  nossos produtos e serviços, ou de nossos parceiros, inclusive
                  com viabilização de ofertas e envio de informações sobre
                  produtos, serviços, novidades, funcionalidades, conteúdos,
                  notícias e demais eventos relevantes para a manutenção do
                  relacionamento com Você; prevenção e resolução de problemas
                  técnicos ou de segurança e monitoramento de uso e performance
                  dos Serviços e Produtos; investigações e medidas de prevenção
                  e combate a ilícitos, fraudes, crimes financeiros e garantia
                  da segurança dos Usuários e do sistema financeiro; exercício
                  regular de direitos, inclusive apresentando documentos em
                  processos judiciais e administrativos, se necessário;
                  colaboração ou cumprimento de ordem judicial, autoridade
                  competente ou de órgão fiscalizador; cumprimento de obrigação
                  legal ou regulatória; Dados públicos: divulgação dos produtos
                  e serviços prestados em redes sociais, websites, aplicativos
                  ou materiais institucionais e publicitários; investigações e
                  medidas de prevenção e combate a ilícitos, fraudes, crimes
                  financeiros e garantia da segurança dos Usuários e do sistema
                  financeiro; exercício regular de direitos; cumprimento de
                  obrigações legais e/ou regulatórias impostas, incluindo normas
                  de “Conheça seu Cliente”, “Prevenção à Lavagem de Dinheiro”,
                  “Financiamento ao Terrorismo” entre outras. Compartilhamento
                  de Dados Seus Dados NÃO SERÃO vendidos, licenciados ou mesmo
                  compartilhados com outras empresas, organizações ou indivíduos
                  não relacionados à Plataforma ou à PASS TO PAY, a não ser que
                  Você, Usuário, solicite tal compartilhamento. Seus Dados, por
                  outro lado, PODERÃO ser compartilhados com Credenciados,
                  empresas vinculadas à PASS TO PAY, com terceiros parceiros e
                  com autoridades e órgãos reguladores, sempre de acordo com a
                  legislação aplicável, os limites e propósitos da Plataforma. O
                  compartilhamento de Dados, a título meramente exemplificativo,
                  poderá ocorrer da seguinte forma: Com os Credenciados, quando
                  da prestação de serviços e/ou fornecimento de produtos
                  contratados pelo Usuário no âmbito da Plataforma: para
                  assegurar a adequada prestação dos serviços e/ou fornecimento
                  de produtos contratados por Você, Usuário, junto aos
                  Credenciados; para realização de diagnósticos e análise prévia
                  de condições próprias do Usuário, necessários à prestação de
                  serviços pelo Credenciado; para obtenção de documentação
                  complementar necessária à prestação dos serviços e/ou
                  fornecimento dos produtos contratados por Você, Usuário, junto
                  aos Credenciados; para informar e compartilhar resultados
                  obtidos a partir dos serviços contratados junto aos
                  Credenciados; para que Você, Usuário, possa ter à sua
                  disposição, no âmbito da Plataforma, os Dados que lhe forem
                  pertinentes, tanto para possibilitar um compartilhamento mais
                  rápido e dinâmico com eventuais outros Credenciados que possam
                  precisar de tais informações para a prestação de outros
                  serviços contratados por Você, Usuário, como para que tais
                  informações possam ser utilizadas por você com terceiros, fora
                  do âmbito da Plataforma; Com empresas vinculadas aos Serviços
                  da PASS TO PAY: na prestação de Serviços contratados para o
                  Usuário; operacionalização e oferta de novos Serviços;
                  marketing, prospecção, pesquisas de mercado, de opinião e
                  promoção dos Serviços; prevenção e resolução de problemas
                  técnicos ou de segurança; investigações e medidas de prevenção
                  e combate a ilícitos, fraudes, crimes financeiros e garantia
                  da segurança dos Usuários e do sistema financeiro; exercício
                  regular de direitos; cumprimento de obrigação legal ou
                  regulatória; Com parceiros de negócios, prestadores de serviço
                  e outros terceiros tais como fornecedores de serviços de
                  tecnologia da informação, de atendimento ao consumidor, de
                  comunicação, de serviços estatísticos, de pesquisas,
                  marketing, serviços financeiros e de meios de pagamentos;
                  agências de cobrança, de crédito e prevenção a fraudes,
                  bancos, instituições financeiras e outros terceiros: para o
                  aprimoramento dos Serviços, Plataforma e aplicativo e
                  operacionalização de novos produtos ou serviços, auxílio na
                  prestação dos serviços que entregamos aos Usuários, por
                  exemplo, confecção e entrega de acessórios; contato por
                  telefone, e-mail, SMS, WhatsApp, notificação push ou outros
                  meios de comunicação; auxílio no desenvolvimento e oferta dos
                  nossos produtos financeiros; cobrança de dívidas; marketing,
                  prospecção, pesquisas de mercado, de opinião e promoção dos
                  nossos produtos e serviços; prevenção e resolução de problemas
                  técnicos ou de segurança; investigações e medidas de prevenção
                  e combate a ilícitos, fraudes, crimes financeiros e garantia
                  da segurança dos Usuários e do sistema financeiro; exercício
                  regular de direitos; cumprimento de ordem judicial, de
                  autoridade competente ou de órgão fiscalizador; cumprimento de
                  obrigação legal ou regulatória; Com autoridades e órgãos
                  reguladores: para investigações e medidas de prevenção e
                  combate a ilícitos, fraudes, crimes financeiros e garantia da
                  segurança dos Usuários e do sistema financeiro; exercício
                  regular de direitos, inclusive apresentando documentos em
                  processos judiciais e administrativos, se necessário;
                  cumprimento de ordem judicial, atendimento de solicitação de
                  autoridade competente ou órgão fiscalizador; cumprimento de
                  obrigação legal ou regulatória; Mediante a sua solicitação:
                  para garantir a transparência na relação com Você, Usuário;
                  pelo envio de notificações não-obrigatórias por e-mails, push,
                  WhatsApp e SMS. Ao utilizar a Plataforma, por meio de browser
                  ou aplicativo, poderá haver situações em que Você, Usuário,
                  será redirecionado para sites ou aplicativos de terceiros.
                  Nestes casos, uma vez realizado o redirecionamento para um
                  site ou aplicativo de terceiros, as práticas de privacidade
                  destes ambientes serão regidas pelas políticas de privacidade
                  e pelos termos de uso destes terceiros, não havendo qualquer
                  tipo de responsabilidade ou solidariedade da PASS TO PAY por
                  fatos verificados em sites e aplicativos de terceiros.
                  Retenção e Exclusão dos seus Dados Seus dados pessoais serão
                  sempre armazenados e mantidos em ambiente seguro e controlado
                  durante todo o período em que, Você, Usuário, ostentar a
                  condição de Usuário ou tiver acesso à Plataforma. Quando
                  aplicável, e mesmo após o cancelamento/exclusão de seu Perfil
                  na Plataforma, seus Dados poderão ser armazenados por um
                  período adicional não inferior a 6 (seis) meses a partir do
                  cancelamento/exclusão, para fins de auditoria, cumprimento de
                  obrigações legais ou regulatórias, bem como para o exercício
                  regular de direitos ou, ainda, pelo prazo necessário de acordo
                  com a base legal que justifique a retenção dos dados. Seus
                  Direitos como Titular de Dados Você, Usuário, é o efetivo
                  titular de seus Dados e, como tal, poderá exercer seus
                  direitos perante os controladores e operadores dos seus Dados,
                  conforme melhor detalhado, de forma clara e transparente, na
                  Cláusula 6.2, abaixo. Os Usuários possuem os seguintes
                  direitos: Confirmação da existência de tratamento de dados
                  pessoais: O fato de Você ser Usuário da Plataforma já
                  significa que a PASS TO PAY faz o tratamento dos seus Dados,
                  mesmo que esse tratamento seja, entre outros, o armazenamento
                  de dados pessoais em ambiente seguro e controlado. Ainda
                  assim, você pode solicitar à equipe da PASS TO PAY que
                  confirme a realização do tratamento dos seus dados pessoais.
                  Acesso aos Dados: Você, Usuário, pode solicitar, a qualquer
                  tempo, que a PASS TO PAY informe e forneça os Dados que possui
                  em relação a Você. Correção de Dados incompletos, inexatos ou
                  desatualizados: Se Você, Usuário, verificar que os seus Dados
                  estão incompletos, inexatos ou desatualizados e não puder, por
                  conta própria, retificar os mesmos, poderá sempre pedir a
                  correção ou complementação de tais Dados à PASS TO PAY. Para
                  isso Você precisará enviar um documento que comprove a forma
                  correta e atual do dado pessoal. Anonimização, bloqueio ou
                  eliminação de dados desnecessários, excessivos ou tratados em
                  desconformidade com a LGPD: Caso quaisquer Dados tratados
                  sejam desnecessários, em excesso para a finalidade a que se
                  destinam ou em desconformidade com a LGPD, Você, Usuário,
                  poderá solicitar que a PASS TO PAY anonimize, bloqueie ou
                  elimine tais Dados, desde que efetivamente constatado o
                  excesso, falta de necessidade ou desconformidade com a lei.
                  Eliminação de Dados tratados com o consentimento: Caso Você,
                  Usuário, tenha consentido com o tratamento de seus Dados para
                  finalidades específicas, não imprescindíveis para a prestação
                  dos Serviços, Você poderá solicitar à PASS TO PAY a eliminação
                  de tais Dados. Informação das empresas com as quais a PASS TO
                  PAY compartilhou ou das quais recebeu seus Dados: Você pode
                  solicitar que a PASS TO PAY informe com quais terceiros
                  compartilhou ou de quem recebeu seus Dados. Informação sobre a
                  possibilidade de não fornecer consentimento em determinado
                  caso e as consequências de tal negativa: Caso seu
                  consentimento seja necessário para acessar ou usar determinado
                  produto ou serviço, você pode solicitar à PASS TO PAY que
                  esclareça se é possível fornecer esse produto ou prestar esse
                  serviço sem o seu consentimento para o tratamento de seus
                  Dados, bem como informe quais seriam as consequências do não
                  consentimento no caso específico. Revogação do consentimento:
                  Caso Você, Usuário, tenha dado o seu consentimento para
                  tratamento dos seus Dados, você poderá solicitar a revogação
                  desta autorização. A revogação do consentimento pode resultar
                  na impossibilidade de uso de algumas funcionalidades do
                  aplicativo ou até mesmo no encerramento dos Serviços
                  prestados, mas não impede o uso de (i) dados anonimizados; e
                  (ii) dados cujo tratamento esteja baseado em outra hipótese
                  legal prevista na LGPD. Decisões automatizadas: Você pode
                  solicitar a revisão de decisões tomadas unicamente com base em
                  tratamento automatizado de dados pessoais que afetem seus
                  interesses e a indicação dos critérios utilizados para essas
                  decisões. Por motivos de segredo de negócio, proteção de
                  informações confidenciais e preservação da concorrência, a
                  PASS TO PAY não informa a forma de funcionamento desses
                  sistemas automatizados. Importante destacar que a PASS TO PAY
                  não garante um resultado diferente da primeira decisão,
                  podendo apenas assegurar que seu modelo tecnológico é
                  consistente e pautado em princípios éticos e legais.
                  Portabilidade e direito de petição: Quando regulamentado o
                  direito de portabilidade pela Autoridade Nacional de Proteção
                  de Dados (ANPD), Você, Usuário, poderá solicitar à PASS TO PAY
                  a portabilidade dos seus dados pessoais a outro prestador de
                  serviços. Além disso, com a constituição da ANPD, você terá
                  também o direito de peticionar em relação aos seus Dados
                  perante a autoridade nacional. Caso Você, Usuário, deseje
                  exercer qualquer um desses direitos, deverá entrar em contato
                  com a PASS TO PAY por meio da área de Atendimento ao Cliente
                  disponível na Plataforma certo que, para resguardar os seus
                  direitos, serão solicitados meios de comprovação da sua
                  identidade, como medida de segurança e prevenção à fraude.
                  Registro de Atividades A PASS TO PAY está autorizada a
                  registrar as atividades que Você, Usuário, realiza quando
                  utiliza a Plataforma, criando, quando possível e aplicável,
                  logs (registros de atividades efetuadas nos sites e
                  aplicativos e serviços) contendo, dentre outros: endereço IP,
                  acesso e ações realizadas por Você no serviço disponibilizado,
                  data e hora de cada ação realizada, informações sobre o
                  dispositivo utilizado, tais como a versão de sistema
                  operacional, navegador, geolocalização etc. A PASS TO PAY
                  também está autorizada a utilizar tecnologias próprias ou de
                  terceiros para monitoramento das atividades realizadas
                  enquanto Você acessa a Plataforma, tais como: Cookies: são
                  arquivos de internet que armazenam de forma temporária o que
                  você está visitando na rede. A PASS TO PAY possui cookies em
                  seu Site e Plataforma e, também, recebe informações de
                  parceiros a respeito de cookies inseridos nos seus respectivos
                  websites. Os cookies podem ser utilizados para diversos
                  propósitos, incluindo lembrar-se de Você e de suas
                  preferências, persistir informações relacionadas a suas
                  atividades no site visitado, ou coletar informações que podem
                  ser usadas para oferecer conteúdo de uma forma personalizada.
                  O Site e Plataforma da PASS TO PAY também poderá utilizar
                  objetos armazenados localmente para fornecer certos conteúdos
                  específicos. Cookies de Terceiros: A PASS TO PAY também possui
                  cookies de terceiros ativados em seu Site e Plataforma, de
                  modo que, nestes casos, as práticas de privacidade serão
                  regidas pelas políticas de privacidade e pelos termos de uso
                  de tais terceiros, ficando a PASS TO PAY, desde já, eximida de
                  qualquer responsabilidade pelo controle, armazenamento,
                  práticas e conteúdo relacionado à privacidade de terceiros.
                  Por isso, a qualquer momento Você, Usuário, poderá bloquear o
                  uso dos cookies em sua navegação, ativando a configuração no
                  seu navegador de internet, certo que a capacidade de limitar
                  os cookies estará sujeita às configurações e limitações do seu
                  navegador. Você, Usuário, também poderá excluir os cookies
                  existentes através das mesmas configurações do seu navegador
                  de internet. Caso opte por desativar os cookies, Você,
                  Usuário, poderá continuar navegando normalmente no Site e
                  Plataforma, mas algumas partes das páginas poderão deixar de
                  funcionar. Web beacon: Web beacon é uma técnica que permite
                  mapear quem está visitando uma determinada página da web,
                  identificando o seu comportamento com diferentes sites ou
                  servidores da web. Ferramentas de analytics: Estas ferramentas
                  podem coletar informações como a forma que você visita um
                  site, incluindo quais páginas e quando você visita tais
                  páginas, além de outros sites que foram visitados antes, entre
                  outras. Todas as tecnologias utilizadas pela PASS TO PAY em
                  seu Site e Plataforma sempre respeitarão os termos desta
                  Política de Privacidade. Transferência Internacional de Dados
                  Pessoais Você, Usuário, neste ato, declara possuir pleno
                  conhecimento e concordar que alguns de seus Dados (ou até
                  mesmo todos eles), poderão eventualmente ser transferidos para
                  o exterior para tratamento, como quando, por exemplo, os
                  mesmos são armazenados em servidores de computação em nuvem
                  localizados fora do Brasil. Em qualquer hipótese, a PASS TO
                  PAY adotará sempre todas as providências necessárias para
                  garantir o cumprimento desta Política de Privacidade e da
                  legislação vigente, aplicando as melhores práticas de
                  segurança e privacidade para garantir a integridade e
                  confidencialidade dos seus Dados. Tratamento de Dados
                  Sensíveis Dentre os Dados coletados e tratados pela PASS TO
                  PAY para o adequado funcionamento da Plataforma e prestação
                  dos Serviços, existem alguns que merecem especial destaque por
                  serem considerados “Dados Sensíveis” pela legislação em vigor,
                  assim denominados pela LGPD como sendo todo e qualquer dado
                  pessoal sobre origem racial ou étnica, convicção religiosa,
                  opinião política, filiação a sindicato ou a organização de
                  caráter religioso, filosófico ou político, dado referente à
                  saúde ou à vida sexual, dado genético ou biométrico, quando
                  vinculado a uma pessoa natural, bem como, bem como aqueles
                  relacionados a crianças ou adolescentes. Parágrafo Primeiro.
                  Neste ato, Você, Usuário, declara possuir plena ciência e
                  concordar que a PASS TO PAY também poderá, eventualmente,
                  proceder à coleta e tratamento de Dados Sensíveis,
                  especialmente no que concerne a Dados Médicos e de Saúde,
                  quando necessários à prestação de serviços e/ou fornecimento
                  de produtos contratados por Você, Usuário, junto a
                  Credenciados no âmbito da Plataforma. Parágrafo Segundo. A
                  PASS TO PAY sempre adotará medidas técnicas e organizacionais
                  para proteger, manter o sigilo médico e prevenir qualquer tipo
                  de acesso ou utilização indevida, destruição, violação,
                  corrupção ou divulgação não permitida de Dados Sensíveis. A
                  utilização de Dados Sensíveis no âmbito da Plataforma, será
                  sempre destinada a propósitos específicos e predeterminados.
                  Os Dados Sensíveis estarão sempre sujeitos a um rígido
                  controle de acesso por meio de criptografia e outras medidas
                  de segurança, de modo a assegurar que tanto o armazenamento
                  como o fluxo destas informações sejam sempre o mais seguro e
                  criterioso possível. A PASS TO PAY não poderá utilizar os
                  Dados Sensíveis para fins diversos daqueles pretendidos no
                  momento de sua coleta, não podendo utilizar os mesmos para
                  seleção de risco relacionada aos Serviços, muito menos para
                  estratégias comerciais, de marketing, publicidade direcionada,
                  comercialização de outros produtos ou serviços da PASS TO PAY
                  ou de terceiros. Parágrafo Primeiro. Os Dados Sensíveis
                  poderão, no entanto, ser armazenados, compartilhados e
                  utilizados no âmbito da Plataforma para assegurar a adequada
                  prestação dos serviços e/ou fornecimento de produtos
                  contratados por Você, Usuário, junto aos Credenciados, nos
                  termos na Cláusula 4.2.(i), acima. Assim, neste ato, Você,
                  Usuário, autoriza os Credenciados que vier a contratar por
                  meio da Plataforma, a nela inserir as informações pertinentes
                  relacionadas a Você, Usuário, e ao serviço prestado para que,
                  assim, Você possa ter acesso a tais informações, bem como
                  possa compartilhar as mesmas com quem quiser, seja no âmbito
                  da Plataforma, seja com terceiros, estranhos à PASS TO PAY. O
                  Credenciado, por sua vez, se compromete a inserir na
                  Plataforma todas as informações pertinentes à prestação de
                  serviços contratada pelos Usuários junto a ele, atendendo
                  também aos requerimentos feitos diretamente pelos Usuários
                  conforme o caso. Parágrafo Segundo. Os Dados Sensíveis
                  poderão, ainda, ser utilizados fora do âmbito da Plataforma,
                  de maneira agregada e anonimizada em estudos e pesquisas de
                  saúde populacional para orientação das ações da PASS TO PAY e
                  melhora na eficiência da Plataforma e Serviços. A PASS TO PAY
                  não coleta intencionalmente informações de menores de 18
                  (dezoito) anos, podendo apenas fazê-lo mediante a autorização
                  prévia e expressa outorgada pelo responsável legal pelo menor,
                  hipótese em que o tratamento dos Dados do menor poderá ocorrer
                  para a finalidade e nos termos abrangidos pela respectiva
                  autorização. Medidas de Segurança A PASS TO PAY usa diversos
                  tipos de medidas de segurança para garantir a integridade de
                  seus Dados, como padrões de segurança de informação praticados
                  pela indústria quando da coleta e armazenamento de seus Dados.
                  Você, Usuário, concorda que seus Dados poderão, ainda, ser
                  armazenados por meio de tecnologia de cloud computing
                  (“nuvem”) e outras tecnologias que surjam futuramente, visando
                  sempre a melhoria e aperfeiçoamento da Plataforma, dos
                  Serviços e sua segurança. A segurança de seus Dados é tratada
                  com o máximo de cuidado, utilizando padrões e melhores
                  práticas adotados no mercado. A PASS TO PAY possui equipe
                  robusta, altamente qualificada e responsável por garantir a
                  adoção das melhores práticas de segurança, dentre elas:
                  Múltiplo fator de autenticação para acesso às informações;
                  Segurança como código, a fim de viabilizar automações e
                  respostas rápidas e eficientes para eventos de segurança no
                  ambiente tecnológico; Criptografia para dados em repouso, em
                  trânsito e em uso, para garantir a integridade das
                  informações; Monitoramento contínuo do ambiente; Firewalls;
                  Análises e testes contínuos de segurança da informação em
                  nossos sistemas, feitos por times internos e externos;
                  Auditorias periódicas. Você, Usuário, tem plena consciência de
                  que seus dados de acesso, como login e senha, são pessoais e
                  intransferíveis, não devendo ser compartilhados com mais
                  ninguém. Consentimento Ao aderir e aceitar esta Política de
                  Privacidade, Você, Usuário, consente com o tratamento dos
                  Dados nas formas aqui indicadas. Alguns de seus Dados somente
                  poderão ser coletados e tratados pela PASS TO PAY com o seu
                  consentimento prévio e expresso, sendo que, nestes casos, Você
                  será informado de forma clara e expressa na Plataforma acerca
                  da necessidade de consentimento. O tratamento de seus Dados é
                  condição necessária para o adequado funcionamento da
                  Plataforma e prestação dos Serviços. Caso Você tenha dúvidas
                  sobre qualquer um dos termos aqui explicados, basta entrar em
                  contato por meio dos canais de atendimento da PASS TO PAY.
                  Aplicação Esta Política de Privacidade é aplicável a todos os
                  Usuários da PASS TO PAY, os quais terão seus Dados coletados
                  desde o momento da instalação, acesso e/ou utilização da
                  Plataforma da PASS TO PAY, bem como de seu Site, aplicativos,
                  serviços, recursos e software, assim como quando da
                  contratação de quaisquer produtos ou serviços por meio da
                  Plataforma, quando da realização de pagamentos ou transações
                  através da sua Carteira Digital, comunicação com a PASS TO PAY
                  pelos canais de atendimento e, também, participação de
                  eventuais pesquisas e promoções. Esta Política de Privacidade
                  se aplica especificamente aos Usuários da Plataforma PASS TO
                  PAY, entendidos como toda e qualquer pessoa, física ou
                  jurídica, cadastrada na Plataforma. Esta Política de
                  Privacidade também é aplicável a outras formas de coleta de
                  Dados pela PASS TO PAY, que permitem a prestação ou o
                  aprimoramento de seus Serviços, por exemplo, por meio de
                  parceiros ou relativas às nossas tecnologias. A Política de
                  Privacidade da PASS TO PAY abrange suas controladoras e
                  controladas, direta ou indiretamente, bem como suas coligadas
                  e sociedades sob controle comum no Brasil. Você, Usuário,
                  declara ter plena consciência de que a controladora dos seus
                  Dados, ou seja, a empresa responsável por tomar as decisões
                  sobre o tratamento dos seus dados pessoais, será a PASS TO PAY
                  INTERMEDIAÇÃO DOS SERVIÇOS ONLINE LTDA., pessoa jurídica de
                  direito privado com sede em São Paulo/SP, na Rua Gomes de
                  Carvalho, 911, Vila Olímpia, CEP 04547-003, inscrita no
                  CNPJ/MF sob nº 39.584.819/0001-89. A PASS TO PAY poderá
                  proceder ao tratamento de Dados dos Usuários de forma direta
                  ou por meio da contratação de empresas especializadas neste
                  serviço que, em todo caso, deverão respeitar os termos da
                  presente Política de Privacidade e os ditames da lei. As
                  práticas descritas nesta Política de Privacidade se aplicam ao
                  tratamento de seus Dados e estarão sempre sujeitas às Leis da
                  República Federativa do Brasil, com especial destaque para a
                  Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais ou
                  simplesmente LGPD). Disposições Gerais A PASS TO PAY poderá
                  alterar esta Política de Privacidade a qualquer tempo. Toda
                  vez que alguma condição relevante desta Política de
                  Privacidade for alterada, essas alterações serão válidas,
                  eficazes e vinculantes tão logo a sua nova versão seja
                  divulgada nas Plataformas ou enviada por e-mail para Você,
                  Usuário. Toda vez que uma alteração relevante for feita, a
                  PASS TO PAY enviará um comunicado expresso aos Usuários
                  indicando a nova versão vigente. Ao continuar a Plataforma
                  após uma alteração na Política de Privacidade, Você, Usuário,
                  estará automaticamente concordando com as novas condições,
                  podendo sempre, contudo, manifestar a sua eventual
                  discordância no caso concreto por meio dos canais de
                  atendimento da PASS TO PAY. Nas hipóteses em que as alterações
                  desta Política de Privacidade resultarem em mudanças nas
                  práticas de tratamento de Dados que dependam do seu
                  consentimento, Você, Usuário, será comunicado e deverá,
                  novamente, demonstrar o seu consentimento com os novos termos
                  da Política de Privacidade em relação ao tratamento de Dados e
                  finalidades indicados. Todas as disposições desta Política de
                  Privacidade que prevejam a observância de obrigações ou
                  responsabilidades após a rescisão ou extinção deste Termo e/ou
                  dos Serviços, subsistirão a sua rescisão ou extinção e
                  continuarão em pleno vigor e efeito, em especial no que tange
                  à confidencialidade, armazenamento e privacidade de dados e
                  informações. As Partes reconhecem que, na hipótese de qualquer
                  descumprimento das obrigações supracitadas, estarão sujeitas
                  às sanções legais cabíveis. AO ACEITAR OS TERMOS DA PRESENTE
                  POLÍTICA DE PRIVACIDADE, VOCÊ, USUÁRIO, DECLARA TER CAPACIDADE
                  LEGAL PARA TANTO, DANDO O SEU CONSENTIMENTO EXPRESSO E
                  IRRESTRITO EM RELAÇÃO A TODO O CONTEÚDO DESTE DOCUMENTO.
                </p>
              </Modal>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export { Dashboard };
