import { useContextSelector } from 'use-context-selector';
import { MouseEvent } from 'react';

import { MenuContext } from '../contexts/menu';

interface IMenuContext {
  menuType: string;
  handleChangeOfMenus(menu: string, e: MouseEvent): void;
}

function useMenuTypes(): IMenuContext {
  const menuType = useContextSelector(
    MenuContext,
    menuContext => menuContext.menuType,
  );
  const handleChangeOfMenus = useContextSelector(
    MenuContext,
    menuContext => menuContext.handleChangeOfMenus,
  );

  return {
    handleChangeOfMenus,
    menuType,
  };
}

export { useMenuTypes };
