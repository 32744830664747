import { FormHandles } from '@unform/core';
import { FC, useCallback, useRef, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import QRCode from 'react-qr-code';
import { Canvg } from 'canvg';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { URLPath } from '@components/layouts/UrlPath';
import { Button } from '@components/elements/Button';
import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Input } from '@components/elements/Form/Input';
import { Money } from '@components/layouts/Money';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Textarea } from '@components/elements/Form/Textarea';
import { Modal } from '@components/elements/Modal';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import { bankApi } from '@services/bankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { consoleLog } from '@helpers/consoleLog';

import { IFormData } from './interfaces';
import { formValidation } from './validations';
import { QRWrapper } from './styles';

export const GenerateStaticPixQRCode: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const svgRef = useRef<any>(null);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        const formData = data;

        delete formData.userDocument;
        delete formData.userLogged;

        await formValidation(formData);

        formData.amount = data.amount * 100;

        const { data: responseData } = await bankApi.post(
          '/pix/qr-codes/static',
          data,
        );

        addToast({
          title: 'Legal! Cobrança criada com sucesso.',
          type: 'success',
        });

        setModalVisible(true);
        setQrCode(responseData.emv);
        setLoadingRequest(false);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          consoleLog(errors);

          formRef.current?.setErrors(errors);

          setLoadingRequest(false);

          return;
        }

        if (err.response) {
          const { message } = getClientErrors(err);

          addToast({
            title: 'Ops... Algo não deu certo!',
            type: 'error',
            message,
          });
        }

        setLoadingRequest(false);
      }
    },
    [addToast],
  );

  const handleDownloadAsPdf = useCallback(async () => {
    const canvasElement = document.querySelector('canvas') as HTMLCanvasElement;
    const ctx = canvasElement.getContext('2d') as CanvasRenderingContext2D;

    const loaded = await Canvg.from(ctx, svgRef.current?.outerHTML);

    if (!loaded) {
      return;
    }

    await loaded.render();

    const img = canvasElement.toDataURL('image/png');

    const link = document.createElement('a');

    link.setAttribute('href', img);
    link.setAttribute('download', 'qr-code.png');

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }, []);

  const handlePixButtonClick = () => {
    navigator.clipboard
      .writeText(qrCode)
      .then(() => {
        addToast({
          title: 'Copiado!',
          type: 'info',
        });
      })
      .catch(error => {
        console.error('Erro ao copiar o QR Code:', error);
      });
  };

  return (
    <>
      <AuthModal />

      <URLPath paths={['Pass to Pay', 'PIX', 'Novo QR Code estático']} />

      <Money initVisible />

      <Card>
        <CardHeader>
          <h1>Novo QR Code</h1>
        </CardHeader>

        <CardContent>
          <Form onSubmit={handleFormSubmit} ref={formRef}>
            <FormRow>
              <InputGroup>
                <label>Seu nome</label>
                <Input name="userLogged" disabled defaultValue={user.name} />
              </InputGroup>

              <InputGroup>
                <label>Seu CPF/CNPJ</label>
                <Input
                  name="userDocument"
                  disabled
                  defaultValue={user.document}
                />
              </InputGroup>
            </FormRow>

            <FormRow>
              <InputGroup>
                <label>Para</label>
                <Input
                  name="title"
                  placeholder="Ex: Cobrança da loja 1"
                  upperCase={false}
                />
              </InputGroup>

              <InputGroup>
                <label>Valor</label>
                <InputCurrency name="amount" />
              </InputGroup>
            </FormRow>

            <FormRow>
              <InputGroup textarea>
                <label>Descrição (opcional)</label>
                <Textarea name="comments" />
              </InputGroup>
            </FormRow>

            <FormRow buttonWrapper>
              <Button
                styleType="success"
                icon={FiSave}
                type="submit"
                loading={loadingRequest}
              >
                Criar cobrança
              </Button>
            </FormRow>
          </Form>
        </CardContent>
      </Card>

      <Modal
        showPixButton
        onPixButtonClick={handlePixButtonClick}
        visible={modalVisible}
        onOkClick={handleDownloadAsPdf}
        okButtonText="Fazer download da imagem"
        headerText="Código QR"
        hide={{ closeButton: true, xButton: false }}
        hideButton
        onDismiss={({ visible }) => setModalVisible(visible)}
      >
        <>
          <QRWrapper>
            <QRCode
              ref={svgRef}
              size={256}
              style={{ height: 295, width: 295 }}
              value={qrCode}
              viewBox="0 0 256 256"
            />
          </QRWrapper>
          <canvas id="canvas" style={{ display: 'none' }} />
        </>
      </Modal>
    </>
  );
};
