import { FC, useCallback, useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';

import { ITermsOfUseContext, IStoragedData } from './interfaces/termsOfUse';
import { useAuth } from '../hooks/auth';

export const TermsOfUseContext = createContext<ITermsOfUseContext>(
  {} as ITermsOfUseContext,
);

const TermsOfUseProvider: FC = ({ children }) => {
  const [termsOfUseRead, setTermsOfUseRead] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    const storagedData = localStorage.getItem('@BbankDashboard:TermsOfUse');

    if (storagedData) {
      const parsedStoragedData = JSON.parse(storagedData) as IStoragedData[];

      const findUserWhoReadTermsOfUse = parsedStoragedData.find(
        ({ key }) => key === user.id,
      );

      if (findUserWhoReadTermsOfUse) {
        setTermsOfUseRead(true);
        return;
      }

      setTermsOfUseRead(false);
    }

    setTermsOfUseRead(false);
  }, [user]);

  const markTermsOfUseAsRead = useCallback(() => {
    const dataStoraged = localStorage.getItem('@BbankDashboard:TermsOfUse');
    const userWhoReadTermsOfUse = { key: user.id, termsOfUseRead: true };

    if (dataStoraged) {
      const parsedStoragedData = JSON.parse(dataStoraged) as IStoragedData[];

      const findUserWhoReadTermsOfUse = parsedStoragedData.find(
        ({ key }) => key === user.id,
      );

      if (findUserWhoReadTermsOfUse) {
        return;
      }

      parsedStoragedData.push(userWhoReadTermsOfUse);

      localStorage.removeItem('@BbankDashboard:TermsOfUse');
      localStorage.setItem(
        '@BbankDashboard:TermsOfUse',
        JSON.stringify(parsedStoragedData),
      );

      setTermsOfUseRead(true);

      return;
    }

    const dataToBeStoraged = [userWhoReadTermsOfUse];
    const parsedDataToBeStoraged = JSON.stringify(dataToBeStoraged);

    localStorage.removeItem('@BbankDashboard:TermsOfUse');
    localStorage.setItem('@BbankDashboard:TermsOfUse', parsedDataToBeStoraged);

    setTermsOfUseRead(true);
  }, [user]);

  return (
    <TermsOfUseContext.Provider
      value={{ termsOfUseRead, markTermsOfUseAsRead }}
    >
      {children}
    </TermsOfUseContext.Provider>
  );
};

export { TermsOfUseProvider };
