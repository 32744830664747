import { FC, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { CheckboxGroup } from '@components/elements/Form/CheckboxGroup';
import { Select } from '@components/elements/Form/Select';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { Checkbox } from '@components/elements/Form/Checkbox';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { IFormData, IProspect } from './interfaces';
import {
  questionOne,
  questionTwo,
  questionFour,
  questionFive,
  questionSix,
  questionTen,
  getProspectsOptions,
} from './selectOptions';
import { formValidation } from './validations';

const BbankConnectResearch: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [prospects, setProspects] = useState<IProspect[]>();

  useEffect(() => {
    async function loadProspects() {
      const { data } = await api.get(`/prospects/indications/${user.id}`);

      setProspects(data);
    }

    const timer = setTimeout(() => loadProspects(), 1500);

    return () => clearTimeout(timer);
  }, [user.id]);

  const handeleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const score = Object.entries(data).reduce((acc, [_, value]) => {
          const number = Number(value);

          if (Number.isNaN(number)) {
            return acc + 0;
          }

          return acc + number;
        }, 0);

        await api.post(`/telemedicine/scores`, {
          score,
          prospectId: data.prospectId,
          sellerId: user.id,
        });

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'A pesquisa foi computada com sucesso!',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, user.id],
  );

  const propectsOptions = useMemo(() => {
    if (!prospects) {
      return [];
    }

    return getProspectsOptions(prospects);
  }, [prospects]);

  return (
    <>
      {!prospects ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Bbank Connect', 'Pesquisa de Mercado', 'Nova pesquisa']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Nova pesquisa de mercado para Bbank Connect</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handeleFormSubmit} ref={formRef}>
                  <FormRow>
                    <InputGroup>
                      <label>Escolha um prospecto</label>
                      <Select name="prospectId" options={propectsOptions} />
                    </InputGroup>

                    <InputGroup>
                      <label>1 - Como você avalia a sua saúde?</label>
                      <Select name="questionOne" options={questionOne} />
                    </InputGroup>

                    <InputGroup checkbox>
                      <label>
                        2 - Você sofre atualmente de uma doença crônica?
                      </label>
                      <Select name="questionTwo" options={questionTwo} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup checkbox>
                      <label>
                        3 - Você tem alguma doença ou condição hereditária?
                      </label>
                      <CheckboxGroup
                        labelText="Pressão arterial alta"
                        labelHtmlFor="questionTwoItemOne"
                      >
                        <Checkbox
                          name="questionTwo-itemOne"
                          id="questionTwoItemOne"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Diabetes"
                        labelHtmlFor="questionTwoItemTwo"
                      >
                        <Checkbox
                          name="questionTwo-itemTwo"
                          id="questionTwoItemTwo"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Outras"
                        labelHtmlFor="questionTwoItemThree"
                      >
                        <Checkbox
                          name="questionTwo-itemThree"
                          id="questionTwoItemThree"
                          value="5"
                        />
                      </CheckboxGroup>
                    </InputGroup>

                    <InputGroup>
                      <label>
                        4 - Com que frequência você faz um check-up médico?
                      </label>
                      <Select name="questionFour" options={questionFour} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        5- Como você avalia os hospitais locais na área que você
                        vive?
                      </label>
                      <Select name="questionFive" options={questionFive} />
                    </InputGroup>

                    <InputGroup>
                      <label>6 - Você tem plano de saúde?</label>
                      <Select name="questionSix" options={questionSix} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup checkbox>
                      <label>
                        7 - A falta de cobertura do plano de saúde fez você
                      </label>
                      <CheckboxGroup
                        labelText="Não comparecer a uma consulta com o médico"
                        labelHtmlFor="questionSevenItemOne"
                      >
                        <Checkbox
                          name="questionSevenItemOne"
                          id="questionSevenItemOne"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Adiar uma consulta com o médico"
                        labelHtmlFor="questionSevenItemTwo"
                      >
                        <Checkbox
                          name="questionSevenItemTwo"
                          id="questionSevenItemTwo"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Não comprar medicamentos"
                        labelHtmlFor="questionSevenItemThree"
                      >
                        <Checkbox
                          name="questionSevenItemThree"
                          id="questionSevenItemThree"
                          value="3"
                        />
                      </CheckboxGroup>
                    </InputGroup>

                    <InputGroup checkbox>
                      <label>8 - O plano de saúde ideal é?</label>
                      <CheckboxGroup
                        labelText="Que eu possa pagar"
                        labelHtmlFor="questionEightItemOne"
                      >
                        <Checkbox
                          name="questionEightItemOne"
                          id="questionEightItemOne"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Que me atenda a qualquer momento"
                        labelHtmlFor="questionEightItemTwo"
                      >
                        <Checkbox
                          name="questionEightItemTwo"
                          id="questionEightItemTwo"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Que eu consiga ir até ao atendimento"
                        labelHtmlFor="questionEightItemThree"
                      >
                        <Checkbox
                          name="questionEightItemThree"
                          id="questionEightItemThree"
                          value="5"
                        />
                      </CheckboxGroup>
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup checkbox>
                      <label>
                        9 - Quantas pessoas da sua família precisam de
                        atendimento médico?
                      </label>
                      <CheckboxGroup
                        labelText="De 1 a 3"
                        labelHtmlFor="questionNineItemOne"
                      >
                        <Checkbox
                          name="questionNineItemOne"
                          id="questionNineItemOne"
                          value="3"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="De 4 a 10"
                        labelHtmlFor="questionNineItemTwo"
                      >
                        <Checkbox
                          name="questionNineItemTwo"
                          id="questionNineItemTwo"
                          value="5"
                        />
                      </CheckboxGroup>

                      <CheckboxGroup
                        labelText="Acima de 10"
                        labelHtmlFor="questionNineItemThree"
                      >
                        <Checkbox
                          name="questionNineItemThree"
                          id="questionNineItemThree"
                          value="5"
                        />
                      </CheckboxGroup>
                    </InputGroup>

                    <InputGroup>
                      <label>
                        10 - Qual o valor você pode investir na sua saúde?
                      </label>
                      <Select name="questionTen" options={questionTen} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiSave}>
                      Salvar nova pesquisa
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { BbankConnectResearch };
