import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiDownload, FiEye, FiMoreHorizontal } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';

import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { maskHandler } from '@helpers/maskHandler';

import { IECommerce, IParsedStatus } from './interfaces';

export const ListECommerce: FC = () => {
  const { push } = useHistory();

  const [ecommerceList, setECommerceList] = useState<IECommerce[]>();

  useEffect(() => {
    async function getECommerceList() {
      const { data } = await api.get<any[]>('/ecommerce', {
        params: {
          relations: ['ecommerce_files'],
        },
      });

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IECommerce>(item),
      );

      setECommerceList(parsedData);
    }

    const timer = setTimeout(() => getECommerceList(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleGoToDetailsPage = useCallback(
    (ecommerceId: string) => {
      push(`/ecommerce/${ecommerceId}/edit`);
    },
    [push],
  );

  const handleGoToFilesPage = useCallback(
    (ecommerceId: string) => {
      push(`/ecommerce/${ecommerceId}/files`);
    },
    [push],
  );

  const parsedStatus = useMemo<IParsedStatus>(() => {
    return {
      active: {
        badgeStyle: 'success',
        badgeText: 'Ativo',
      },
      pending: {
        badgeStyle: 'warning',
        badgeText: 'Pendente',
      },
      denied: {
        badgeStyle: 'danger',
        badgeText: 'Negado',
      },
      contract_pending: {
        badgeStyle: 'info',
        badgeText: 'Contrato pendente',
      },
    };
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Documento',
        selector: 'search',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Limite de crédito',
        selector: 'creditLimit',
      },
      {
        name: 'Solicitado em',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!ecommerceList) {
      return [];
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const data = ecommerceList.map(item => {
      const mask = item.document.length === 11 ? 'cpf' : 'cnpj';

      return {
        id: item.id,
        search: maskHandler({ mask, payload: item.document }),
        status: (
          <Badge type={parsedStatus[item.status].badgeStyle}>
            {parsedStatus[item.status].badgeText}
          </Badge>
        ),
        creditLimit: numberFormat(item.creditLimit),
        createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="success"
              title="Ver detalhes"
              onClick={() => handleGoToDetailsPage(item.id)}
            />
            <TableButton
              icon={FiDownload}
              styleType="info"
              title="Ver arquivos"
              onClick={() => handleGoToFilesPage(item.id)}
              disabled={item.ecommerceFiles.length === 0}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [ecommerceList, parsedStatus, handleGoToFilesPage, handleGoToDetailsPage]);

  const handleExportData = useCallback(() => {
    if (!ecommerceList) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const worksheetCells = ecommerceList.map(ecommerce => {
      const mask = ecommerce.document.length === 11 ? 'cpf' : 'cnpj';

      return {
        Documento: maskHandler({ mask, payload: ecommerce.document }),
        'Limite de crédito': numberFormat(ecommerce.creditLimit),
        Status: parsedStatus[ecommerce.status].badgeText,
        'Solicitado em': format(new Date(ecommerce.createdAt), 'dd/MM/yyyy'),
      };
    });

    exportFromJSON({
      data: worksheetCells,
      exportType: 'xls',
      fileName: 'ecommerce',
    });
  }, [ecommerceList, parsedStatus]);

  return (
    <>
      {!ecommerceList ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['ECommerce', 'Lista']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>ECommerces cadastrados</h1>

                <Dropdown
                  styles={{
                    textColor: '#fff',
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
