import { useState, FC, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { InputMask } from '@components/elements/Form/InputMask';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import {
  ITelemedicineScore,
  IParams,
  IScoreBadgeType,
  ISeller,
} from './interfaces';

const TelemedicineResearchDetails: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [score, setScore] = useState<ITelemedicineScore>();
  const [seller, setSeller] = useState<ISeller>();

  useEffect(() => {
    async function loadTelemedicineScore() {
      const { data } = await api.get(
        `/telemedicine/scores/${params.telemedicineScoreId}`,
        {
          params: {
            relations: ['prospect', 'seller'],
          },
        },
      );

      setScore(data);
      setSeller(data.seller);
    }

    const timer = setTimeout(() => loadTelemedicineScore(), 1500);

    return () => clearInterval(timer);
  }, [params.telemedicineScoreId]);

  const scoreBadge = useMemo<IScoreBadgeType>(() => {
    return {
      warm: {
        badgeText: 'Morno',
        badgeType: 'warning',
      },
      hot: {
        badgeText: 'Quente',
        badgeType: 'success',
      },
      cold: {
        badgeText: 'Frio',
        badgeType: 'danger',
      },
    };
  }, []);

  return (
    <>
      {!score ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'Bbank Connect',
                'Vendedores',
                seller?.name || '',
                'Pesquisa de mercado',
                'Detalhes',
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes da pesquisa de mercado selecionada</h1>

                <div>
                  <Badge type="info">
                    Pontuação:&nbsp;
                    {score.score}
                  </Badge>

                  <Badge type={scoreBadge[score.classification].badgeType}>
                    Classificação:&nbsp;
                    {scoreBadge[score.classification].badgeText}
                  </Badge>
                </div>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                  initialData={score}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Nome do vendedor</label>
                      <Input name="seller.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome do prospecto</label>
                      <Input name="prospect.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Informações do prospecto</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Email</label>
                      <Input name="prospect.email" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        name="prospect.phone"
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Estado que reside</label>
                      <Input name="prospect.state" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { TelemedicineResearchDetails };
