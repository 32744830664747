import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    data: Yup.array()
      .required('This field is required!')
      .of(
        Yup.object({
          inputType: Yup.string().required('Preencha este campo!'),
          barCode: Yup.string().when('inputType', {
            is: 'barCode',
            then: Yup.string().required('Preencha este campo!'),
          }),
          line: Yup.string().when('inputType', {
            is: 'line',
            then: Yup.string().required('Preencha este campo!'),
          }),
          description: Yup.string().required('Preencha este campo!'),
          type: Yup.string()
            .required('Preencha este campo!')
            .oneOf(['bills', 'taxes', 'billets'], 'Invalido!'),
        }),
      ),
  });

  await shape.validate(data, { abortEarly: false });
};
