import { FC, useMemo, useState } from 'react';
import Datatable, { TableProps } from 'react-data-table-component';

import { FilterInput } from './FilterInput';

interface ITableProps extends TableProps {
  filterPlaceholder?: string;
}

const DataTable: FC<ITableProps> = ({ data, filterPlaceholder, ...rest }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo<JSX.Element>(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterInput
        filterPlaceholder={filterPlaceholder}
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle, filterPlaceholder]);

  const filteredItems = useMemo(() => {
    const filtered = data.filter(item => {
      const hasSearchValue = Object.entries(item).some(([, value]) => {
        return `${value}`.toLowerCase().includes(filterText.toLowerCase());
      });

      return hasSearchValue;
    });

    return filtered;
  }, [filterText, data]);

  return (
    <Datatable
      data={filteredItems}
      {...rest}
      pagination={rest.pagination !== undefined ? rest.pagination : true}
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader={rest.subHeader !== undefined ? rest.pagination : true}
      subHeaderComponent={
        !rest.subHeaderComponent
          ? subHeaderComponentMemo
          : rest.subHeaderComponent
      }
    />
  );
};

export { DataTable };
