import styled from 'styled-components';

export const Card = styled.li`
  max-width: 134px;
  width: 100%;
  height: 166px;

  border: 1px solid #ebedf3;
  border-radius: 10px;

  transition: background-color 0.2s;

  &:hover {
    > a {
      color: ${({ theme }) => theme.colors.primary};

      > svg {
        stroke: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 2.3rem 1.4rem;

    height: 100%;

    text-decoration: none;
    color: #ccc;

    transition: color 0.2s;

    > span {
      display: block;

      text-align: center;
      font-size: 0.95rem;
      font-weight: 700;

      max-width: 90px;

      margin: 6.5px 0;
    }

    > svg {
      color: #ccc;

      margin: 0.45rem 0;

      transition: stroke 0.2s, color 0.2s;
    }
  }
`;
