import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FiEdit2, FiEye, FiTrash2 } from 'react-icons/fi';
import { OptionTypeBase } from 'react-select';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import { Card, CardContent } from '@components/layouts/Card';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { consoleLog } from '@helpers/consoleLog';

import { IForm, ITax, ITaxesGroup, IUser } from './interfaces';
import {
  validateIsolatedTaxesFormFields,
  validateTableTaxesFormFields,
} from './validations';
import { CardHeader } from './styles';

export const ManageClientTaxes = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<{ id: string }>();
  const { addToast } = useToast();
  const { push } = useHistory();

  const [user, setUser] = useState<IUser>();
  const [taxes, setTaxes] = useState<ITax[]>([]);
  const [taxesGroups, setTaxesGroups] = useState<ITaxesGroup[]>([]);
  const [prefix, setPrefix] = useState<string>();
  const [formMode, setFormMode] = useState<'isolated' | 'table'>('isolated');

  const parseTaxesFromGroup = useCallback((group: ITaxesGroup) => {
    return group.taxes.map((item: ITax) => {
      const taxesGroupName = group.name || '';

      return {
        ...item,
        product: `${item.product_name} (${taxesGroupName})`,
        isFromGroup: true,
      };
    });
  }, []);

  useEffect(() => {
    async function loadUser() {
      const [
        { data: responseUser },
        { data: userTaxes },
        { data: taxesGroupsResponse },
      ] = await Promise.all([
        await api.get(`/users/${params?.id}`, {
          params: {
            relations: ['taxes_group'],
          },
        }),
        await api.get(`/client-taxes/users/${params?.id}`),
        await api.get(`/taxes-group`),
      ]);

      let parsedUserTaxesFromGroup: ITax[] = [];

      if (responseUser.taxes_group) {
        parsedUserTaxesFromGroup = parseTaxesFromGroup(
          responseUser.taxes_group,
        );
      }

      setUser(responseUser);
      setTaxes([...parsedUserTaxesFromGroup, ...userTaxes]);
      setTaxesGroups(taxesGroupsResponse);
    }

    loadUser();
  }, [params, parseTaxesFromGroup]);

  const handleDeleteTax = useCallback(async ({ id }: ITax) => {
    await api.delete(`/client-taxes/${id}`);

    setTaxes(oldState => {
      const updatedState = oldState.filter(item => item.id !== id);

      return updatedState;
    });
  }, []);

  const tableData = useMemo(() => {
    return taxes.map(item => {
      const numberFormat = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;
      const taxString =
        item.type === 'percentage'
          ? `${item.value}%`
          : numberFormat(item.value);
      const taxIsFromGroup = !!item.isFromGroup;

      return {
        product: item.product_name,
        tax: taxString,
        actions: (
          <TableButtonContainer>
            {!taxIsFromGroup ? (
              <>
                <TableButton
                  icon={FiEdit2}
                  styleType="warning"
                  title="Editar"
                  onClick={() => {
                    consoleLog(item);

                    formRef.current?.setData(item);
                  }}
                />

                <TableButton
                  icon={FiTrash2}
                  styleType="danger"
                  title="Clique duas vezes para remover taxa"
                  onDoubleClick={() => handleDeleteTax(item)}
                />
              </>
            ) : (
              <TableButton
                icon={FiEye}
                styleType="success"
                title="Ver tabela referente"
                onClick={() =>
                  push(`/taxes-groups/details/${user?.taxes_group_id}`)
                }
              />
            )}
          </TableButtonContainer>
        ),
      };
    });
  }, [taxes, handleDeleteTax, push, user]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Produto',
        selector: 'product',
      },
      {
        name: 'Taxa aplicada',
        selector: 'tax',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleTaxTypeChange = useCallback((option: OptionTypeBase | null) => {
    if (!option) return;

    const { value } = option;

    if (value === 'percentage') {
      setPrefix('% ');
      return;
    }

    setPrefix('R$ ');
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IForm, { reset }) => {
      try {
        if (formMode === 'isolated') {
          await validateIsolatedTaxesFormFields(data);

          const { data: response } = await api.post('/client-taxes', {
            userId: user?.id,
            product: data.product,
            type: data.type,
            value: data.value,
          });

          setTaxes(oldState => {
            const findIndex = oldState.findIndex(
              item => item.id === response.id,
            );

            if (findIndex >= 0) {
              const updatedState = [...oldState];

              updatedState[findIndex] = response;

              return updatedState;
            }

            return [...oldState, response];
          });
        } else {
          await validateTableTaxesFormFields(data);

          const {
            data: { taxes_group_id },
          } = await api.put(`/users/${user?.id}`, {
            taxes_group_id: data.taxesGroupId,
          });

          const { data: responseData } = await api.get(
            `/taxes-group/${data.taxesGroupId}`,
          );

          const parsedGroup = parseTaxesFromGroup(responseData);

          setTaxes(oldState => [...oldState, ...parsedGroup]);
          setUser(oldState => {
            if (!oldState) return;

            return { ...oldState, taxes_group_id };
          });
        }

        addToast({
          title: 'Taxa aplicada!',
          type: 'success',
          message: 'A taxa foi adicionada com sucesso!',
        });

        reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        consoleLog(err);
      }
    },
    [addToast, user, formMode, parseTaxesFromGroup],
  );

  const handleRemoveCurrentTaxesGroup = useCallback(async () => {
    if (!user) return;

    await api.put(`/users/${user.id}`, { taxes_group_id: null });

    setTaxes(oldState => {
      const updatedState = oldState.filter(item => !item.isFromGroup);

      return updatedState;
    });
    setUser(oldState => {
      if (!oldState) return;

      return { ...oldState, taxes_group_id: null };
    });
  }, [user]);

  return (
    <>
      <Row>
        <URLPath paths={['Taxas', 'Gerenciar taxas']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>
              {`Taxas registradas ${
                user ? `para ${user.name.split(' ')[0]}` : ''
              }`}
            </h1>

            {!!user?.taxes_group_id && (
              <Button
                type="button"
                onClick={handleRemoveCurrentTaxesGroup}
                styleType="danger"
              >
                Remover tabela de taxas atual
              </Button>
            )}
          </CardHeader>

          <CardContent>
            <DataTable data={tableData} columns={tableColumns} />
          </CardContent>
        </Card>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Adicionar nova taxa</h1>

            <div>
              <div>
                <label htmlFor="taxGroup">Tabela de taxas</label>
                <input
                  id="taxGroup"
                  type="radio"
                  name="mode"
                  value="table"
                  onChange={e => setFormMode(e.target.value as 'table')}
                />
              </div>

              <div>
                <label htmlFor="isolatedTax">Taxa isolada</label>
                <input
                  id="isolatedTax"
                  type="radio"
                  name="mode"
                  value="isolated"
                  onChange={e => setFormMode(e.target.value as 'isolated')}
                />
              </div>
            </div>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              {formMode === 'isolated' ? (
                <>
                  <FormRow>
                    <InputGroup>
                      <label>Produto</label>
                      <Select
                        name="product"
                        options={[
                          { label: 'PIX Out', value: 'pix' },
                          { label: 'Boletos', value: 'billet' },
                          {
                            label: 'PIX QR CODE (Dinâmico)',
                            value: 'dynamic-invoice',
                          },
                          {
                            label: 'PIX QR CODE (Estático)',
                            value: 'static-invoice',
                          },
                          { label: 'TED', value: 'ted' },
                          { label: 'Mensalidade', value: 'monthly-payment' },
                        ]}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo da taxa</label>
                      <Select
                        name="type"
                        options={[
                          { label: 'Porcentagem', value: 'percentage' },
                          { label: 'Preço', value: 'price' },
                        ]}
                        onChange={handleTaxTypeChange}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor da taxa</label>
                      <InputCurrency name="value" prefix={prefix} />
                    </InputGroup>
                  </FormRow>
                </>
              ) : (
                <>
                  <FormRow>
                    <InputGroup>
                      <label>Tabela de taxas</label>
                      <Select
                        name="taxesGroupId"
                        options={taxesGroups.map(group => ({
                          label: group.name,
                          value: group.id,
                        }))}
                        placeholder="Selecione uma tabela de taxas"
                      />
                    </InputGroup>
                  </FormRow>
                </>
              )}

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success">
                  Salvar taxa
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
