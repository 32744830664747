import { decode } from 'jsonwebtoken';
import { FC, useEffect, useState } from 'react';
import {
  FiBook,
  FiBriefcase,
  FiDollarSign,
  FiDownload,
  FiFile,
  FiFileText,
  FiHeadphones,
  // FiGlobe,
  FiHelpCircle,
  FiInfo,
  FiLink,
  FiShare,
  FiShoppingBag,
  FiTag,
  FiUsers,
} from 'react-icons/fi';
import { MdOutlinePix } from 'react-icons/md';

import { useAuth } from '@hooks/auth';

import { CardMenu } from './CardMenu';
import { Container, Row } from './styles';

interface IPayloadToken {
  role: string;
}

const CardMenuContainer: FC = () => {
  const [role, setRole] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const { role: roleFromToken } = decode(user.token) as IPayloadToken;

      setRole(roleFromToken);
    }
  }, [user.token, user]);

  return (
    <Row id="mainContent">
      <Container>
        {role === 'credcash' && (
          <>
            <CardMenu
              menuId="adminCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            <CardMenu
              menuId="credCashDownloads"
              icon={FiDownload}
              title="Downloads"
            />

            <CardMenu menuId="clientFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="sellerClients" icon={FiUsers} title="Clientes" />
          </>
        )}

        {role === 'admin' && (
          <>
            <CardMenu
              menuId="financeiro"
              icon={FiDollarSign}
              title="Financeiro"
            />

            <CardMenu
              menuId="relatorios"
              icon={FiFileText}
              title="Relatórios"
            />

            <CardMenu menuId="faq" icon={FiHelpCircle} title="FAQ" />

            <CardMenu menuId="users" icon={FiUsers} title="Usuários" />

            <CardMenu
              menuId="adminPagamentoDeContas"
              icon={FiShoppingBag}
              title="Pagamento de Contas"
            />

            <CardMenu menuId="adminBoleto" icon={FiFile} title="Boleto" />

            <CardMenu menuId="adminPix" icon={MdOutlinePix} title="PIX" />

            <CardMenu menuId="adminClientTaxes" icon={FiShare} title="Taxas" />

            <CardMenu
              menuId="departments"
              icon={FiBriefcase}
              title="Departamentos"
            />

            <CardMenu menuId="adminTickets" icon={FiTag} title="Tickets" />

            <CardMenu
              menuId="downloads"
              icon={FiDownload}
              title="Arquivos enviados"
            />

            <CardMenu
              menuId="sharedTransaction"
              icon={FiDollarSign}
              title="Comprovantes"
            />
          </>
        )}

        {role === 'client' && (
          <>
            {/* <CardMenu menuId="franquia" icon={FiUsers} title="Franquias" /> */}

            <CardMenu menuId="adminPix" icon={MdOutlinePix} title="PIX" />

            <CardMenu menuId="adminBoleto" icon={FiFile} title="Boleto" />

            <CardMenu
              menuId="adminPagamentoDeContas"
              icon={FiShoppingBag}
              title="Pagamento de Contas"
            />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu
              menuId="sharedFinancial"
              icon={FiDollarSign}
              title="Financeiro"
            />

            <CardMenu
              menuId="sharedTransaction"
              icon={FiDollarSign}
              title="Comprovantes"
            />

            {/* <CardMenu menuId="clientBbankPay" icon={FiBook} title="Bbankpay" />

            <CardMenu menuId="clientFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu
              menuId="sharedInvest"
              icon={FiDollarSign}
              title="Bbank Invest"
            /> */}
          </>
        )}

        {role === 'business' && (
          <>
            <CardMenu menuId="businessClients" icon={FiUsers} title="Gestor" />

            {/* <CardMenu
              menuId="franchiseesSellers"
              icon={FiUsers}
              title="Promotor"
            />

            <CardMenu
              menuId="businessCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            <CardMenu
              menuId="businessBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            />

            <CardMenu
              menuId="businessUploads"
              icon={FiUpload}
              title="Uploads"
            />

            <CardMenu
              menuId="businessBbankPay"
              icon={FiBook}
              title="BbankPay"
            />

            <CardMenu menuId="businessFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="franchiseesLinks" icon={FiLink} title="Links" />

            <CardMenu
              menuId="sharedInvest"
              icon={FiDollarSign}
              title="Bbank Invest"
            />

            <CardMenu
              menuId="franchiseesMarketing"
              icon={FiHeadphones}
              title="Marketing"
            />

            <CardMenu
              menuId="sharedDeposit"
              icon={BiWallet}
              title="Depositar"
            />

            <CardMenu
              menuId="sharedTransfer"
              icon={BiTransfer}
              title="Transferência"
            />

            <CardMenu
              menuId="sharedWithdrawalAndChange"
              icon={BiMoney}
              title="Saque/Troco"
            /> */}
          </>
        )}

        {role === 'master' && (
          <>
            <CardMenu menuId="masterClients" icon={FiUsers} title="Gestor" />

            {/* <CardMenu
              menuId="franchiseesSellers"
              icon={FiUsers}
              title="Promotores"
            />

            <CardMenu
              menuId="masterCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            <CardMenu
              menuId="masterBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            />

            <CardMenu menuId="masterUploads" icon={FiUpload} title="Uploads" />

            <CardMenu menuId="masterBbankPay" icon={FiBook} title="BbankPay" />

            <CardMenu menuId="masterFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="franchiseesLinks" icon={FiLink} title="Links" /> */}
          </>
        )}

        {role === 'seller' && (
          <>
            <CardMenu menuId="adminPix" icon={MdOutlinePix} title="PIX" />

            <CardMenu menuId="adminBoleto" icon={FiFile} title="Boleto" />

            <CardMenu
              menuId="adminPagamentoDeContas"
              icon={FiShoppingBag}
              title="Pagamento de Contas"
            />

            <CardMenu
              menuId="sharedFinancial"
              icon={FiDollarSign}
              title="Financeiro"
            />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />
          </>
        )}

        {role === 'business' || role === 'master' ? (
          <>
            {/* <CardMenu menuId="franquia" icon={FiUsers} title="Franquias" /> */}

            <CardMenu menuId="adminPix" icon={MdOutlinePix} title="PIX" />

            <CardMenu menuId="adminBoleto" icon={FiFile} title="Boleto" />

            <CardMenu
              menuId="adminPagamentoDeContas"
              icon={FiShoppingBag}
              title="Pagamento de Contas"
            />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu
              menuId="franchiseesProspects"
              icon={FiUsers}
              title="Prospectos"
            />

            {/* <CardMenu
              menuId="financeiro"
              icon={FiDollarSign}
              title="Financeiro"
            /> */}

            <CardMenu menuId="businessFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="franchiseesLinks" icon={FiLink} title="Links" />

            <CardMenu
              menuId="franchiseesMarketing"
              icon={FiHeadphones}
              title="Marketing"
            />

            <CardMenu
              menuId="sharedFinancial"
              icon={FiDollarSign}
              title="Financeiro"
            />

            {/*
              <CardMenu
                menuId="franchiseeECommerce"
                icon={FiShoppingBag}
                title="ECommmerce"
              /> */}

            {/* <CardMenu
                menuId="sharedInvest"
                icon={FiDollarSign}
                title="Bbank Invest"
              />

              <CardMenu
                menuId="sharedDeposit"
                icon={BiWallet}
                title="Depositar"
              />

              <CardMenu
                menuId="sharedTransfer"
                icon={BiTransfer}
                title="Transferência"
              />

              <CardMenu
                menuId="sharedWithdrawalAndChange"
                icon={BiMoney}
                title="Saque/Troco"
              /> */}
          </>
        ) : null}

        {/* <CardMenu
          menuId="sharedFinancial"
          icon={FiDollarSign}
          title="Financeiro"
        /> */}

        {/* <CardMenu menuId="sharedCharges" icon={FiDollarSign} title="Cobrança" /> */}
      </Container>
    </Row>
  );
};

export { CardMenuContainer };
