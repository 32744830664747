import {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { FiCheckCircle, FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputFile } from '@components/elements/Form/InputFile';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Table } from '@components/elements/Table';
import { TableRow } from '@components/elements/Table/TableRow';
import { TableColumn } from '@components/elements/Table/TableColumn';
import { Modal } from '@components/elements/Modal';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';
import { IZipcodeResponse } from '@services/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  ChooseAFile,
  InputFees,
} from './styles';
import {
  statusOpions,
  getFranchiseesOptions,
  getFranchiseeClientsOptions,
  statesOptions,
  TypeOfCompany,
  PaymentOnAccount,
  ResponsibleRole,
  banksOptions,
} from './selectOptions';
import {
  IFormData,
  IClient,
  IFile,
  IFranchisee,
  IParsedFormData,
  IMessage,
} from './interfaces';
import { formValidation } from './validations';

const TopBankMerchantForm: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const rgInputFileFormRow = useRef<HTMLDivElement>(null);
  const cnhInputGroupRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [franchisees, setFranchisees] = useState<IFranchisee[]>();
  const [franchiseeClients, setFranchiseeClients] = useState<IClient[]>();
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    async function loadFranchisees() {
      const { data } = await api.get('/franchisees');

      setFranchisees(data);
    }

    const timer = setTimeout(async () => {
      loadFranchisees();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handleAddMessages = useCallback(() => {
    setMessages(oldState => {
      if (oldState.length === 7) {
        return oldState;
      }

      const message = {
        id: v4(),
      };

      return [...oldState, message];
    });
  }, []);

  const handleRemoveMessages = useCallback((messageId: string) => {
    setMessages(oldState => {
      const updatedState = oldState.filter(state => state.id !== messageId);

      return updatedState;
    });
  }, []);

  const handlInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: string, title: string) => {
      const inputFileRef = formRef.current?.getFieldRef(e.target.name);

      if (!e.target.files || e.target.files.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      const file = e.target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];
      const fileSize = file.size * 0.000001;

      if (fileExtension !== 'pdf' && fileExtension !== 'png') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos PDF ou PNG',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      if (fileSize > 2.1) {
        addToast({
          title: 'Arquivo grande demais!',
          message: 'São aceitos apenas arquivos com 2MB ou menos',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      setFiles(oldState => {
        const findDuplicated = oldState.findIndex(item => item.key === key);

        if (findDuplicated >= 0) {
          oldState.splice(findDuplicated, 1);
        }

        return [...oldState, { file, key, title }];
      });
    },
    [addToast],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const fieldsToDelete = [
        'fileOne',
        'fileTwo',
        'fileThree',
        'fileFour',
        'fileFive',
      ];

      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        if (
          rgInputFileFormRow.current?.style.display !== 'none' &&
          files.length !== 4
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (4). Insira todos para poder continuar!',
          });
          return;
        }

        if (
          rgInputFileFormRow.current?.style.display === 'none' &&
          files.length !== 3
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (3). Insira todos para poder continuar!',
          });
          return;
        }

        const parsedMaskedData = removeInputMask();

        const formData: IParsedFormData = {
          ...data,
        };

        delete formData.files;

        parsedMaskedData.forEach(item => {
          formData[item.name] = item.value;
        });

        fieldsToDelete.forEach(fieldToDelete => {
          delete formData[fieldToDelete];
        });

        const { data: merchant } = await api.post(
          '/bbank-pay/merchants',
          formData,
        );

        const formFiles = new FormData();

        files.forEach(({ file, title }) => {
          formFiles.append('files', file);
          formFiles.append('titles', title);
        });

        formFiles.append('merchantId', merchant.id);

        await api.post('/bbank-pay/merchant-files', formFiles);

        addToast({
          title: 'Estabelecimento cadastrado!',
          type: 'success',
        });

        reset();
        setFiles([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, files],
  );

  const handleFranchiseesSelectChange = useCallback(
    async (value: OptionTypeBase | null) => {
      if (!value) {
        return;
      }

      api.get(`/users-indicated/${value.value}`).then(response => {
        const { data } = response;

        setFranchiseeClients(data);
      });
    },
    [],
  );

  const franchiseesOptions = useMemo(() => {
    return franchisees ? getFranchiseesOptions(franchisees) : [];
  }, [franchisees]);

  const franchiseeClientsOptions = useMemo(() => {
    return franchiseeClients
      ? getFranchiseeClientsOptions(franchiseeClients)
      : [];
  }, [franchiseeClients]);

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('postalCode');
        const cityInputRef = formRef.current?.getFieldRef('addressCity');
        const addressInputRef = formRef.current?.getFieldRef('address');
        const neighborhoodInputRef = formRef.current?.getFieldRef(
          'addressNeighborhood',
        );

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          neighborhoodInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro, bairro } = data;

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;
        neighborhoodInputRef.value = bairro;

        const stateInputRef = formRef.current?.getFieldRef('state');

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  const handleChooseAFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!rgInputFileFormRow.current || !cnhInputGroupRef.current) {
      return;
    }

    const { value } = e.target;

    if (value === 'rgCpfFile') {
      rgInputFileFormRow.current.style.display = 'flex';
      cnhInputGroupRef.current.style.display = 'none';
    } else {
      rgInputFileFormRow.current.style.display = 'none';
      cnhInputGroupRef.current.style.display = 'flex';
    }
  }, []);

  return (
    <>
      {!franchisees ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>BbankPay</li>
              <li>{'>'}</li>
              <li>Estabelecimento</li>
              <li>{'>'}</li>
              <li>Novo</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo estabelecimento</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOpions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Franqueado responsável</label>
                      <Select
                        name="franchiseeId"
                        options={franchiseesOptions}
                        onChange={handleFranchiseesSelectChange}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cliente do fraqueado responsável</label>
                      <Select
                        name="clientId"
                        options={franchiseeClientsOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Razão social</label>
                      <Input name="socialReason" />
                    </InputGroup>

                    <InputGroup>
                      <label>CNPJ</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        placeholder="00.000.000/0000-00"
                        name="cnpj"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de abertura</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        noUnmask
                        name="openingDate"
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Tipo de empresa</label>
                      <Select name="companyType" options={TypeOfCompany} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome Fantasia</label>
                      <Input name="nameFantasy" />
                    </InputGroup>
                    <InputGroup>
                      <label>Ramo de atividade</label>
                      <Input name="companyActivity" />
                    </InputGroup>
                    <InputGroup>
                      <label>CNAE</label>
                      <Input name="cnae" />
                    </InputGroup>
                    <InputGroup>
                      <label>Inscrição Estadual</label>
                      <Input name="stateRegistry" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Faturamento Mensal Cartões</label>
                      <Input name="cardMonthlyInvoicing" />
                    </InputGroup>

                    <InputGroup>
                      <label>E-mail</label>
                      <Input name="companyEmail" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Funcionamento do estabelecimento </h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Segunda a sexta</label>
                      <Input
                        name="openingHoursMondayUntilFriday"
                        placeholder="Exemplo: 08h ás 18:00"
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Sábado (deixe vazio caso não exista)</label>
                      <Input
                        name="openingHoursSaturday"
                        placeholder="Exemplo: 08h ás 18:00"
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Domingo (deixe vazio caso não exista)</label>
                      <Input
                        name="openingHoursSunday"
                        placeholder="Exemplo: 08h ás 18:00"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <InputMask
                        mask="99999-999"
                        placeholder="00000-000"
                        name="postalCode"
                        onBlur={e => handleFindZipcode(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="address" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Bairro</label>
                      <Input name="addressNeighborhood" />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="addressCity" />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Select name="addressState" options={statesOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento (deixe vazio caso não exista)</label>
                      <Input name="addressComplement" />
                    </InputGroup>

                    <InputGroup>
                      <label>Número (deixe vazio caso não exista)</label>
                      <InputMask
                        mask="9999999999"
                        noUnmask
                        name="addressNumber"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone da empresa</label>
                      <InputMask mask="(99) 9999-9999" name="companyPhone" />
                    </InputGroup>
                    <InputGroup>
                      <label>Ramal</label>
                      <Input name="ramal" />
                    </InputGroup>
                    <InputGroup>
                      <label>Celular</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        name="companyCellphone"
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Outros Telefones</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        name="alternativePhone"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Responsável pela assinatura</label>
                      <Input name="membershipResponsible" />
                    </InputGroup>
                    <InputGroup>
                      <label>Fone contato</label>
                      <Input name="membershipResponsibleCellphone" />
                    </InputGroup>
                    <InputGroup>
                      <label>CPF</label>
                      <Input name="membershipResponsibleDocument" />
                    </InputGroup>
                    <InputGroup>
                      <label>Função</label>
                      <Select
                        name="membershipResponsibleRole"
                        options={ResponsibleRole}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato com Departamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome</label>
                      <Input name="departmentContactName" />
                    </InputGroup>
                    <InputGroup>
                      <label>E-mail</label>
                      <Input name="departmentContactEmail" />
                    </InputGroup>
                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask
                        mask="(99) 9999-9999"
                        name="departmentContactPhone"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <h1>Domicilio Bancario</h1>

                    <Button
                      styleType="info"
                      icon={FiPlusCircle}
                      onClick={handleAddMessages}
                      disabled={messages.length === 3}
                    >
                      Adicionar mensagem
                    </Button>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Select name="bankInfo[0].bank" options={banksOptions} />
                    </InputGroup>
                    <InputGroup>
                      <label>Número banco</label>
                      <Input name="bankInfo[0].bankCode" />
                    </InputGroup>
                    <InputGroup>
                      <label>Agência</label>
                      <Input name="bankInfo[0].agency" />
                    </InputGroup>
                    <InputGroup>
                      <label>Conta Corrente</label>
                      <Input name="bankInfo[0].account" />
                    </InputGroup>
                    <InputGroup>
                      <label>Pagamento na conta TopBank</label>
                      <Select
                        name="bankInfo[0].paymentInTopBankAccount"
                        options={PaymentOnAccount}
                      />
                    </InputGroup>
                  </FormRow>

                  {messages.length > 0 &&
                    messages.map((message, index) => (
                      <div
                        key={message.id}
                        style={{
                          display: 'flex',
                          flex: 1,
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <FormRow>
                          <InputGroup>
                            <label>Banco</label>
                            <Select
                              name={`bankInfo[${index + 1}].bank`}
                              options={banksOptions}
                            />
                          </InputGroup>
                          <InputGroup>
                            <label>Número banco</label>

                            <Input name={`bankInfo[${index + 1}].bankCode`} />
                          </InputGroup>
                          <InputGroup>
                            <label>Agência</label>
                            <Input name={`bankInfo[${index + 1}].agency`} />
                          </InputGroup>
                          <InputGroup>
                            <label>Conta Corrente</label>
                            <Input name={`bankInfo[${index + 1}].account`} />
                          </InputGroup>

                          <InputGroup>
                            <label>Pagamento na conta TopBank</label>
                            <Select
                              name={`bankInfo[${
                                index + 1
                              }].paymentInTopBankAccount`}
                              options={PaymentOnAccount}
                            />
                          </InputGroup>
                        </FormRow>

                        <Button
                          styleType="danger"
                          icon={FiMinusCircle}
                          onClick={() => handleRemoveMessages(message.id)}
                          style={{
                            padding: 11,
                            marginLeft: 6,
                            alignSelf: 'flex-end',
                          }}
                        />
                      </div>
                    ))}

                  <FormRow separator>
                    <h1>Taxas</h1>
                  </FormRow>

                  {/* Taxas - Bandeiras */}
                  <InputFees>
                    <div>
                      <Modal
                        buttonText="Taxas - Bandeiras"
                        headerText="Taxas bandeiras"
                      >
                        <Table width="auto" height="auto">
                          <TableRow>
                            <TableColumn>
                              <span>Tipo</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Faturamento</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Função</span>
                            </TableColumn>
                          </TableRow>

                          <TableRow>
                            <TableColumn>
                              <span>Azul</span>
                            </TableColumn>
                            <TableColumn>
                              <span>5 Mil</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Debito/Credito</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Vermelha</span>
                            </TableColumn>
                            <TableColumn>
                              <span>10 Mil</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Debito/Credito</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Amarela</span>
                            </TableColumn>
                            <TableColumn>
                              <span>20 Mil</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Debito/Credito</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Roxa</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Acima de 30 mil</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Debito/Credito</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Pagamento de contas</span>
                            </TableColumn>
                            <TableColumn>
                              <span />
                            </TableColumn>
                            <TableColumn>
                              <span>Conveniência</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Credpay</span>
                            </TableColumn>
                            <TableColumn>
                              <span />
                            </TableColumn>
                            <TableColumn>
                              <span>Conveniência</span>
                            </TableColumn>
                          </TableRow>
                        </Table>
                      </Modal>
                    </div>

                    {/* Tarifas de Serviços */}
                    <div>
                      <Modal
                        buttonText="Tarifas de serviços"
                        headerText="Tarifas de serviços"
                      >
                        <Table width="auto" height="auto">
                          <TableRow>
                            <TableColumn>
                              <span>Tarifa</span>
                            </TableColumn>
                            <TableColumn>
                              <span>Valor</span>
                            </TableColumn>
                          </TableRow>

                          <TableRow>
                            <TableColumn>
                              <span>Emissão de cartão TopBank</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 18,90</span>
                            </TableColumn>
                          </TableRow>

                          <TableRow>
                            <TableColumn>
                              <span>Reemissão cartão TopBank</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 24,90</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Manutenção conta digital</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 9,90 </span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Emissão boleto</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 3,49</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Recarga Celular</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 0,40</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Transfêrencia peer to peer</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 0,40</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>Saque</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 6,50</span>
                            </TableColumn>
                          </TableRow>
                          <TableRow>
                            <TableColumn>
                              <span>TED/DOC</span>
                            </TableColumn>
                            <TableColumn>
                              <span>R$ 5,90</span>
                            </TableColumn>
                          </TableRow>
                        </Table>
                      </Modal>
                    </div>
                  </InputFees>

                  <FormRow separator>
                    <h1>Arquivos</h1>

                    <ChooseAFile>
                      <div>
                        <label htmlFor="cnhFile">CNH</label>
                        <input
                          name="chooseAFile"
                          type="radio"
                          id="cnhFile"
                          defaultValue="cnhFile"
                          onChange={handleChooseAFile}
                          defaultChecked
                        />
                      </div>
                      <div>
                        <label htmlFor="rgCpfFile">RG/CPF</label>
                        <input
                          name="chooseAFile"
                          type="radio"
                          id="rgCpfFile"
                          defaultValue="rgCpfFile"
                          onChange={handleChooseAFile}
                        />
                      </div>
                    </ChooseAFile>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Comprovante de residência</label>
                      <InputFile
                        name="fileOne"
                        onChange={e =>
                          handlInputFileChange(
                            e,
                            'aWqt',
                            'Comprovante de residência',
                          )
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Contrato social</label>
                      <InputFile
                        name="fileTwo"
                        onChange={e =>
                          handlInputFileChange(e, 'AOlv', 'Contrato')
                        }
                      />
                    </InputGroup>

                    <InputGroup ref={cnhInputGroupRef}>
                      <label>CNH</label>
                      <InputFile
                        name="fileThree"
                        onChange={e => handlInputFileChange(e, 'nyhZ', 'CNH')}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow ref={rgInputFileFormRow} style={{ display: 'none' }}>
                    <InputGroup>
                      <label>RG (frente)</label>
                      <InputFile
                        name="fileFour"
                        onChange={e =>
                          handlInputFileChange(e, 'OPiuI', 'RG (frente)')
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>RG (verso)</label>
                      <InputFile
                        name="fileFive"
                        onChange={e =>
                          handlInputFileChange(e, 'PnuT', 'RG (verso)')
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      styleType="info"
                      icon={FiCheckCircle}
                      loading={loadingRequest}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { TopBankMerchantForm };
