import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form } from '@unform/web';
import Loader from 'react-loader-spinner';
import { FiDownload, FiEye } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { Card, CardHeader, CardContent, LoaderContainer } from './styles';
import {
  IConsignedCompany,
  IParams,
  IConsigned,
  IFormData,
  IStatusParsedObject,
} from './interfaces';
import { formValidation } from './validations';

export const ConsignedCompanyDetails: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { push } = useHistory();
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();

  const [company, setCompany] = useState<IConsignedCompany>();
  const [consigned, setConsigned] = useState<IConsigned[]>();

  useEffect(() => {
    let consignedCompanyId = '';

    async function loadCompany() {
      const { data } = await api.get(
        `/consigned/companies/${params.consignedCompanyDocument}`,
        {
          params: {
            relations: ['indication'],
          },
        },
      );

      const parsedData = parseObjectPropertiesToCamelCase<IConsignedCompany>(
        data,
      );

      consignedCompanyId = parsedData.id;

      setCompany(parsedData);
    }

    async function loadConsignedCredits() {
      const { data } = await api.get<any[]>(
        `/consigned/companies/${consignedCompanyId}/credits`,
        {
          params: {
            relations: ['client'],
          },
        },
      );

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IConsigned>(item);
      });

      setConsigned(parsedData);
    }

    const timerOne = setTimeout(() => loadCompany(), 1500);
    const timerTwo = setTimeout(() => loadConsignedCredits(), 3000);

    return () => {
      clearInterval(timerOne);
      clearInterval(timerTwo);
    };
  }, [params.consignedCompanyDocument]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        const parsedData = data;

        await formValidation(parsedData);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const validationErrors = getValidationErrors(err);

          formRef.current?.setErrors(validationErrors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'info',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast],
  );

  const handleGoToDetailsPage = useCallback(
    (consignedId: string) => {
      push(`/cred-cash/consigned/${consignedId}/details`);
    },
    [push],
  );

  const handleGoToFilesPage = useCallback(
    (consignedId: string) => {
      push(`/cred-cash/consigned/${consignedId}/files`);
    },
    [push],
  );

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pending: {
        badgeType: 'warning',
        text: 'Pendente',
      },
      approved: {
        badgeType: 'success',
        text: 'Aprovado',
      },
      pre_approved: {
        badgeType: 'info',
        text: 'Pré aprovado',
      },
      in_analysis: {
        badgeType: 'warning',
        text: 'Em análise',
      },
      denied: {
        badgeType: 'danger',
        text: 'Negado',
      },
      contract_pending: {
        badgeType: 'info',
        text: 'Contrato pendente',
      },
    };
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do funcionário',
        selector: 'search',
      },
      {
        name: 'Valor do crédito',
        selector: 'creditValue',
      },
      {
        name: 'status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!consigned) {
      return [];
    }

    const data = consigned.map(item => ({
      id: item.id,
      search: item.client.name,
      creditValue: item.creditValue,
      status: (
        <Badge type={statusParsedObject[item.status].badgeType}>
          {statusParsedObject[item.status].text}
        </Badge>
      ),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="info"
            icon={FiEye}
            onClick={() => handleGoToDetailsPage(item.id)}
          />

          <TableButton
            styleType="info"
            icon={FiDownload}
            onClick={() => handleGoToFilesPage(item.id)}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [
    handleGoToDetailsPage,
    handleGoToFilesPage,
    consigned,
    statusParsedObject,
  ]);

  return (
    <>
      {!company ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Cred Cash', 'Consignado', 'Empresas', 'Detalhes']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Créditos solicitados por seus funcionários</h1>
              </CardHeader>

              <CardContent>
                {!consigned ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      width={50}
                      height={50}
                      color="${({ theme }) => theme.colors.secondary}"
                    />
                  </LoaderContainer>
                ) : (
                  <DataTable data={tableData} columns={tableColumns} />
                )}
              </CardContent>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes da empresa selecionada</h1>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  initialData={company}
                  ref={formRef}
                >
                  <FormRow separator>
                    <h1>Indicação</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome da indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Detalhes da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome da empresa</label>
                      <Input name="companyName" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Documento da empresa</label>
                      <Input name="companyDocument" readOnly />
                    </InputGroup>
                  </FormRow>

                  {/* <FormRow buttonWrapper>
                      <Button styleType="success" icon={FiSave}>
                        Salvar alterações
                      </Button>
                    </FormRow> */}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
