import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Form } from '@unform/web';
import { FiEye, FiSave, FiTrash2 } from 'react-icons/fi';
import Loader from 'react-loader-spinner';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Input } from '@components/elements/Form/Input';
import { Textarea } from '@components/elements/Form/Textarea';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Button } from '@components/elements/Button';
import { DataTable } from '@components/elements/Datatable';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { Select } from '@components/elements/Form/Select';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  IPlatform,
  IParams,
  IDepartment,
  IFormData,
  IPlatformDepartment,
} from './interfaces';
import { Card, CardHeader, CardContent, LoaderContainer } from './styles';
import { formValidation } from './validations';

export const EditPlatform: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { push } = useHistory();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [platform, setPlatform] = useState<IPlatform>();
  const [departments, setDepartments] = useState<IDepartment[]>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [platformDepartments, setPlatformDepartments] = useState<
    IPlatformDepartment[]
  >();

  useEffect(() => {
    async function loadPlatform() {
      const { data } = await api.get(`/platforms/${params.platformId}`);
      const { data: departmentsResponse } = await api.get('/departments');

      setPlatform(data);
      setDepartments(departmentsResponse);
    }

    async function loadPlatformDepartments() {
      const { data } = await api.get<any[]>(
        `/platform-departments/platforms/${params.platformId}`,
        {
          params: {
            relations: ['department'],
          },
        },
      );

      if (data.length === 0) {
        setPlatformDepartments([]);
        return;
      }

      setPlatformDepartments(data);
    }

    const timer = setTimeout(() => {
      loadPlatform();
    }, 1500);

    const timer2 = setTimeout(() => {
      loadPlatformDepartments();
    }, 2500);

    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };
  }, [params.platformId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        await formValidation(data);

        const parsedData = data;

        if (parsedData.department) {
          const { data: response } = await api.post('/platform-departments', {
            platformId: params.platformId,
            departmentId: parsedData.department,
          });

          formRef.current?.clearField('department');

          setPlatformDepartments(oldState => {
            if (!oldState) {
              return [response];
            }

            return [...oldState, response];
          });
        }

        delete parsedData.department;

        const { data: updatedPlatform } = await api.put(
          `/platforms/${params.platformId}`,
          parsedData,
        );

        setPlatform(updatedPlatform);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, params.platformId],
  );

  const handleDeletePlatformDeparments = useCallback(
    async (departmentId: string) => {
      setLoadingDeleteState(true);

      await api.delete(`/platform-departments/${departmentId}`);

      setPlatformDepartments(oldState => {
        if (!oldState) {
          return undefined;
        }

        const updatedState = oldState.filter(item => item.id !== departmentId);

        return updatedState;
      });
      setLoadingDeleteState(false);
    },
    [],
  );

  const handleGoToDepartmentsDetailsPage = useCallback(
    (departmentId: string) => {
      push(`/departments/${departmentId}/view`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do departamento',
        selector: 'search',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!platformDepartments) {
      return [];
    }

    const data = platformDepartments.map(item => {
      const { department } = item;

      return {
        id: item.id,
        search: department.name,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              icon={FiEye}
              onClick={() => handleGoToDepartmentsDetailsPage(department.id)}
            />
            <TableButton
              styleType="danger"
              icon={FiTrash2}
              onDoubleClick={() => handleDeletePlatformDeparments(item.id)}
              loading={loadingDeleteState}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [
    platformDepartments,
    handleDeletePlatformDeparments,
    handleGoToDepartmentsDetailsPage,
    loadingDeleteState,
  ]);

  const departmentsOptions = useMemo(() => {
    if (!departments) {
      return undefined;
    }

    let filteredDepartments = departments;

    if (platformDepartments && platformDepartments.length !== 0) {
      filteredDepartments = departments.filter(item => {
        const duplicated = platformDepartments.some(
          i => i.department.id !== item.id,
        );

        return duplicated;
      });
    }

    const options = filteredDepartments.map(item => ({
      label: item.name,
      value: item.id,
    }));

    return options;
  }, [departments, platformDepartments]);

  return (
    <>
      {!platform ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Card>
              <CardHeader>
                <h1>Departamentos cadastrados</h1>
              </CardHeader>

              <CardContent>
                {!platformDepartments ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      color="${({ theme }) => theme.colors.secondary}"
                      width={50}
                      height={50}
                    />
                  </LoaderContainer>
                ) : (
                  <DataTable data={tableData} columns={tableColumns} />
                )}
              </CardContent>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Editar plataforma:&nbsp;
                  {platform.name}
                </h1>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  initialData={platform}
                  ref={formRef}
                >
                  <FormRow separator>
                    <h1>Dados da plataforma</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome da plataforma</label>
                      <Input name="name" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Descrição da plataforma</label>
                      <Textarea name="description" rows={6} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Atribua departamentos</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Departamentos</label>
                      <Select name="department" options={departmentsOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiSave}
                      loading={loadingRequest}
                    >
                      Salvar alterações
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
