import { FC, useState, useEffect, useMemo } from 'react';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import { Row } from '@components/layouts/CardMenuContainer/styles';

import api from '@services/bbankApi';

import { IBalance, IMoney } from './interfaces';
import { Money as MoneyComponent } from './styles';

export const Money: FC<IMoney> = ({ initVisible = false, ...rest }) => {
  const [moneyVisible, setMoneyVisible] = useState(initVisible);
  const [balance, setBalance] = useState<IBalance>();
  const [userRole, setUserRole] = useState<any>();

  useEffect(() => {
    async function loadBalance() {
      const { data } = await api.get('/users/balance');

      setBalance(data);
    }

    loadBalance();

    const storedSession = localStorage.getItem('@Bbanking:Auth');
    if (storedSession) {
      const sessionObject = JSON.parse(storedSession);
      setUserRole(sessionObject);
    } else {
      console.log(
        "Nenhum dado encontrado no localStorage para a chave '@Bbanking:Auth'",
      );
    }
  }, []);

  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format;
  }, []);

  return (
    <Row>
      <MoneyComponent moneyVisible={moneyVisible} {...rest}>
        <div>
          <strong>Saldo da sua conta</strong>

          {/* <span>
            {moneyVisible &&
              (balance?.balance ? numberFormat(balance.balance) : 'R$ 0,00')}
          </span> */}

          <span>
            {userRole?.user.role === 'admin'
              ? moneyVisible && balance?.balance
                ? numberFormat(userRole.user.system_balance / 100)
                : ''
              : moneyVisible && balance?.balance
              ? numberFormat(balance.balance)
              : ''}
          </span>
        </div>

        <button type="button">
          {moneyVisible ? (
            <FiEyeOff
              size={24}
              color="#fff"
              onClick={() => setMoneyVisible(false)}
            />
          ) : (
            <FiEye
              size={24}
              color="#fff"
              onClick={() => setMoneyVisible(true)}
            />
          )}
        </button>
      </MoneyComponent>
    </Row>
  );
};
