import * as Yup from 'yup';

import { IFormData } from './interface';

export const formValidation = async (
  data: IFormData,
  selectedOption: string,
): Promise<void> => {
  let shape = Yup.object().shape({
    paymentValue: Yup.number()
      .required('Preencha este campo!')
      .min(2, 'Apenas valores acima de R$2,00!'),
    issueDate: Yup.string()
      .required('Preencha este campo!')
      .length(10, 'Insira uma data válida!'),
    dueDate: Yup.string()
      .required('Preencha este campo!')
      .length(10, 'Insira uma data válida!'),
    payerId: Yup.string().when([], {
      is: () => data.payerId !== undefined,
      then: Yup.string().uuid('Inválido!'),
      otherwise: Yup.string().notRequired(),
    }),
  });

  shape = shape.shape({
    descriptions: Yup.array()
      .of(
        Yup.object({
          text: Yup.string().required('Preencha este campo!'),
          amount: Yup.number()
            .transform((va, ov) => {
              if (ov === '') {
                return undefined;
              }
              return va;
            })
            .required('Preencha este campo!'),
        }),
      )
      .min(1, 'Insira ao menos 1 descrição!'),
    discounts: Yup.array()
      .of(
        Yup.object({
          date: Yup.string().required('Preencha este campo!'),
          percentage: Yup.number()
            .transform((va, ov) => {
              if (ov === '') {
                return undefined;
              }
              return va;
            })
            .required('Preencha este campo!')
            .typeError('Insira um número válido!'),
        }),
      )
      .min(1, 'Insira ao menos 1 desconto!'),
  });

  if (selectedOption === 'new') {
    shape = shape.shape({
      zipcode: Yup.string().required('Preencha este campo!'),
      name: Yup.string().required('Preencha este campo!'),
      document: Yup.string().required('Preencha este campo!'),
      address: Yup.string().required('Preencha este campo!'),
      city: Yup.string().required('Preencha este campo!'),
      state: Yup.string().required('Selecione um estado!'),
    });
  }

  await shape.validate(data, {
    abortEarly: false,
  });
};
