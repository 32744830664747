import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns/esm';
import { FiDownload } from 'react-icons/fi';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Money } from '@components/layouts/Money';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';

import useBank from '@hooks/bank';

import { bankApi } from '@services/bankApi';

import { ITransfer } from './interfaces';

export const ListTransfers: FC = () => {
  const { handleVerifyToken } = useBank();

  const [transfers, setTransfers] = useState<ITransfer[]>();

  const getTransfersList = useCallback(async () => {
    const { data } = await bankApi.get('/pix/requests');

    setTransfers(data);
  }, []);

  useEffect(() => {
    handleVerifyToken().then(response => {
      if (response) {
        getTransfersList();
      }
    });
  }, [handleVerifyToken, getTransfersList]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Para',
        selector: 'to',
      },
      {
        name: 'Valor',
        selector: 'amount',
      },
      {
        name: 'Data',
        selector: 'when',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePrintReceipt = useCallback(async (id: string) => {
    const { data } = await bankApi.get(`/pix/pdf/${id}`, {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(data);
    const anchor = document.createElement('a');

    anchor.setAttribute('href', url);

    anchor.click();

    document.removeChild(anchor);
    URL.revokeObjectURL(url);
  }, []);

  const tableData = useMemo(() => {
    if (!transfers) return [];

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const parsed = transfers.map(item => {
      return {
        id: item._id,
        to: item.to,
        amount: numberFormat(item.amount),
        when: (
          <Badge type="success">
            {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiDownload}
              styleType="info"
              onClick={() => handlePrintReceipt(item._id)}
              title="Fazer download do comprovante"
            />
          </TableButtonContainer>
        ),
      };
    });

    return parsed;
  }, [transfers, handlePrintReceipt]);

  return (
    <>
      <AuthModal onAuth={getTransfersList} />

      {!transfers ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Money />
          </Row>

          <Row>
            <URLPath paths={['Transferencia', 'Lista de Transferencias']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de transferências feitas</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
