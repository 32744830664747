import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FiEye, FiMoreHorizontal } from 'react-icons/fi';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Badge } from '@components/elements/Badge';
import { Textarea } from '@components/elements/Form/Textarea';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IBillet, IParams } from './interfaces';

export const BilletDetails: FC = () => {
  const { billetId } = useParams<IParams>();

  const [billet, setBillet] = useState<IBillet>();

  useEffect(() => {
    async function loadBillet() {
      const { data } = await api.get(`/billets/${billetId}`, {
        params: {
          relations: ['assignor', 'payer'],
        },
      });

      const parsedData = parseObjectPropertiesToCamelCase<IBillet>(data);

      setBillet(parsedData);
    }

    const timer = setTimeout(() => loadBillet(), 1500);

    return () => clearInterval(timer);
  }, [billetId]);

  const handleFormSubmit = useCallback(() => {
    window.open(billet?.billetUrl || '');
  }, [billet]);

  return (
    <>
      {!billet ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Boletos', 'Detalhes do boleto']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes de um boleto</h1>

                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <Dropdown
                    styles={{
                      textColor: '#fff',
                      togglerBackground: `${({ theme }: any) =>
                        theme.colors.secondary}`,
                    }}
                    icon={FiMoreHorizontal}
                  >
                    <li>
                      <a
                        href={billet.billetUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FiEye />
                        Visualizar o boleto completo
                      </a>
                    </li>
                  </Dropdown>
                </div>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} initialData={billet}>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 20,
                    }}
                  >
                    <Badge type="info">
                      Emitido em&nbsp;
                      {format(new Date(billet.createdAt), 'dd/MM/yyyy')}
                    </Badge>
                  </div>

                  <FormRow>
                    <InputGroup>
                      <label>Emissor</label>
                      <Input name="assignor.name" disabled />
                    </InputGroup>

                    <InputGroup>
                      <label>Pagador</label>
                      <Input name="payer.name" disabled />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Código de barras</label>
                      <Input name="barCode" disabled />
                    </InputGroup>

                    <InputGroup>
                      <label>Linha digitável</label>
                      <Input name="insertLine" disabled />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Relação</label>
                      <Textarea name="comments" rows={10} readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
