import { FC, useCallback, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FiLock, FiLogIn } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import themes, { ThemeNames } from '../../../../styles/themes';
import { Container, Background, Content, ImageWrapper } from './styles';
import { formValidation } from './validations';

interface IParams {
  token: string;
}

const RecoverPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const currentTheme: ThemeNames = process.env.REACT_APP_THEME as ThemeNames;

  const handleFormSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        await api.put('/user-password/recover', {
          token: params.token,
          ...data,
        });

        addToast({
          type: 'success',
          title: 'Senha resetada!',
          message: `Sua senha foi recuperada com sucesso! Agora você já pode fazer login na aplicação.`,
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 403 || status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Erro interno do servidor!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast, params],
  );

  return (
    <>
      <Container>
        <Background />
        <Content>
          <div>
            <ImageWrapper>
              <img src={themes[currentTheme].images.logo} alt="Logo" />
            </ImageWrapper>

            <Form onSubmit={handleFormSubmit} ref={formRef}>
              {/* <h1>Insira seu email</h1> */}

              <Input
                name="password"
                type="password"
                icon={FiLock}
                placeholder="Nova senha"
                upperCase={false}
              />

              <Input
                name="confirmPassword"
                type="password"
                icon={FiLock}
                placeholder="Confirme a nova senha"
                upperCase={false}
              />

              <button type="submit">Salvar nova senha</button>

              <Link to="/">
                <FiLogIn size={20} />
                Voltar para o login
              </Link>
            </Form>

            <ul>
              <li>
                {new Date().getFullYear()}
                &copy;
              </li>
              <li>Todos os direitos reservados</li>
            </ul>
          </div>
        </Content>
      </Container>
    </>
  );
};

export { RecoverPassword };
