import { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { format } from 'date-fns/esm';

import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { FormRow } from '@components/elements/Form/FormRow';
import { Form } from '@components/elements/Form';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { Input } from '@components/elements/Form/Input';
import { Textarea } from '@components/elements/Form/Textarea';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { accountType, banksOptions } from './selectOptions';
import { IFormData } from './interfaces';
import { formValidation } from './validations';

const TransferToAccount: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        await formValidation(data);

        const { operationValue } = data;

        const formData = data;
        delete formData.operationValue;

        const items = Object.entries(formData).map(([key, value]) => {
          return {
            relation: key,
            value,
          };
        });

        const {
          data: { id: financingReportId },
        } = await api.post('/financing/reports', {
          clientId: user.id,
          relation: 'Nova transferência',
          operationValue,
          operationDate: format(new Date(), 'dd/MM/yyyy'),
        });

        await api.post('/financing/additional-info', {
          financingReportId,
          items,
        });

        reset();
        addToast({
          title: 'Transferência realizada!',
          type: 'success',
          message:
            'Sua transferência foi realizada com sucesso! Aguarde pela confirmação.',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [user, addToast],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Transferencia', 'Nova Transferencia']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova transferencia</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow separator>
                <h1>Preencha os dados para salvar</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Valor da transferência</label>
                  <InputCurrency name="operationValue" />
                </InputGroup>

                <InputGroup>
                  <label>Lista de bancos</label>
                  <Select name="Banco" options={banksOptions} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Tipo de conta</label>
                  <Select name="Tipo de conta" options={accountType} />
                </InputGroup>

                <InputGroup>
                  <label>Nome destino</label>
                  <Input name="Nome destino" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Agência</label>
                  <Input name="Agencia" maxLength={10} />
                </InputGroup>
                <InputGroup>
                  <label>Conta</label>
                  <Input name="Conta" maxLength={20} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup textarea>
                  <label>Descrição</label>
                  <Textarea name="Descrição da transferencia" rows={3} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper style={{ marginTop: 20 }}>
                <Button type="submit" styleType="success">
                  Salvar
                </Button>
                <Button type="submit" styleType="warning">
                  Transferir
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { TransferToAccount };
