import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import {
  FiDownload,
  FiEdit,
  FiMoreHorizontal,
  FiPercent,
  FiPlusCircle,
  FiTrash,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import exportFromJSON from 'export-from-json';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Dropdown } from '@components/elements/Dropdown';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import {
  Card,
  CardHeader,
  CardContent,
  TableButtonContainer,
  TableButton,
} from './styles';
import { IPercentage } from './interfaces';

const ListInvestmentsPercentages: FC = () => {
  const { push } = useHistory();

  const [percentages, setPercentages] = useState<IPercentage[]>();

  useEffect(() => {
    async function loadPercentages() {
      const { data } = await api.get('/investments/percentages');

      setPercentages(data);
    }

    const timer = setTimeout(() => {
      loadPercentages();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Período',
        selector: 'search',
      },
      {
        name: 'Valor',
        selector: 'value',
      },
      {
        name: 'Percentual',
        selector: 'percentage',
      },
      {
        name: 'Rendimento',
        selector: 'income',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleNavigateToEditPercentagesPage = useCallback(
    (percentageId: string) => {
      push(`/bbank-invest/investments/percentages/${percentageId}/edit`);
    },
    [push],
  );

  const handleDeletePercentage = useCallback(async (percentageId: string) => {
    await api.delete(`/investments/percentages/${percentageId}`);

    setPercentages(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(
        percentage => percentage.id !== percentageId,
      );

      return updatedState;
    });
  }, []);

  const handleNavigateToAddPercentagesPage = useCallback(() => {
    push('/bbank-invest/investments/percentages/new');
  }, [push]);

  const handlePushToAddInvestmentsPage = useCallback(() => {
    push('/bbank-invest/investments/new');
  }, [push]);

  const tableData = useMemo(() => {
    if (!percentages) {
      return [];
    }

    const data = percentages.map(percentage => ({
      id: percentage.id,
      name: percentage.name,
      search: `${percentage.period} meses`,
      percentage: `${percentage.percentage}%`,
      value: new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(percentage.value),
      income: new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(percentage.income),
      actions: (
        <TableButtonContainer>
          <TableButton
            type="button"
            styleType="success"
            title="Editar/detalhes"
            onClick={() => handleNavigateToEditPercentagesPage(percentage.id)}
          >
            <FiEdit size={22} />
          </TableButton>
          <TableButton
            type="button"
            styleType="danger"
            title="Clique duas vezes para deletar este registro!"
            onDoubleClick={() => handleDeletePercentage(percentage.id)}
          >
            <FiTrash size={22} />
          </TableButton>
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [
    percentages,
    handleNavigateToEditPercentagesPage,
    handleDeletePercentage,
  ]);

  const handleExportData = useCallback(() => {
    if (!percentages) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const percentagesLength = percentages.length;

    const worksheetData = Array.from(Array(percentagesLength).keys()).map(
      item => {
        const investmentPercentage = percentages[item];

        const worksheetLines = {
          Recorrência: numberFormat(investmentPercentage.income),
          Porcentagem: `${investmentPercentage.percentage}%`,
          Período: `${investmentPercentage.period} meses`,
          Valor: numberFormat(investmentPercentage.value),
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'dados_investimentos_porcentagens',
    });
  }, [percentages]);

  return (
    <>
      {!percentages ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Bbank Invest', 'Percentuais de rendimento', 'Todos']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Percentuais de rendimento</h1>

                <Dropdown
                  styles={{
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                    textColor: '#fff',
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button
                      type="button"
                      onClick={handleNavigateToAddPercentagesPage}
                    >
                      <FiPercent />
                      Adicionar porcentagem
                    </button>
                  </li>

                  <li>
                    <button
                      type="button"
                      onClick={handlePushToAddInvestmentsPage}
                    >
                      <FiPlusCircle />
                      Adicionar investimento
                    </button>
                  </li>

                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por período"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListInvestmentsPercentages };
