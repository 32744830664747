import { shade } from 'polished';
import styled, { css } from 'styled-components';

import { FormRow } from '@components/elements/Form/FormRow';

interface ICashBackPercentageFormRow {
  isShown: boolean;
}

export const ChooseAFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0;

  > div {
    display: flex;
    align-items: center;

    > label,
    > input {
      cursor: pointer;
    }

    > label {
      margin-right: 6px;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > button,
  > a {
    padding: 5px 10px;

    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;

    font-size: 16px;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${({ theme }) => shade(0.25, theme.colors.secondary)};
    }
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const CashBackPercentageFormRow = styled(
  FormRow,
)<ICashBackPercentageFormRow>`
  ${props =>
    props.isShown
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;
