import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { FiEye, FiPlusCircle } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { rolesTranslated } from '@utils/rolesTranslated';

import { IDataTableData, IState, IResponse } from './interfaces';

const AllBusinessClients: FC = () => {
  const { push } = useHistory();

  const [users, setUsers] = useState<Array<IState>>();

  const dataTableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Tipo de cadastro',
        selector: 'person_type',
      },
      {
        name: 'Documentos',
        selector: 'document',
      },
      {
        name: 'Papel',
        selector: 'role',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePushToUserView = useCallback(
    (id: string) => {
      push(`/business/clients/${id}/edit`);
    },
    [push],
  );

  const dataTableData = useMemo<Array<IDataTableData>>(() => {
    if (!users) {
      return [];
    }

    return users.map(data => {
      return {
        search: data.name,
        document: data.document,
        email: data.email,
        person_type: data.person_type,
        id: data.id,
        role: <Badge type="success">{rolesTranslated[data.role]}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              onClick={() => handlePushToUserView(data.id)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });
  }, [users, handlePushToUserView]);

  useEffect(() => {
    async function loadUsers() {
      const { data } = await api.get<IResponse>('/users-indicated');

      setUsers(data);
    }

    const timer = setTimeout(() => loadUsers(), 1500);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      {!users ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Clientes', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Listagem</h1>

                <Button
                  styleType="success"
                  icon={FiPlusCircle}
                  loading={false}
                  onClick={() => push('/business/clients/new')}
                >
                  Novo
                </Button>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={dataTableColumns}
                  data={dataTableData}
                  filterPlaceholder="Filtrar por nome"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { AllBusinessClients };
