import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns/esm';
import { FiDownload, FiEye, FiMoreHorizontal } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { parseISO } from 'date-fns';

import { URLPath } from '@components/layouts/UrlPath';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';
import { Badge } from '@components/elements/Badge';
import { Dropdown } from '@components/elements/Dropdown';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IFinancial } from './interfaces';

export const ListFinanceiro: FC = () => {
  const [financing, setFinancing] = useState<IFinancial[]>();

  const { push } = useHistory();

  useEffect(() => {
    async function loadFinancial() {
      const { data } = await api.get<any[]>('/financing/reports', {
        params: {
          relations: ['client'],
        },
      });

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IFinancial>(item);
      });

      setFinancing(parsedData);
    }

    loadFinancial();
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Operação realizada por',
        selector: 'search',
      },
      {
        name: 'Tipo de operação',
        selector: 'relation',
      },
      {
        name: 'Valor da operação',
        selector: 'operationValue',
      },
      {
        name: 'Data da operação',
        selector: 'operationDate',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleGoToFinancingDetails = useCallback(
    (financingId: string) => {
      push(`/financial/${financingId}/details`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!financing) {
      return [];
    }

    const data = financing.map(item => {
      const formattedDate = format(
        new Date(item.operationDate),
        'dd/MM/yyyy HH:mm:ss',
      );

      return {
        search: item?.client?.name,
        clientId: item?.clientId,
        relation: (
          <div style={{ whiteSpace: 'break-spaces' }}>{item?.relation}</div>
        ),
        operationDate: <Badge type="success">{formattedDate}</Badge>,
        operationValue: new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(item.operationValue),
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="info"
              onClick={() => handleGoToFinancingDetails(item.id)}
            />
          </TableButtonContainer>
        ),
        relationSearch: item?.relation,
        searchDate: formattedDate,
      };
    });

    return data;
  }, [financing, handleGoToFinancingDetails]);

  const handleExportToExcel = () => {
    if (!financing || financing.length === 0) {
      console.error('Não há dados para exportar.');
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const parsedFinancing = financing.map(item => ({
      'Nome do cliente': item.client?.name || 'Cliente não encontrado',
      Documentação: item.client?.document || 'Cliente não encontrado',
      'Tipo da operação': item.relation,
      'Data da operação': format(
        parseISO(item.operationDate),
        'dd/MM/yyyy HH:mm',
      ),
      'Valor da operação': numberFormat(Number(item.operationValue)),
      Tipo: Number(item.operationValue) > 0 ? 'Crédito' : 'Débito',
    }));

    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(parsedFinancing);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'financeiro_listagem');

    const excelBuffer = XLSX.write(workbook, {
      type: 'array',
      bookType: 'xlsx',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'financeiro_listagem.xlsx');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };
  return (
    <>
      {!financing ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Operações', 'Operações Geradas']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Operações geradas na plataforma</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#782273' }}
                  icon={FiMoreHorizontal}
                  isDisabled={financing.length === 0}
                >
                  <li>
                    <button type="button" onClick={handleExportToExcel}>
                      <FiDownload />
                      Exportar dados para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
