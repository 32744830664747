import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import { FiDownload } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { DataTable } from '@components/elements/Datatable';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IECommerceFile, IParams } from './interfaces';

export const ListECommerceFiles: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [files, setFiles] = useState<IECommerceFile[]>();

  useEffect(() => {
    async function loadECommerceFiles() {
      const { data } = await api.get<any[]>(
        `/ecommerce/files/${params.ecommerceId}`,
      );

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IECommerceFile>(item),
      );

      setFiles(parsedData);
    }

    const timer = setTimeout(() => loadECommerceFiles(), 1500);

    return () => clearInterval(timer);
  }, [params.ecommerceId]);

  const handleDownloadFile = useCallback((url: string) => {
    window.open(url);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do arquivo',
        selector: 'search',
      },
      {
        name: 'Data de upload',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!files) {
      return [];
    }

    const data = files.map(file => {
      return {
        id: file.id,
        search: file.title,
        createdAt: format(new Date(file.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiDownload}
              title="Fazer o download"
              styleType="success"
              onClick={() => handleDownloadFile(file.urlPath)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [files, handleDownloadFile]);

  return (
    <>
      {!files ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['ECommerce', 'Lista de arquivos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de arquivos</h1>

                <Badge type={files.length === 0 ? 'danger' : 'success'}>
                  {files.length}
                  &nbsp;arquivos foram encontrados
                </Badge>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
