import { useRouteMatch } from 'react-router-dom';
import { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Button } from '@components/elements/Button';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { toCamelCase } from '@helpers/toCamelCase';

import { formValidation } from './validations';
import { IDepartment, IFormData, ITicket, IParams } from './interfaces';
import { getDepartmentsOptions, platformOptions } from './selectOptions';

const UpdateTickets: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [departments, setDepartments] = useState<IDepartment[]>();
  const [ticket, setTicket] = useState<ITicket>();

  useEffect(() => {
    async function loadDepartments() {
      const { data } = await api.get<IDepartment[]>('/departments');

      setDepartments(data);
    }

    async function loadTicket() {
      const { data } = await api.get(`/tickets/${params.ticketId}`);

      setTicket(data);
    }

    const timer = setTimeout(async () => {
      await loadDepartments();
      loadTicket();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.ticketId]);

  const departmentsOptions = useMemo(() => {
    return departments ? getDepartmentsOptions(departments) : [];
  }, [departments]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const parsedData: { [key: string]: string } = {};

        Object.entries(data).forEach(([key, value]) => {
          const parsedKey = toCamelCase(key);

          parsedData[parsedKey] = value;
        });

        delete parsedData.answer;

        const { data: ticketUpdated } = await api.put(
          `/tickets/${params.ticketId}`,
          parsedData,
        );

        addToast({
          type: 'success',
          message: 'Ticket atualizado com sucesso!',
          title: 'Sucesso!',
        });

        setTicket(ticketUpdated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }

          if (status === 500) {
            addToast({
              title: 'Solicitação não processada!',
              message:
                'Um erro desconhecido aconteceu. Tente novamente mais tarde!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast, params.ticketId],
  );

  return (
    <>
      {!ticket ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Tickets', 'Meus tickets', ticket.title]} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  {ticket.status === 'em_aberto' ? 'Editar' : 'Visualizar'}
                  &nbsp;ticket
                </h1>

                {ticket.status === 'em_aberto' && (
                  <Badge type="warning">Em aberto</Badge>
                )}
                {ticket.status === 'em_andamento' && (
                  <Badge type="info">Em andamento</Badge>
                )}
                {ticket.status === 'finalizado' && (
                  <Badge type="success">Finalizado</Badge>
                )}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={ticket}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Assunto</label>
                      <Input
                        name="title"
                        upperCase={false}
                        readOnly={ticket.status !== 'em_aberto'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Departamento</label>
                      <Select
                        name="department_id"
                        options={departmentsOptions}
                        readonly={ticket.status !== 'em_aberto'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Plataforma</label>
                      <Select
                        name="platform"
                        options={platformOptions}
                        readonly={ticket.status !== 'em_aberto'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Conteúdo</label>
                      <Textarea
                        name="content"
                        rows={7}
                        readOnly={ticket.status !== 'em_aberto'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Reposta do administrador</label>
                      <Textarea name="answer" rows={15} readOnly />
                    </InputGroup>
                  </FormRow>

                  {ticket.status === 'em_aberto' && (
                    <FormRow buttonWrapper>
                      <Button styleType="success" type="submit" icon={FiSave}>
                        Salvar alterações
                      </Button>
                    </FormRow>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { UpdateTickets };
