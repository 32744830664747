import { FormHandles } from '@unform/core';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ValidationError } from 'yup';
import { FiSave, FiTrash } from 'react-icons/fi';
import Loader from 'react-loader-spinner';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';

import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { DataTable } from '@components/elements/Datatable';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import {
  ILoadedUser,
  IUser,
  IFormData,
  IDepartment,
  IParams,
  IDepartmentUser,
  ILoadedDepartmentUser,
} from './interfaces';
import { getUsersOptions } from './selectOptions';
import { formValidation } from './validations';
import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  TableButtonContainer,
  TableButton,
  LoaderContainer,
} from './styles';

const UpdateDepartment: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [users, setUsers] = useState<IUser[]>();
  const [department, setDepartment] = useState<IDepartment>();
  const [departmentUsers, setDepartmentUsers] = useState<IDepartmentUser[]>();
  const [deleteButtonState, setDeleteButtonState] = useState(false);

  useEffect(() => {
    async function getDataFromApi() {
      const { data: departmentFromApi } = await api.get(
        `/departments/${params.departmentId}`,
      );

      const { data } = await api.get<ILoadedUser[]>('/users');

      const filteredUsers = data.filter(user => user.role.role === 'admin');

      setUsers(filteredUsers);
      setDepartment(departmentFromApi);
    }

    async function loadDepartmentUsers() {
      const { data: departmentUsersFromApi } = await api.get<
        ILoadedDepartmentUser[]
      >(`/users-departments/department/${params.departmentId}`);

      const filteredDepartmentUsers = departmentUsersFromApi.map(item => {
        return {
          id: item.id,
          user: item.user,
          createdAt: item.created_at,
        };
      });

      setDepartmentUsers(filteredDepartmentUsers);
    }

    const timer = setTimeout(() => getDataFromApi(), 1500);

    const timer2 = setTimeout(() => loadDepartmentUsers(), 3000);

    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };
  }, [params.departmentId]);

  const usersOptions = useMemo(() => {
    return users ? getUsersOptions(users) : [];
  }, [users]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        if (data.userId) {
          const {
            data: userDepartmentUpdated,
          } = await api.post<ILoadedDepartmentUser>('/users-departments', {
            departmentId: params.departmentId,
            userId: data.userId,
          });

          setDepartmentUsers(oldState => {
            const parsedData = {
              id: userDepartmentUpdated.id,
              user: userDepartmentUpdated.user,
              createdAt: userDepartmentUpdated.created_at,
            };

            if (!oldState) {
              return [parsedData];
            }

            return [...oldState, parsedData];
          });
        }

        await api.put<IDepartment>(`/departments/${params.departmentId}`, {
          name: data.name,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          message: 'Departamento atualizado com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }

          if (status === 500) {
            addToast({
              title: 'Solicitação não processada!',
              message:
                'Um erro desconhecido aconteceu. Tente novamente mais tarde!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast, params.departmentId],
  );

  const handleDeleteUserFromDepartment = useCallback(
    async (departmentUserId: string) => {
      try {
        setDeleteButtonState(true);

        await api.delete(`/users-departments/${departmentUserId}`);

        setDepartmentUsers(oldState => {
          if (!oldState) {
            return undefined;
          }

          const updatedState = oldState.filter(
            state => state.id !== departmentUserId,
          );

          return updatedState;
        });
      } catch (err: any) {
        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }

          if (status === 500) {
            addToast({
              title: 'Solicitação não processada!',
              message:
                'Um erro desconhecido aconteceu. Tente novamente mais tarde!',
              type: 'error',
            });
          }
        }
      } finally {
        setDeleteButtonState(false);
      }
    },
    [addToast],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do usuário',
        selector: 'search',
      },
      {
        name: 'Data de atribuição',
        selector: 'userAddedAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!departmentUsers) {
      return [];
    }

    const data = departmentUsers.map(item => {
      return {
        id: item.id,
        search: item.user.name,
        userAddedAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="danger"
              title="Clique duas vezes para deletar!"
              onDoubleClick={() => handleDeleteUserFromDepartment(item.id)}
              loading={Number(deleteButtonState)}
            >
              <FiTrash size={20} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [departmentUsers, handleDeleteUserFromDepartment, deleteButtonState]);

  return (
    <>
      {!users || !department ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Departamentos</li>
              <li>{'>'}</li>
              <li>Editar</li>
              <li>{'>'}</li>
              <li>{department.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Usuários atribuídos a este departamento</h1>
              </CardHeader>

              <CardContent>
                {!departmentUsers ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      color="${({ theme }) => theme.colors.secondary}"
                      width={50}
                      height={50}
                    />
                  </LoaderContainer>
                ) : (
                  <DataTable columns={tableColumns} data={tableData} />
                )}
              </CardContent>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Editar um departamento existente</h1>

                {/* <Button
            styleType="info"
            icon={FiPlusCircle}
            onClick={addUserSelect}
          >
            Adicionar usuários
          </Button> */}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={department}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Nome do departamento</label>
                      <Input name="name" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Atribua novos usuários!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Selecione um usuário</label>
                      <Select name="userId" options={usersOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiSave}>
                      Atualizar departamento
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { UpdateDepartment };
