import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';

import { FormRow } from '@components/elements/Form/FormRow';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { formValidation } from './validations';
import { IClient, IFormData } from './interfaces';
import { getClientsOptions } from './selectOptions';

const MonthlyPayment: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { addToast } = useToast();

  const [clients, setClients] = useState<IClient[]>();

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users');

      setClients(data);
    }

    const timer = setTimeout(async () => {
      loadClients();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handleFormSubmit = useCallback(async (data: IFormData, { reset }) => {
    try {
      setLoadingRequest(true);
      formRef.current?.setErrors({});

      console.log(data);

      await formValidation(data);

      await api.post('/users/monthly-payment', data);

      addToast({
        title: 'Solicitação feita com sucesso!',
        type: 'success',
        message: 'Mensalidade adicionada com sucesso!',
      });

      reset();
    } catch (err: any) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }

      if (err.response) {
        const { message, status } = getClientErrors(err.response);

        if (status === 400 || status === 404) {
          addToast({
            title: 'Solicitação não processada!',
            type: 'error',
            message,
          });
        }

        if (status === 500) {
          addToast({
            title: 'Algum erro aconteceu!',
            type: 'error',
            message:
              'Por favor, contate o administrador do sistema e reporte o erro!',
          });
        }
      }
    } finally {
      setLoadingRequest(false);
    }
  }, []);

  const clientOptions = useMemo(() => {
    return clients ? getClientsOptions(clients) : [];
  }, [clients]);

  return (
    <>
      <Row>
        <Card>
          <CardHeader>
            <h1>Mensalidades</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Usuário</label>
                  <Select name="userId" options={clientOptions} />
                </InputGroup>

                <InputGroup>
                  <label>
                    Valor (deixe vazio para recuperar a taxa previamente
                    configurada)
                  </label>
                  <InputCurrency name="amount" />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button
                  styleType="success"
                  icon={FiSave}
                  type="submit"
                  loading={loadingRequest}
                >
                  Adicionar Mensalidade
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export default MonthlyPayment;
