import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns/esm';
import { ValidationError } from 'yup';
import { FiSave } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputFile } from '@components/elements/Form/InputFile';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { IClient, IFile, IFormData, IParsedFormData } from './interfaces';
import { getClientsOptions, operationType } from './selectOptions';
import { formValidation } from './validations';

const Financeiro: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { addToast } = useToast();

  const [clients, setClients] = useState<IClient[]>();
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users/clients');

      setClients(data);
    }

    loadClients();
  }, []);

  const handlInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: string, title: string) => {
      const inputFileRef = formRef.current?.getFieldRef(e.target.name);

      if (!e.target.files || e.target.files.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      const file = e.target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];
      const fileSize = file.size * 0.000001;

      if (fileExtension !== 'pdf' && fileExtension !== 'png') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos PDF ou PNG',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      if (fileSize > 2.1) {
        addToast({
          title: 'Arquivo grande demais!',
          message: 'São aceitos apenas arquivos com 2MB ou menos',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      setFiles(oldState => {
        const findDuplicated = oldState.findIndex(item => item.key === key);

        if (findDuplicated >= 0) {
          oldState.splice(findDuplicated, 1);
        }

        return [...oldState, { file, key, title }];
      });
    },
    [addToast],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const parsedData = {
          clientId: data.clientId,
          relation: data.relation,
          operationValue: data.operationValue,
          operationDate: data.operationDate,
        };

        await api.post('/financing/reports', parsedData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Sua operação foi enviado com sucesso!',
        });
        reset();
        setFiles([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status >= 400 && status < 499) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },

    [addToast],
  );

  const clientOptions = useMemo(() => {
    if (!clients) {
      return [];
    }

    return getClientsOptions(clients);
  }, [clients]);

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Financeiro', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Nova Operação</h1>
              </CardHeader>

              <CardContent>
                <Form ref={formRef} onSubmit={handleFormSubmit}>
                  <FormRow separator>
                    <h1>Preencha os dados para uma operação</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Escolha um cliente</label>
                      <Select name="clientId" options={clientOptions} />
                    </InputGroup>
                    <InputGroup>
                      <label>Tipo de operação</label>
                      <Select name="relation" options={operationType} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor da operação</label>
                      <InputCurrency name="operationValue" />
                    </InputGroup>
                    <InputGroup>
                      <label>Data da operação</label>
                      <Input
                        name="operationDate"
                        defaultValue={format(new Date(), 'dd/MM/yyyy')}
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Arquivos</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Comprovante de Operação
                        <span>*</span>
                      </label>
                      <InputFile
                        name="financingId"
                        onChange={e =>
                          handlInputFileChange(
                            e,
                            'AwQT',
                            'Comprovante de operação',
                          )
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      type="submit"
                      icon={FiSave}
                      loading={loadingRequest}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { Financeiro };
