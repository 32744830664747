import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { OptionTypeBase } from 'react-select';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Button } from '@components/elements/Button';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { consoleLog } from '@helpers/consoleLog';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { ITaxesGroup, ITax, IForm } from './interfaces';
import { validateFields } from './validations';

export const TaxesGroupDetails = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [taxesGroup, setTaxesGroup] = useState<ITaxesGroup>();
  const [taxes, setTaxes] = useState<ITax[]>([]);
  const [prefix, setPrefix] = useState<string>('');

  useEffect(() => {
    async function loadTaxesGroup() {
      const { data } = await api.get(`/taxes-group/${params.id}`);

      setTaxesGroup(data);
      setTaxes(data.taxes);
    }

    loadTaxesGroup();
  }, [params]);

  const testing = taxes.map(item => item.product_name);

  const handleDeleteTax = useCallback(async ({ id }: ITax) => {
    await api.delete(`/client-taxes/${id}`);

    setTaxes(oldState => {
      const updatedState = oldState.filter(item => item.id !== id);

      return updatedState;
    });
  }, []);

  const handleTaxTypeChange = useCallback((option: OptionTypeBase | null) => {
    if (!option) return;

    const { value } = option;

    if (value === 'percentage') {
      setPrefix('%');
    } else if (value === 'price') {
      setPrefix('R$');
    }
  }, []);

  const tableData = useMemo(() => {
    return taxes.map(item => {
      const numberFormat = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;
      const taxString =
        item.type === 'percentage'
          ? `${item.value}%`
          : numberFormat(item.value);

      return {
        product: item.product_name,
        tax: taxString,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEdit2}
              styleType="warning"
              title="Editar"
              onClick={() => {
                consoleLog(item);

                formRef.current?.setData(item);
              }}
            />

            <TableButton
              icon={FiTrash2}
              styleType="danger"
              title="Clique duas vezes para remover taxa"
              onDoubleClick={() => handleDeleteTax(item)}
            />
          </TableButtonContainer>
        ),
      };
    });
  }, [taxes, handleDeleteTax]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Produto',
        selector: 'product',
      },
      {
        name: 'Taxa aplicada',
        selector: 'tax',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IForm, { reset }) => {
      try {
        await validateFields(data);

        formRef.current?.setErrors({});

        const cleanedValue = data.value
          .toString()
          .replace(/[^\d.]/g, '')
          .replace(/^0+(\d)/, '$1')
          .replace(/\.$/, '')
          .replace(/%$/, '');

        const { data: response } = await api.post('/client-taxes', {
          product: data.product,
          type: data.type,
          value: cleanedValue,
          taxesGroupId: params.id,
        });

        setTaxes(oldState => {
          const findIndex = oldState.findIndex(item => item.id === response.id);

          if (findIndex >= 0) {
            const updatedState = [...oldState];

            updatedState[findIndex] = response;

            return updatedState;
          }

          return [...oldState, response];
        });

        addToast({
          title: 'Sucesso!',
          message: 'A taxa foi configurada com sucesso para a tabela atual!',
          type: 'success',
        });

        reset();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        consoleLog(err);
      }
    },
    [addToast, params.id],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Tabela de taxas', taxesGroup?.name || '']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Taxas contidas nesta tabela</h1>
          </CardHeader>

          <CardContent>
            <DataTable data={tableData} columns={tableColumns} />
          </CardContent>
        </Card>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Adicionar novas taxas a esta tabela</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Produto</label>
                  <Select
                    name="product"
                    options={[
                      { label: 'PIX Out', value: 'pix' },
                      { label: 'Boletos', value: 'billet' },
                      {
                        label: 'PIX QR CODE (Dinâmico)',
                        value: 'dynamic-invoice',
                      },
                      {
                        label: 'PIX QR CODE (Estático)',
                        value: 'static-invoice',
                      },
                      { label: 'TED', value: 'ted' },
                      { label: 'Mensalidade', value: 'monthly-payment' },
                    ]}
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Tipo da taxa</label>
                  <Select
                    name="type"
                    options={[
                      { label: 'Porcentagem', value: 'percentage' },
                      { label: 'Preço', value: 'price' },
                    ]}
                    onChange={handleTaxTypeChange}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Valor da taxa</label>
                  <InputCurrency name="value" prefix={prefix} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success">
                  Salvar taxa
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
