import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OptionTypeBase } from 'react-select';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { FiSave } from 'react-icons/fi';

import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { IOption } from '@utils/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  Card,
  CardContent,
  CardHeader,
  VehicleGuarantee,
  PropertyGuarantee,
  CredLuzGuarantee,
} from './styles';
import {
  creditProducts,
  loadBanks,
  loadUsers,
  loadStates,
  maritalStatusOptions,
  propertyType,
  ownPropertyOptions,
} from './selectOptions';
import {
  creditValidationShape,
  credluzValidationShape,
  propertyGuaranteeValidation,
  vehicleGuaranteeValidationShape,
} from './validations';
import { IClient, IFormData, IFranchisee, IGuaranteeTypes } from './interfaces';

const FranchiseeCreatePFCredit: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [clients, setClients] = useState<IClient[]>();
  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [creditProduct, setCreditProduct] = useState<IGuaranteeTypes>(
    '' as IGuaranteeTypes,
  );

  useEffect(() => {
    async function getFranchiseesFromAPI() {
      const { data } = await api.get<IFranchisee[]>('/users-indicated');

      setClients(data);
    }

    const timer = setTimeout(() => getFranchiseesFromAPI(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleGetGuaranteeValidation = useCallback((type: IGuaranteeTypes) => {
    if (type === 'property') {
      return propertyGuaranteeValidation;
    }

    if (type === 'credluz') {
      return credluzValidationShape;
    }

    return vehicleGuaranteeValidationShape;
  }, []);

  const handleGetGuaranteeEndpoint = useCallback((type: IGuaranteeTypes) => {
    if (type === 'credluz') {
      return '/credit-guarantees/cred-luz';
    }

    if (type === 'property') {
      return '/credit-guarantees/properties';
    }

    return '/credit-guarantees/vehicles';
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        const { credit } = data;

        formRef.current?.setErrors({});

        await creditValidationShape(credit);

        const guaranteeValidation = handleGetGuaranteeValidation(creditProduct);
        const guaranteeData = data[creditProduct];

        await guaranteeValidation(guaranteeData);

        const guaranteeEndpoint = handleGetGuaranteeEndpoint(creditProduct);

        const {
          data: { id: creditId },
        } = await api.post('/credits', {
          ...credit,
          indication: user.id,
          type: 'PF',
        });

        await api.post(guaranteeEndpoint, { ...guaranteeData, creditId });

        reset();
        setCreditProduct('' as IGuaranteeTypes);

        addToast({
          type: 'success',
          title: 'Muito bom!',
          message: 'Os dados inseridos foram enviados e salvos com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro.',
            });
          }
        }
      }
    },
    [
      addToast,
      creditProduct,
      handleGetGuaranteeValidation,
      handleGetGuaranteeEndpoint,
      user,
    ],
  );

  const handleModalityInputChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value } = option;

      if (value === 'com_garantia') {
        setProductOptions([
          { value: 'vehicle', label: 'Veículos' },
          { value: 'property', label: 'Imóvel' },
        ]);
        return;
      }

      setProductOptions([{ value: 'credluz', label: 'CredLuz' }]);
    },
    [],
  );

  const handleGuaranteeTypeChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      setCreditProduct(option.value);
    },
    [],
  );

  const bankOptions = useMemo(() => {
    return loadBanks();
  }, []);

  const clientOptions = useMemo(() => {
    return clients ? loadUsers(clients) : undefined;
  }, [clients]);

  const stateOptions = useMemo(() => {
    return loadStates();
  }, []);

  const credLuzFormPart = useMemo(() => {
    return (
      <CredLuzGuarantee>
        <FormRow separator>
          <h1>CredLuz</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Registro geral (RG)
              <span>*</span>
            </label>
            <Input name="credluz.rg" maxLength={20} />
          </InputGroup>

          <InputGroup>
            <label>
              Orgão expeditor (RG)
              <span>*</span>
            </label>
            <Input name="credluz.issuingBody" maxLength={50} />
          </InputGroup>

          <InputGroup>
            <label>
              Data de nascimento
              <span>*</span>
            </label>
            <Input type="date" name="credluz.birthday" id="credluz.birthday" />
          </InputGroup>

          <InputGroup>
            <label>
              Status civil
              <span>*</span>
            </label>
            <Select
              name="credluz.maritalStatus"
              id="credluz.maritalStatus"
              options={maritalStatusOptions}
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Escolaridade
              <span>*</span>
            </label>
            <Input name="credluz.schooling" />
          </InputGroup>

          <InputGroup>
            <label>
              Nome do pai
              <span>*</span>
            </label>
            <Input name="credluz.fatherName" />
          </InputGroup>

          <InputGroup>
            <label>
              Nome da mãe
              <span>*</span>
            </label>
            <Input name="credluz.motherName" />
          </InputGroup>

          <InputGroup>
            <label>
              Sexo
              <span>*</span>
            </label>
            <Input name="credluz.sex" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Referência
              <span>*</span>
            </label>
            <Input name="credluz.reference" />
          </InputGroup>

          <InputGroup>
            <label>
              Telefone da referência
              <span>*</span>
            </label>
            <InputMask name="credluz.referencePhone" mask="(99) 99999-9999" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Segunda referência
              <span>*</span>
            </label>
            <Input name="credluz.secondReference" />
          </InputGroup>

          <InputGroup>
            <label>
              Telefone da segunda referência
              <span>*</span>
            </label>
            <InputMask
              name="credluz.secondReferencePhone"
              mask="(99) 99999-9999"
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Unidade consumidora
              <span>*</span>
            </label>
            <Input name="credluz.consumerUnit" />
          </InputGroup>
        </FormRow>

        <FormRow separator>
          <h1>Endereço</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Número
              <span>*</span>
            </label>
            <Input name="credluz.number" />
          </InputGroup>

          <InputGroup>
            <label>
              Bairro
              <span>*</span>
            </label>
            <Input name="credluz.neighborhood" />
          </InputGroup>

          <InputGroup>
            <label>
              Complemento
              <span>*</span>
            </label>
            <Input name="credluz.complement" />
          </InputGroup>
        </FormRow>
      </CredLuzGuarantee>
    );
  }, []);

  const propertyGuaranteeFormPart = useMemo(() => {
    return (
      <PropertyGuarantee>
        <FormRow separator>
          <h1>Propriedade</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Registro geral (RG)
              <span>*</span>
            </label>
            <Input name="property.generalRegistry" maxLength={50} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Tipo do imóvel
              <span>*</span>
            </label>
            <Select name="property.propertyType" options={propertyType} />
          </InputGroup>

          <InputGroup>
            <label>
              Logradouro
              <span>*</span>
            </label>
            <Input name="property.publicPlace" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Número
              <span>*</span>
            </label>
            <Input type="number" name="property.number" />
          </InputGroup>

          <InputGroup>
            <label>
              Condomínio
              <span>*</span>
            </label>
            <Input name="property.condominium" />
          </InputGroup>

          <InputGroup>
            <label>
              Unidade
              <span>*</span>
            </label>
            <Input name="property.unity" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Torre
              <span>*</span>
            </label>
            <Input name="property.tower" />
          </InputGroup>

          <InputGroup>
            <label>
              Quantidade de vagas
              <span>*</span>
            </label>
            <Input name="property.vacancy" />
          </InputGroup>

          <InputGroup>
            <label>
              Bairro
              <span>*</span>
            </label>
            <Input name="property.neighborhood" />
          </InputGroup>

          <InputGroup>
            <label>
              Cidade
              <span>*</span>
            </label>
            <Input name="property.city" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Selecione um estado
              <span>*</span>
            </label>
            <Select name="property.state" options={stateOptions} />
          </InputGroup>

          <InputGroup>
            <label>
              CEP
              <span>*</span>
            </label>
            <InputMask name="property.zipcode" mask="99999-999" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Valor da garantia
              <span>*</span>
            </label>
            <Input name="property.guaranteeValue" />
          </InputGroup>

          <InputGroup>
            <label>
              Crédito pretendido
              <span>*</span>
            </label>
            <Input name="property.intendedCredit" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Período de financiamento
              <span>*</span>
            </label>
            <Input name="property.financingPeriod" />
          </InputGroup>

          <InputGroup>
            <label>
              Imóvel próprio?
              <span>*</span>
            </label>
            <Select name="property.ownProperty" options={ownPropertyOptions} />
          </InputGroup>

          <InputGroup>
            <label>Relacionamento com o terceiro</label>
            <Input name="property.relationship" />
          </InputGroup>

          <InputGroup>
            <label>Nome do terceiro</label>
            <Input name="property.thirdPartyName" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Banco</label>
            <Select name="property.bank" options={bankOptions} />
          </InputGroup>

          <InputGroup>
            <label>CPF do terceiro</label>
            <InputMask name="property.thirdPartyCpf" mask="999.999.999-99" />
          </InputGroup>

          <InputGroup>
            <label>CPF/CNPJ</label>
            <Input name="property.cpfCnpj" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Agência</label>
            <Input name="property.agency" maxLength={4} />
          </InputGroup>

          <InputGroup>
            <label>Conta</label>
            <Input name="property.account" />
          </InputGroup>
        </FormRow>
      </PropertyGuarantee>
    );
  }, [bankOptions, stateOptions]);

  const vehicleGuaranteeFormPart = useMemo(() => {
    return (
      <VehicleGuarantee>
        <FormRow separator>
          <h1>Veículos</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Data de nascimento
              <span>*</span>
            </label>
            <InputMask
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              name="vehicle.birthday"
              noUnmask
            />
          </InputGroup>

          <InputGroup>
            <label>
              Celular
              <span>*</span>
            </label>
            <InputMask name="vehicle.cellphone" mask="(99) 99999-9999" />
          </InputGroup>

          <InputGroup>
            <label>
              Telefone
              <span>*</span>
            </label>
            <InputMask name="vehicle.phone" mask="(99) 9999-9999" />
          </InputGroup>

          <InputGroup>
            <label>
              Telefone comercial
              <span>*</span>
            </label>
            <InputMask name="vehicle.commercialPhone" mask="(99) 9999-9999" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Faturamento
              <span>*</span>
            </label>
            <InputCurrency name="vehicle.income" />
          </InputGroup>

          <InputGroup>
            <label>
              Registro geral (RG)
              <span>*</span>
            </label>
            <Input name="vehicle.generalRegistry" />
          </InputGroup>

          <InputGroup>
            <label>
              Estado civil
              <span>*</span>
            </label>
            <Select
              name="vehicle.maritalStatus"
              options={maritalStatusOptions}
            />
          </InputGroup>

          <InputGroup>
            <label>
              Nome do cliente
              <span>*</span>
            </label>
            <Input name="vehicle.clientName" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Empresa
              <span>*</span>
            </label>
            <Input name="vehicle.company" />
          </InputGroup>

          <InputGroup>
            <label>
              Data de admissão
              <span>*</span>
            </label>
            <InputMask
              mask="99/99/9999"
              placeholder="dd/mm/aaaa"
              name="vehicle.admissionDate"
              noUnmask
            />
          </InputGroup>

          <InputGroup>
            <label>
              Função na empresa
              <span>*</span>
            </label>
            <Input name="vehicle.employeeCompanyRole" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Tempo de conta bancária (em anos)
              <span>*</span>
            </label>
            <Input type="number" name="vehicle.bankAccountAge" />
          </InputGroup>

          <InputGroup>
            <label>
              Nome da mãe
              <span>*</span>
            </label>
            <Input name="vehicle.motherName" />
          </InputGroup>

          <InputGroup>
            <label>
              Marca do veículo
              <span>*</span>
            </label>
            <Input name="vehicle.vehicleBrand" />
          </InputGroup>

          <InputGroup>
            <label>
              CPF do responsável
              <span>*</span>
            </label>
            <InputMask name="vehicle.cpf" mask="999.999.999-99" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Nacionalidade
              <span>*</span>
            </label>
            <Input name="vehicle.nationality" />
          </InputGroup>

          <InputGroup>
            <label>
              Profissão
              <span>*</span>
            </label>
            <Input name="vehicle.profession" />
          </InputGroup>

          <InputGroup>
            <label>
              Referência
              <span>*</span>
            </label>
            <Input name="vehicle.thirdParty" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Modelo do veículo
              <span>*</span>
            </label>
            <Input name="vehicle.vehicleModel" />
          </InputGroup>

          <InputGroup>
            <label>
              Ano (fabricação e modelo)
              <span>*</span>
            </label>
            <Input name="vehicle.vehicleFabricationYear" />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>
              Versão do veículo
              <span>*</span>
            </label>
            <Input name="vehicle.vehicleVersion" />
          </InputGroup>

          <InputGroup>
            <label>
              Valor do veículo
              <span>*</span>
            </label>
            <InputCurrency name="vehicle.vehicleValue" />
          </InputGroup>

          <InputGroup>
            <label>
              Valor do empréstimo
              <span>*</span>
            </label>
            <InputCurrency name="vehicle.loanAmount" />
          </InputGroup>
        </FormRow>
      </VehicleGuarantee>
    );
  }, []);

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['CredCash', 'Novo', 'Pessoa física']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo crédito</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Escolha um cliente
                        <span>*</span>
                      </label>
                      <Select name="credit.user_id" options={clientOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados complementares</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Renda comprovada
                        <span>*</span>
                      </label>
                      <InputCurrency name="credit.average_of_revenues" />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Telefone
                        <span>*</span>
                      </label>
                      <InputMask name="credit.phone" mask="(99) 9999-9999" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Informações do crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>
                        Valor do crédito solicitado (sem casas decimais)
                        <span>*</span>
                      </label>
                      <InputCurrency name="credit.amount" />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Tipo de crédito
                        <span>*</span>
                      </label>
                      <Select
                        name="credit.modality"
                        options={creditProducts}
                        onChange={handleModalityInputChange}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>
                        Tipo de garantia
                        <span>*</span>
                      </label>
                      <Select
                        name="credit.product"
                        options={productOptions}
                        onChange={handleGuaranteeTypeChange}
                      />
                    </InputGroup>
                  </FormRow>

                  {creditProduct === 'credluz' && credLuzFormPart}
                  {creditProduct === 'vehicle' && vehicleGuaranteeFormPart}
                  {creditProduct === 'property' && propertyGuaranteeFormPart}

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      icon={FiSave}
                      styleType="success"
                      style={{ marginTop: 20 }}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FranchiseeCreatePFCredit };
