/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    userId: Yup.string().required('Preencha este campo!').uuid('Inválido'),
  });

  await shape.validate(data, { abortEarly: false });
};
