import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { Canvg } from 'canvg';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Button } from '@components/elements/Button';
import { FormRow } from '@components/elements/Form/FormRow';

import { useToast } from '@hooks/toast';

import { IParams } from './interfaces';
import { QRWrapper } from './styles';

export const QrCodesAsImage: FC = () => {
  const { addToast } = useToast();
  const { url } = useRouteMatch<IParams>();
  const svgRef = useRef<any>(null);

  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (!url) {
      addToast({
        type: 'error',
        title: 'Ops... Algo não está certo!',
        message: 'Sua navegação é inválida!',
      });
      return;
    }

    setValue(
      window.location.href
        .substring(window.location.href.indexOf('qr-code/'))
        .replace('qr-code/', ''),
    );
  }, [url, addToast]);

  const handleDownloadAdPdf = useCallback(async () => {
    const canvasElement = document.querySelector('canvas') as HTMLCanvasElement;
    const ctx = canvasElement.getContext('2d') as CanvasRenderingContext2D;

    const loaded = await Canvg.from(ctx, svgRef.current?.outerHTML);

    if (!loaded) {
      return;
    }

    await loaded.render();

    const img = canvasElement.toDataURL('image/png');

    const link = document.createElement('a');

    link.setAttribute('href', img);
    link.setAttribute('download', 'qr-code.png');

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }, []);

  return (
    <>
      {!value ? (
        <LoadingPage />
      ) : (
        <>
          <URLPath paths={['QR Code']} />

          <Card>
            <CardHeader>
              <h1>Imagem do QR CODE solicitado</h1>
            </CardHeader>

            <CardContent>
              <QRWrapper>
                <QRCode
                  ref={svgRef}
                  size={256}
                  style={{ height: 300, width: 300 }}
                  value={value}
                  viewBox="0 0 256 256"
                />
                <canvas id="canvas" style={{ display: 'none' }} />
              </QRWrapper>

              <FormRow buttonWrapper>
                <Button styleType="success" onClick={handleDownloadAdPdf}>
                  Fazer download da imagem
                </Button>
              </FormRow>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
