import { ComponentType, FC, useCallback, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { useSpring } from 'react-spring';

import { useAside } from '@hooks/aside';

import { Container, Dropdown } from './styles';

interface IAsideItemProps {
  icon: ComponentType<IconBaseProps>;
  title: string;
  link?: string;
  externalLink?: boolean;
}

const AsideItem: FC<IAsideItemProps> = ({
  icon: Icon,
  title,
  link,
  externalLink = false,
  children,
}) => {
  const [isDropped, setIsDropped] = useState(false);
  const { handleAsideVisibility } = useAside();
  const props = useSpring({
    maxHeight: isDropped ? '280px' : '0px',
  });

  const handleDropdown = useCallback(() => {
    if (!children) {
      return;
    }

    setIsDropped(!isDropped);
  }, [isDropped, children]);

  const handleLinkOnClick = useCallback(() => {
    handleAsideVisibility();

    const mainContent = document.getElementById('mainContent');

    if (mainContent) {
      window.scrollTo({
        top: mainContent.scrollHeight + 80,
        behavior: 'smooth',
      });
    }
  }, [handleAsideVisibility]);

  return (
    <>
      <Container hasLink={!!link} onClick={handleDropdown}>
        {!link ? (
          <>
            <Icon size={21} />

            <div>
              <span>{title}</span>
            </div>

            <FiChevronRight
              size={18}
              style={isDropped ? { transform: 'rotate(90deg)' } : {}}
            />
          </>
        ) : (
          <>
            {externalLink ? (
              <a href={link} target="_blank" rel="noreferrer">
                <Icon size={21} />

                <div>
                  <span>{title}</span>
                </div>
              </a>
            ) : (
              <Link to={link} onClick={handleLinkOnClick}>
                <Icon size={21} />

                <div>
                  <span>{title}</span>
                </div>
              </Link>
            )}
          </>
        )}
      </Container>
      {children && (
        <Dropdown style={props}>
          <ul>{children}</ul>
        </Dropdown>
      )}
    </>
  );
};

export { AsideItem };
