import { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useLocation } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Button } from '@components/elements/Button';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { consoleLog } from '@helpers/consoleLog';

import { IForm, ITaxesGroup } from './interfaces';
import { validateFields } from './validations';

export const CreateTaxesGroups = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { search } = useLocation();
  const formRef = useRef<FormHandles>(null);

  const [taxesGroup, setTaxesGroup] = useState<ITaxesGroup>();

  useEffect(() => {
    async function loadTaxesGroup() {
      const query = new URLSearchParams(search);
      const id = query.get('id');

      consoleLog({ query, search, id });

      if (id) {
        const { data } = await api.get(`/taxes-group/${id}`);

        setTaxesGroup(data);
      }
    }

    loadTaxesGroup();
  }, [search]);

  consoleLog(taxesGroup);

  const handleFormSubmit = useCallback(
    async (data: IForm) => {
      try {
        await validateFields(data);

        if (!taxesGroup) {
          await api.post(`/taxes-group`, data);

          addToast({
            title: 'Sucesso!',
            type: 'success',
            message: 'A nova tabela foi adicionada com sucesso!',
          });
        } else {
          await api.put(`/taxes-group/${taxesGroup.id}`, data);

          addToast({
            title: 'Sucesso!',
            type: 'info',
            message: 'A nova tabela foi atualizada com sucesso!',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [user, addToast, taxesGroup],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Tabela de taxas', 'Nova tabela']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Preencha os dados abaixo</h1>
          </CardHeader>

          <CardContent>
            <Form
              ref={formRef}
              onSubmit={handleFormSubmit}
              initialData={taxesGroup}
            >
              <FormRow>
                <InputGroup>
                  <label>Nome da tabela</label>
                  <Input name="name" defaultValue={taxesGroup?.name} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success">
                  {taxesGroup ? 'Atualizar tabela' : 'Salvar tabela'}
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
