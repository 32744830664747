import * as Yup from 'yup';

export const formValidation = async (data: {
  [key: string]: string;
}): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
    email: Yup.string()
      .email('Insira um email válido!')
      .required('Preencha este campo!'),
    password: Yup.string()
      .required('Preencha este campo!')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Precisa conter 8 caracteres, 1 letra maiúscula, minúscula, caractere especial e número!',
      ),
    confirmPassword: Yup.string()
      .required('Preencha este campo!')
      .oneOf([Yup.ref('password')], 'As senhas não correspondem!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    cellphone: Yup.string().required('Preencha este campo!'),
    person_type: Yup.string().required('Preencha este campo!'),
    document: Yup.string().required('Preencha este campo!'),
    role: Yup.string()
      .oneOf(['business', 'client'], 'Business ou cliente?')
      .required('Selecione uma opção!'),
    nickname: Yup.string().when({
      is: (_: string) => data.role === 'business',
      then: Yup.string().required('Preencha este campo!'),
    }),
    birthdate: Yup.string()
      .max(10, 'Insira uma data válida!')
      .min(10, 'Insira uma data válida')
      .required('Preenha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
