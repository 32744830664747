import { shade } from 'polished';
import styled from 'styled-components';

export const TextField = styled.input`
  display: flex;
  align-items: center;

  background-color: #f3f6f9;

  height: 32px;
  max-width: 300px;
  width: 100%;

  border: 2px solid transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;

  padding: 0 10px;

  transition: background-color 0.2s;

  transition: border-color 0.2s;

  &:focus {
    background-color: #fff;

    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ClearButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  > svg {
    color: #fff;

    transition: background-color 0.2s;
  }

  &:hover {
    background-color: ${({ theme }) => shade(0.25, theme.colors.primary)};

    > svg {
      color: ${shade(0.2, '#fff')};
    }
  }
`;
