import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

export const ListAssignorsMe: FC = () => {
  const { push } = useHistory();

  const [billets, setBillets] = useState<any[]>();

  useEffect(() => {
    async function loadBillets() {
      const { data } = await api.get<any[]>('/billets/assignors/me');

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<any>(item),
      );

      setBillets(parsedData);
    }

    const timer = setTimeout(() => loadBillets(), 1500);

    return () => clearInterval(timer);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Boleto gerado para',
        selector: 'name',
      },
      {
        name: 'Criado',
        selector: 'createdAt',
      },
      {
        name: 'Código de Bar',
        selector: 'insertLine',
      },
      // {
      //   name: 'Comentario',
      //   selector: 'comments',
      // },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleToDetailsPage = useCallback(
    (billetId: string) => {
      push(`/bwallet/billets/${billetId}/details`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!billets) {
      return [];
    }

    const data = billets?.map(billet => {
      return {
        name: billet.payer.name,
        createdAt: format(new Date(billet.createdAt), 'dd/MM/yyyy'),
        insertLine: billet.insertLine,
        //     comments: billet.comments,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="info"
              onClick={() => handleToDetailsPage(billet.integrationId)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [billets, handleToDetailsPage]);

  return (
    <>
      {!billets ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Boletos', 'Boletos gerados por mim']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Boletos gerados por mim</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
