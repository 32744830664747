import { FC, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiHash, FiLogIn } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import themes, { ThemeNames } from '../../../../styles/themes';
import {
  Container,
  Background,
  Content,
  ImageWrapper,
  Instructions,
} from './styles';
import { formValidation } from './validations';

const ForgotPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const currentTheme: ThemeNames = process.env.REACT_APP_THEME as ThemeNames;

  const handleFormSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        await api.post('/user-password/send-recovery-sms', {
          document: data.document,
        });

        addToast({
          type: 'success',
          title: 'SMS enviado!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 403 || status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Erro interno do servidor!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <Background />
        <Content>
          <div>
            <ImageWrapper>
              <img src={themes[currentTheme].images.logo} alt="Logo" />
            </ImageWrapper>

            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <h1>Verifique sua identidade</h1>

              <Instructions>
                Após inserir o seu documento (CPF/CNPJ) sejá enviado um SMS para
                o número de celular vinculado à sua conta junto com o link de
                recuperação
              </Instructions>

              <Input
                name="document"
                icon={FiHash}
                placeholder="Insira seu documento (CPF/CNPJ)"
                maxLength={14}
              />

              <button type="submit">Enviar</button>

              <Link to="/">
                <FiLogIn size={20} />
                Voltar para o login
              </Link>
            </Form>

            <ul>
              <li>
                {new Date().getFullYear()}
                &copy;
              </li>
              <li>Todos os direitos reservados</li>
            </ul>
          </div>
        </Content>
      </Container>
    </>
  );
};

export { ForgotPassword };
