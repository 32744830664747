import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns/esm';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { URLPath } from '@components/layouts/UrlPath';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IFinancial } from './interfaces';

export const SharedListFinancial: FC = () => {
  const [financing, setFinancing] = useState<IFinancial[]>();

  const { push } = useHistory();

  useEffect(() => {
    async function loadFinancial() {
      const { data } = await api.get<any[]>(`/financing/reports/clients`, {
        params: {
          relations: ['client'],
        },
      });

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IFinancial>(item);
      });

      setFinancing(parsedData);
    }

    loadFinancial();
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Tipo de operação',
        selector: 'relation',
      },
      {
        name: 'Valor da operação',
        selector: 'operationValue',
      },
      {
        name: 'Data da operação',
        selector: 'operationDate',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleGoToFinancingDetails = useCallback(
    (financingId: string) => {
      push(`/shared/financial/details/${financingId}`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!financing) {
      return [];
    }

    const data = financing.map(item => {
      return {
        clientId: item.clientId,
        relation: item.relation,
        operationDate: (
          <Badge type="success">
            {format(new Date(item.operationDate), 'dd/MM/yyyy HH:mm:ss')}
          </Badge>
        ),
        operationValue: new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(item.operationValue),
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="info"
              onClick={() => handleGoToFinancingDetails(item.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [financing, handleGoToFinancingDetails]);

  return (
    <>
      {!financing ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Operações', 'Operações Geradas']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Operações gerados na plataforma por você</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
