import { FC, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { FiDownload, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { BiMoney } from 'react-icons/bi';
import { FaFileContract } from 'react-icons/fa';
import exportFromJSON from 'export-from-json';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import QRCode from 'react-qr-code';
import { Canvg } from 'canvg';

import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';
import { Modal } from '@components/elements/Modal';
import { AuthModal } from '@components/bank/layouts/AuthModal';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import { bankApi } from '@services/bankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { QRWrapper } from '@pages/private/shared/Bwallet/Pix/GeneratePixQRCode/styles';

import { IInvestment } from './interfaces';
import { TableButtonContainer } from './styles';

const ListInvestments: FC = () => {
  const { addToast } = useToast();
  const svgRef = useRef<any>(null);
  const { push } = useHistory();
  const { user } = useAuth();

  const [investments, setInvestments] = useState<IInvestment[]>();
  const [modalVisible, setModalVisible] = useState(false);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    async function loadInvestments() {
      const { data } = await api.get<any[]>('/investments/client', {
        params: {
          relations: ['investment_percentage'],
        },
      });

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IInvestment>(item);
      });

      setInvestments(parsedData);
    }

    const timer = setTimeout(() => loadInvestments(), 1500);

    return () => clearInterval(timer);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Duração',
        selector: 'duration',
      },
      {
        name: 'Percentual',
        selector: 'percentage',
      },
      {
        name: 'Valor',
        selector: 'value',
      },
      {
        name: 'Data início',
        selector: 'dateInit',
      },
      {
        name: 'Data fim',
        selector: 'dateEnd',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleGeneratePix = useCallback(
    async (amount: number) => {
      try {
        const { data } = await bankApi.post('/pix/qr-codes/immediate', {
          amount,
          comments: 'Cobrança referente ao Bbank Invest. Pagar em até 4 horas',
          title: `Cobrança Bbank Invest para usuário: ${user.name}`,
          userId: 'e9f5a6e5-ee84-41ae-a141-28c754989ea8',
          expiration: 14400,
        });

        addToast({
          title: 'Cobrança gerada com sucesso!',
          type: 'success',
          message: 'Este QR code irá expirar em 4 horas',
        });

        setModalVisible(true);
        setQrCode(data.emv);
      } catch (err) {
        addToast({
          title: 'Ops...',
          type: 'info',
          message:
            'Não foi possível completar essa ação no momento. Tente novamente mais tarde!',
        });
      }
    },
    [user, addToast],
  );

  const tableData = useMemo(() => {
    if (!investments) {
      return [];
    }

    const data = investments.map(investment => ({
      id: investment.id,
      search: user.name,
      duration: `${investment.investmentPercentage.period} meses`,
      percentage: `${investment.investmentPercentage.percentage}%`,
      value: new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(investment.investmentPercentage.value * investment.quota),
      dateInit: format(new Date(investment.dateInit), 'dd/MM/yyyy'),
      dateEnd: format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
      actions: (
        <TableButtonContainer>
          <TableButton
            icon={BiMoney}
            styleType="success"
            title="Pagar"
            onClick={() =>
              handleGeneratePix(investment.investmentPercentage.value)
            }
          />
          <TableButton icon={BiMoney} styleType="warning" title="Status" />
          <TableButton
            icon={FaFileContract}
            styleType="info"
            title="Gerar Contrato"
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [investments, user, handleGeneratePix]);

  const handlePushToAddPage = useCallback(() => {
    push('/shared/bbank-invest/investments/new');
  }, [push]);

  const handleDownloadAsPdf = useCallback(async ({ visible }) => {
    const canvasElement = document.querySelector('canvas') as HTMLCanvasElement;
    const ctx = canvasElement.getContext('2d') as CanvasRenderingContext2D;

    const loaded = await Canvg.from(ctx, svgRef.current?.outerHTML);

    if (!loaded) {
      return;
    }

    await loaded.render();

    const img = canvasElement.toDataURL('image/png');

    const link = document.createElement('a');

    link.setAttribute('href', img);
    link.setAttribute('download', 'qr-code.png');

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    setModalVisible(visible);
    setQrCode(oldState => (!visible ? '' : oldState));
  }, []);

  const handleExportData = useCallback(() => {
    if (!investments) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const investmentsLength = investments.length;

    const worksheetData = Array.from(Array(investmentsLength).keys()).map(
      item => {
        const investment = investments[item];

        const worksheetLines = {
          Recorrência: numberFormat(investment.investmentPercentage.income),
          Porcentagem: `${investment.investmentPercentage.percentage}%`,
          Período: `${investment.investmentPercentage.period} meses`,
          Valor: numberFormat(investment.investmentPercentage.value),
          'Data de início': format(new Date(investment.dateInit), 'dd/MM/yyyy'),
          'Data final': format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
          'Nome do cliente': investment.client.name,
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'dados_investimentos',
    });
  }, [investments]);

  return (
    <>
      <AuthModal />

      {!investments ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Invest', 'Investimentos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Ver todos os investimentos feitos</h1>

                <Dropdown
                  styles={{
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                    textColor: '#fff',
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handlePushToAddPage}>
                      <FiPlusCircle />
                      Novo investimento
                    </button>
                  </li>

                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}

      <Modal
        visible={modalVisible}
        onOkClick={handleDownloadAsPdf}
        okButtonText="Fazer download da imagem"
        headerText="Código QR"
        hide={{ closeButton: true, xButton: false }}
        hideButton
        onDismiss={({ visible }) => {
          setModalVisible(visible);
          setQrCode('');
        }}
      >
        <QRWrapper>
          <QRCode
            ref={svgRef}
            size={256}
            style={{ height: 295, width: 295 }}
            value={qrCode}
            viewBox="0 0 256 256"
          />
        </QRWrapper>
        <canvas id="canvas" style={{ display: 'none' }} />
      </Modal>
    </>
  );
};

export { ListInvestments };
