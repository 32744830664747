export function consoleLog(
  payload: any[] | any,
  type: 'log' | 'warn' | 'error' = 'log',
): void {
  const logFunction = console[type];
  const appProduction = process.env.REACT_APP_PRODUCTION === 'true';

  const is = Array.isArray(payload);

  if (!appProduction) {
    if (is) {
      logFunction(...payload);
    } else {
      logFunction(payload);
    }
  }
}
