/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useRef,
  FC,
  useState,
  useEffect,
  FocusEvent,
  useCallback,
} from 'react';
import InputCurrencyComponents from 'react-currency-input-field';
import { useField } from '@unform/core';

import { Tooltip } from '@components/elements/Tooltip';

import { IInputCurrency } from './interfaces';
import { Container } from './styles';

const InputCurrency: FC<IInputCurrency> = ({
  name,
  icon: Icon,
  prefix = 'R$ ',
  ...rest
}) => {
  const inputCurrenyRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [isFilled, setIsFilled] = useState(!!inputCurrenyRef.current?.value);
  const [isFocused, setIsFocused] = useState(!!rest.autoFocus);
  const [fieldValue, setFieldValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputCurrenyRef.current,
      getValue: (ref: any) => {
        const { value } = ref;

        if (value) {
          return value
            .replaceAll('.', '')
            .replaceAll(',', '.')
            .replace(prefix, '');
        }
      },
      setValue: (_, value: string) => {
        const parsedValue = String(value).replace('.', ',');

        setFieldValue(parsedValue);
      },
      clearValue: () => {
        setFieldValue('');
      },
    });

    if (inputCurrenyRef.current) {
      const parsedValue = String(fieldValue).replace('.', ',');

      inputCurrenyRef.current.value = parsedValue;

      setFieldValue(parsedValue);
    }
  }, [registerField, fieldName, fieldValue, prefix]);

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (rest.onFocus) {
        rest.onFocus(e);
      }
    },
    [rest],
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (rest.onBlur) {
        rest.onBlur(e);
      }

      setIsFilled(!!inputCurrenyRef.current?.value);
      setIsFocused(false);
    },
    [rest],
  );

  return (
    <Container
      isFilled={isFilled}
      isFocused={isFocused}
      hasErrors={!!error}
      isReadOnly={rest.readOnly}
    >
      {Icon && <Icon size={20} />}
      <InputCurrencyComponents
        {...rest}
        onValueChange={value => setFieldValue(value || 0)}
        ref={inputCurrenyRef}
        name={name}
        defaultValue={defaultValue}
        value={fieldValue}
        onFocus={handleOnFocus}
        prefix={prefix}
        onBlur={handleOnBlur}
      />
      {!!error && <Tooltip type="error">{error}</Tooltip>}
    </Container>
  );
};

export { InputCurrency };
