/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FC,
  ComponentType,
  useState,
  useCallback,
  useRef,
  useEffect,
  FocusEvent,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container } from './styles';
import { Tooltip } from '../../Tooltip';

interface IInputProps extends Omit<InputProps, 'maxLength'> {
  icon?: ComponentType<IconBaseProps>;
  noUnmask?: boolean;
  name: string;
}

const InputMask: FC<IInputProps> = ({
  name,
  icon: Icon,
  style,
  noUnmask,
  disabled,
  onBlur,
  onFocus,
  readOnly,
  defaultValue: forcedValue,
  mask: maskProps,
  ...rest
}) => {
  const { fieldName, registerField, error, defaultValue } = useField(name);

  const inputRef = useRef(null);
  const [isFocused, setIsFosused] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [mask, setMask] = useState(maskProps);

  useEffect(() => {
    setMask(maskProps);
  }, [maskProps]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: any) {
        const { value } = ref;

        if (noUnmask) {
          return value as string;
        }

        return value.replace(/[^0-9]/g, '') as string;
      },
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
        setIsFilled(false);
      },
    });
  }, [fieldName, registerField, noUnmask]);

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setIsFosused(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }

      const ref = inputRef.current as any;

      setIsFilled(!!ref.value);
      setIsFosused(false);
    },
    [onBlur],
  );

  useEffect(() => {
    const ref = inputRef.current as any;

    setIsFilled(!!ref.value);
  }, []);

  return (
    <Container
      isFocused={isFocused}
      isFilled={isFilled}
      hasErrors={!!error}
      style={style}
      isDisabled={disabled}
      isReadOnly={readOnly}
    >
      {Icon && <Icon size={20} />}
      <ReactInputMask
        {...rest}
        mask={mask}
        name={name}
        className={!!noUnmask === true ? '' : 'input-masked'}
        ref={inputRef}
        maskChar=""
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        readOnly={readOnly}
        disabled={disabled}
        defaultValue={!forcedValue ? defaultValue : forcedValue}
      />
      {!!error && <Tooltip type="error">{error}</Tooltip>}
    </Container>
  );
};

export { InputMask };
