/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    title: Yup.string().required('Preencha este campo!'),
    amount: Yup.number()
      .transform((va, ov) => {
        if (ov === '') {
          return undefined;
        }

        return va;
      })
      .typeError('Insira um número válido!')
      .min(0.01, 'O valor deve ser maior que R$ 0,00!'),
    comments: Yup.string(),
  });

  await shape.validate(data, { abortEarly: false });
};
