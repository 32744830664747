import * as Yup from 'yup';

import { IForm } from './interfaces';

export const validateFields = async (data: IForm) => {
  try {
    const cleanedValue = data.value
      .toString()
      .replace(/[^\d.]/g, '')
      .replace(/^0+(\d)/, '$1')
      .replace(/\.$/, '')
      .replace(/%$/, '');

    const shape = Yup.object().shape({
      type: Yup.string().required('Preencha este campo!'),
      product: Yup.string().required('Preencha este campo!'),
      value: Yup.number()
        .typeError('Preencha um número!')
        .required('Preencha este campo!'),
    });

    const validatedData = await shape.validate(
      { ...data, value: cleanedValue },
      {
        abortEarly: false,
      },
    );

    return validatedData;
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      throw new Yup.ValidationError(error, data, 'validateFields');
    }
    throw error;
  }
};
