import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiDownload,
  FiEye,
  FiMoreHorizontal,
  FiPlusCircle,
} from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import exportFromJson from 'export-from-json';
import { format } from 'date-fns';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { maskHandler } from '@helpers/maskHandler';

import {
  IConsigned,
  IClient,
  IConsignedCompany,
  IStatusParsedObject,
} from './interfaces';

export const ListConsigned: FC = () => {
  const { push } = useHistory();

  const [consignedList, setConsignedList] = useState<IConsigned[]>();

  useEffect(() => {
    async function loadConsigned() {
      const { data } = await api.get<IConsigned[]>('/consigned', {
        params: {
          relations: [
            'client',
            'consigned_company',
            'consigned_files',
            'indication',
          ],
        },
      });

      const parsedData = data.map(consigned => {
        const parsedConsigned = parseObjectPropertiesToCamelCase<IConsigned>(
          consigned,
        );
        const { consignedCompany, client } = parsedConsigned;

        const parsedClient = parseObjectPropertiesToCamelCase<IClient>(client);
        const parsedConsignedCompany = parseObjectPropertiesToCamelCase<IConsignedCompany>(
          consignedCompany,
        );

        parsedConsigned.client = parsedClient;
        parsedConsigned.consignedCompany = parsedConsignedCompany;

        return parsedConsigned;
      });

      setConsignedList(parsedData);
    }

    const timer = setTimeout(() => loadConsigned(), 1500);

    return () => clearInterval(timer);
  }, []);

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pending: {
        text: 'Pendente',
        badgeType: 'warning',
      },
      approved: {
        text: 'Aprovado',
        badgeType: 'success',
      },
      pre_approved: {
        text: 'Pre aprovado',
        badgeType: 'info',
      },
      in_analysis: {
        text: 'Em análise',
        badgeType: 'info',
      },
      denied: {
        text: 'Negado',
        badgeType: 'danger',
      },
      contract_pending: {
        text: 'Contrato pendente',
        badgeType: 'info',
      },
    };
  }, []);

  const handleGoToDetailsPage = useCallback(
    (consignedId: string) => {
      push(`/cred-cash/consigned/${consignedId}/details`);
    },
    [push],
  );

  const handleGoToFilesPage = useCallback(
    (consignedId: string) => {
      push(`/cred-cash/consigned/${consignedId}/files`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!consignedList) {
      return [];
    }

    const data = consignedList.map(consigned => ({
      id: consigned.id,
      companyName: consigned.consignedCompany.companyName,
      search: consigned.client.name,
      status: (
        <Badge type={statusParsedObject[consigned.status].badgeType}>
          {statusParsedObject[consigned.status].text}
        </Badge>
      ),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="info"
            icon={FiEye}
            onClick={() => handleGoToDetailsPage(consigned.id)}
            title="Ver detalhes"
          />
          <TableButton
            styleType="info"
            icon={FiDownload}
            onClick={() => handleGoToFilesPage(consigned.id)}
            disabled={consigned.consignedFiles.length === 0}
            title={
              consigned.consignedFiles.length === 0
                ? 'Arquivos indisponíveis'
                : 'Ver arquivos'
            }
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [
    consignedList,
    statusParsedObject,
    handleGoToDetailsPage,
    handleGoToFilesPage,
  ]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da empresa',
        selector: 'companyName',
      },
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Situação',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleExportToExcel = useCallback(() => {
    if (!consignedList) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const consignedListLength = consignedList.length;

    const worksheetData = Array.from(Array(consignedListLength).keys()).map(
      number => {
        const consigned = consignedList[number];
        const { phone } = consigned;
        let parsedPhone = '';

        if (phone.length === 11) {
          parsedPhone = maskHandler({ mask: 'cellphone', payload: phone });
        } else {
          parsedPhone = maskHandler({ mask: 'phone', payload: phone });
        }

        const worksheetLine = {
          'Banco com código': consigned.bankWithCode,
          Agência: consigned.bankAgency,
          Conta: consigned.bankAccount,
          'Renda comprovada': numberFormat(consigned.provenIcome),
          'Telefone para contato': parsedPhone,
          'Valor do crédito solicitado': numberFormat(consigned.creditValue),
          'Nome do cliente': consigned.client.name,
          'Data de aniversário do cliente': format(
            new Date(consigned.client.birthdate),
            'dd/MM/yyyy',
          ),
          'Telefone do cliente': maskHandler({
            mask: 'phone',
            payload: consigned.client.phone,
          }),
          'Celular do cliente': maskHandler({
            mask: 'cellphone',
            payload: consigned.client.cellphone,
          }),
          'Código postal do endreço do cliente': maskHandler({
            mask: 'cep',
            payload: consigned.client.zipcode,
          }),
          'Endereço de moradia do cliente': consigned.client.address,
          'Cidade de moradia do cliente': consigned.client.city,
          'Estado de moradia do cliente': consigned.client.state,
          'Nome da indicação': consigned.indication.name,
          'Nome da empresa responsável': consigned.consignedCompany.companyName,
          Status: statusParsedObject[consigned.status].text,
        };

        return worksheetLine;
      },
    );

    exportFromJson({
      data: worksheetData,
      fileName: 'consignado_privado_dados',
      exportType: 'xls',
    });
  }, [consignedList, statusParsedObject]);

  return (
    <>
      {!consignedList ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Lista']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista dos créditos consignados</h1>

                <Dropdown
                  styles={{
                    textColor: '#fff',
                    togglerBackground: `${({ theme }: any) =>
                      theme.colors.secondary}`,
                  }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handleExportToExcel}>
                      <FiDownload />
                      Exportar para Excel
                    </button>
                  </li>

                  <li>
                    <Link to="/cred-cash/consigned/new">
                      <FiPlusCircle />
                      Adicionar consignado
                    </Link>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
