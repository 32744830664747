import { Form } from '@unform/web';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiSave } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Select } from '@components/elements/Form/Select';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { URLPath } from '@components/layouts/UrlPath';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputMask } from '@components/elements/Form/InputMask';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import {
  IConsigned,
  IParams,
  IStatusParsedObject,
  IFormData,
} from './interfaces';
import { banksOptions } from './selectOptions';
import { consignedFormValidation } from './validations';

export const ConsignedDetails: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();

  const [consigned, setConsigned] = useState<IConsigned>();

  useEffect(() => {
    async function loadConsigned() {
      const { data } = await api.get(`/consigned/${params.consignedId}`, {
        params: {
          relations: ['client', 'consigned_company', 'indication'],
        },
      });

      setConsigned(data);
    }

    const timer = setTimeout(() => loadConsigned(), 1500);

    return () => clearInterval(timer);
  }, [params.consignedId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        let parsedData = data;

        await consignedFormValidation(parsedData);

        delete parsedData.consigned_company;
        delete parsedData.indication;
        delete parsedData.client;

        const valuesWithoutMask = removeInputMask();

        parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, parsedData);

        const { data: updatedConsigned } = await api.put(
          `/consigned/${params.consignedId}`,
          parseObjectPropertiesToCamelCase(parsedData),
        );

        setConsigned(updatedConsigned);
        addToast({
          title: 'Muito bom!',
          message: 'As alterações foram salvas com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const validationErrors = getValidationErrors(err);

          formRef.current?.setErrors(validationErrors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'info',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, params.consignedId],
  );

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pending: {
        badgeType: 'warning',
        text: 'Pendente',
      },
      approved: {
        badgeType: 'success',
        text: 'Aprovado',
      },
      pre_approved: {
        badgeType: 'info',
        text: 'Pré aprovado',
      },
      in_analysis: {
        badgeType: 'warning',
        text: 'Em análise',
      },
      denied: {
        badgeType: 'danger',
        text: 'Negado',
      },
      contract_pending: {
        badgeType: 'info',
        text: 'Contrato pendente',
      },
    };
  }, []);

  return (
    <>
      {!consigned ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do consignado selecionado</h1>

                <Badge type={statusParsedObject[consigned.status].badgeType}>
                  {statusParsedObject[consigned.status].text}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  initialData={consigned}
                  ref={formRef}
                >
                  <FormRow separator>
                    <h1>Indicação e cliente</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do cliente</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome da indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa responsável</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome da empresa</label>
                      <Input name="consigned_company.company_name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Documento da empresa</label>
                      <InputMask
                        name="consigned_company.company_document"
                        mask="99.999.999/9999-99"
                        readOnly
                        noUnmask
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados bancários</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Select
                        name="bank_with_code"
                        options={banksOptions}
                        readonly={consigned.status !== 'pending'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Agência</label>
                      <Input
                        name="bank_agency"
                        maxLength={30}
                        readOnly={consigned.status !== 'pending'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Conta</label>
                      <Input
                        name="bank_account"
                        maxLength={255}
                        readOnly={consigned.status !== 'pending'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask
                        name="phone"
                        mask="(99) 99999-9999"
                        placeholder="(00) 00000-0000"
                        readOnly={consigned.status !== 'pending'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Renda comprovada</label>
                      <InputCurrency
                        name="proven_icome"
                        placeholder="R$"
                        readOnly={consigned.status !== 'pending'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do crédito</label>
                      <InputCurrency
                        name="credit_value"
                        placeholder="R$"
                        readOnly={consigned.status !== 'pending'}
                      />
                    </InputGroup>
                  </FormRow>

                  {consigned.status === 'pending' && (
                    <FormRow buttonWrapper>
                      <Button styleType="success" icon={FiSave}>
                        Salvar alterações
                      </Button>
                    </FormRow>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
