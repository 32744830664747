import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const validation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    names: Yup.array().of(Yup.string()),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
