import { animated } from 'react-spring';
import styled, { css } from 'styled-components';

import dotIcon from '@assets/svg/dot-icon.svg';

interface IContainerProps {
  hasLink: boolean;
}

const contentHasLink = css`
  height: auto;

  &:hover {
    background-color: #f3f6f9;

    > a > div span,
    > a > svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  > a {
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;

    text-decoration: none;
    color: inherit;

    > div {
      flex: 1;

      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        font-size: 1rem;
        color: #a2a2a2;

        padding: 12px 0;
      }
    }

    > svg {
      color: #ccc;
      transition: color 0.2s, transform 0.3s;
    }

    > svg:nth-child(1) {
      margin-right: 1.15rem;
      transition: color 0.2s;
    }
  }
`;

const contentDoesntHasLink = css`
  &:hover {
    background-color: #f3f6f9;

    > div span,
    > svg {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  > div {
    flex: 1;

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-size: 1rem;
      color: #a2a2a2;
    }
  }

  > svg {
    color: #ccc;
    transition: color 0.2s, transform 0.3s;
  }

  > svg:nth-child(1) {
    margin-right: 1.15rem;
    transition: color 0.2s;
  }
`;

export const Container = styled.li<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 45px;

  padding: 0 25px;

  position: relative;

  transition: background-color 0.2s;

  ${props => (props.hasLink ? contentHasLink : contentDoesntHasLink)}
`;

export const Dropdown = styled(animated.div)`
  list-style: none;

  width: 100%;

  font-size: 1.15rem;
  color: #a2a2a2;

  overflow: hidden;

  transform-origin: top;

  ul > li {
    position: relative;

    transition: color 0.2s, background-color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};

      background-color: #f3f6f9;
    }

    a {
      display: block;

      width: 100%;
      height: 100%;

      color: inherit;
      text-decoration: inherit;

      padding: 10px 30px 10px 40px;

      &:hover {
        color: inherit;
        text-decoration: inherit;
      }

      &::after {
        content: '';

        width: 20px;
        height: 20px;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;

        background-image: url(${dotIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
`;
