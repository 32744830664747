import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns/esm';
import { useRouteMatch } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Input } from '@components/elements/Form/Input';
import { Badge } from '@components/elements/Badge';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IFinancingReport, IParams } from './interfaces';

const FinancialDetails: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();

  const [financingReport, setFinancingReport] = useState<IFinancingReport>();

  useEffect(() => {
    async function loadFinancingReport() {
      const { data } = await api.get(
        `/financing/reports/${params.financingReportId}`,
        {
          params: {
            relations: ['client'],
          },
        },
      );

      const parsedData = parseObjectPropertiesToCamelCase<IFinancingReport>(
        data,
      );

      setFinancingReport(parsedData);
    }

    const timer = setTimeout(() => loadFinancingReport(), 1000);

    return () => clearInterval(timer);
  }, [params]);

  const handleFormSubmit = useCallback(() => {
    // CODE
  }, []);

  return (
    <>
      {!financingReport ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Financeiro', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes da operação</h1>
              </CardHeader>

              <CardContent>
                <Form
                  ref={formRef}
                  onSubmit={handleFormSubmit}
                  initialData={financingReport}
                >
                  <FormRow separator>
                    <h1>Informações básicas sobre o lançamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cliente relacionado</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo de operação</label>
                      <Input name="relation" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor da operação</label>
                      <InputCurrency name="operationValue" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Data da operação</label>
                      <Input
                        name="operationDate"
                        defaultValue={format(
                          new Date(financingReport.operationDate),
                          'dd/MM/yyyy HH:mm:ss',
                        )}
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Informações adicionais</h1>
                  </FormRow>

                  {financingReport.additionalInfo.length === 0 && (
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        paddingTop: 10,
                      }}
                    >
                      <Badge type="warning">
                        Não há informações adicionais
                      </Badge>
                    </div>
                  )}

                  {financingReport.additionalInfo.length > 0 &&
                    financingReport.additionalInfo.map(
                      (additionalInfo, index) => (
                        <FormRow key={additionalInfo.id}>
                          <InputGroup>
                            <label>{additionalInfo.relation}</label>
                            <Input
                              name={`additionalInfo[${index}].value`}
                              readOnly
                            />
                          </InputGroup>
                        </FormRow>
                      ),
                    )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FinancialDetails };
