import styled from 'styled-components';

import { CardHeader as CardHeaderComponent } from '@components/layouts/Card';

export const CardHeader = styled(CardHeaderComponent)`
  > div {
    display: flex;
    align-items: center;

    gap: 8px;

    > div {
      display: flex;
      align-items: center;

      gap: 4px;

      > label {
        font-size: 16px;
      }
    }
  }
`;
