import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';
import { IZipcodeResponse } from '@services/interfaces';
import viaCepApi from '@services/viaCepApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { toCamelCase } from '@helpers/toCamelCase';

import {
  statesOptions,
  paymentFormOptions,
  sellingTypeOptions,
  businessMethodOptions,
  paymentPortionsOptions,
} from './selectOptions';
import { IParams, IMerchant, IFormData } from './interfaces';
import { formValidation } from './validations';

const EditMerchant: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [merchant, setMerchant] = useState<IMerchant>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [formRowVisibility, setFormRowVisibility] = useState(false);

  useEffect(() => {
    async function loadMerchant() {
      const { data } = await api.get(
        `/bbank-pay/merchants/${params.merchantId}`,
      );

      if (data.franchisee_id !== user.id) {
        addToast({
          title: 'Não autorizado!',
          type: 'error',
          message: 'Este estabelecimento não tem a sua indicação!',
        });
        setMerchant(undefined);
        return;
      }

      setFormRowVisibility(data.selling_type === 'sells_with_cashback');
      setMerchant(data);
    }

    const timer = setTimeout(() => {
      loadMerchant();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.merchantId, user.id, addToast]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const formData: IFormData = {};

        Object.entries(data).forEach(([key, value]) => {
          const parsedKey = toCamelCase(key);

          formData[parsedKey] = value;
        });

        const valuesWithoutMasks = removeInputMask();

        const parsedValuesWithoutMask = valuesWithoutMasks.map(
          ({ name, value }) => {
            const parsedName = toCamelCase(name);

            return { name: parsedName, value };
          },
        );

        parsedValuesWithoutMask.forEach(item => {
          formData[item.name] = item.value;
        });

        delete formData.client;

        const { data: merchantUpdated } = await api.put(
          `/bbank-pay/merchants/${params.merchantId}`,
          formData,
        );

        addToast({
          title: 'Dados atualizados com sucesso!',
          type: 'success',
        });

        setMerchant(merchantUpdated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, params.merchantId],
  );

  const handleFormRowVisibility = useCallback((opt: OptionTypeBase | null) => {
    if (!opt) {
      return;
    }

    const { value } = opt;

    if (value === 'sells_with_cashback') {
      setFormRowVisibility(true);
    } else {
      setFormRowVisibility(false);
    }
  }, []);

  const handleChangeOfPrice = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const { value } = option;

    const ref = formRef.current?.getFieldRef('payment_value');

    switch (value) {
      case 'accession':
        ref.value = 198;
        break;

      case 'sells_a930':
        ref.value = 676;
        break;

      default:
        ref.value = 556;
        break;
    }
  }, []);

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const cityInputRef = formRef.current?.getFieldRef('city');
        const addressInputRef = formRef.current?.getFieldRef('address');
        const neighborhoodInputRef = formRef.current?.getFieldRef(
          'neighborhood',
        );

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          neighborhoodInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro, bairro } = data;

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;
        neighborhoodInputRef.value = bairro;

        const stateInputRef = formRef.current?.getFieldRef('state');

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  return (
    <>
      {!merchant ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['BbankPay', 'Estabelecimentos', 'Editar/Visualizar']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimento</h1>

                {merchant.status === 'ativo' && (
                  <Badge type="success">Ativo</Badge>
                )}

                {merchant.status === 'parado' && (
                  <Badge type="danger">Parado</Badge>
                )}

                {merchant.status === 'pendente' && (
                  <Badge type="warning">Pendente</Badge>
                )}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={merchant}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Cliente selecionado</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Razão social</label>
                      <Input
                        name="social_reason"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome fantasia</label>
                      <Input
                        name="name_fantasy"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>CNPJ</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        placeholder="00.000.000/0000-00"
                        name="cnpj"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de abertura</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="opening_date"
                        defaultValue={format(
                          new Date(merchant.opening_date),
                          'dd/MM/yyyy',
                        )}
                        readOnly={merchant.status !== 'pendente'}
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone (com DDD)</label>
                      <InputMask
                        mask="(99) 9999-9999"
                        placeholder="(00) 0000-0000"
                        name="phone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular (com DDD)</label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        placeholder="(00) 0 0000-0000"
                        name="cellphone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número do SN</label>
                      <Input
                        name="infinity_id"
                        placeholder="acc51515"
                        upperCase={false}
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do responsável</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do responsável</label>
                      <Input
                        name="responsible_name"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Email do responsável</label>
                      <Input
                        name="responsible_email"
                        upperCase={false}
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF do responsável</label>
                      <InputMask
                        mask="999.999.999-99"
                        placeholder="000.000.000-00"
                        name="responsible_cpf"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de nascimento do responsável</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="responsible_birthday"
                        defaultValue={format(
                          new Date(merchant.responsible_birthday),
                          'dd/MM/yyyy',
                        )}
                        readOnly={merchant.status !== 'pendente'}
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular do responsável (com DDD)</label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        placeholder="(00) 0 0000-0000"
                        name="responsible_cellphone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <InputMask
                        mask="99999-999"
                        placeholder="00000-000"
                        name="zipcode"
                        readOnly={merchant.status !== 'pendente'}
                        onBlur={e => handleFindZipcode(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input
                        name="address"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Bairro</label>
                      <Input
                        name="neighborhood"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input
                        name="city"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Select
                        name="state"
                        options={statesOptions}
                        readonly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento (deixe vazio caso não exista)</label>
                      <Input
                        name="complement"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número (deixe vazio caso não exista)</label>
                      <InputMask
                        mask="9999999999"
                        noUnmask
                        name="address_number"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contrato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modelo de negócio</label>
                      <Select
                        name="business_method"
                        options={businessMethodOptions}
                        readonly={merchant.status !== 'pendente'}
                        onChange={handleChangeOfPrice}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de venda</label>
                      <Select
                        name="selling_type"
                        options={sellingTypeOptions}
                        readonly={merchant.status !== 'pendente'}
                        onChange={handleFormRowVisibility}
                      />
                    </InputGroup>
                  </FormRow>

                  {formRowVisibility && (
                    <FormRow>
                      <InputGroup>
                        <label>Porcentagem de cashback</label>
                        <Input
                          type="number"
                          name="cashback_percentage"
                          readOnly={merchant.status !== 'pendente'}
                        />
                      </InputGroup>
                    </FormRow>
                  )}

                  <FormRow separator>
                    <h1>Método de pagamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Preço a ser pago</label>
                      <Input name="payment_value" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Forma de pagamento</label>
                      <Select
                        name="payment_form"
                        options={paymentFormOptions}
                        readonly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Parcelas</label>
                      <Select
                        name="payment_portions"
                        options={paymentPortionsOptions}
                        readonly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  {merchant.status === 'pendente' && (
                    <FormRow buttonWrapper>
                      <Button
                        type="submit"
                        styleType="info"
                        icon={FiSave}
                        loading={loadingRequest}
                      >
                        Salvar alterações
                      </Button>
                    </FormRow>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditMerchant };
