import {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { format } from 'date-fns';
import { v4 } from 'uuid';
import Loader from 'react-loader-spinner';
import exportFromJSON from 'export-from-json';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';

import { useAuth } from '@hooks/auth';

import { getCardFlag } from '@helpers/getCardFlag';

import {
  Card,
  CardHeader,
  CardContent,
  DatePickerContainer,
  InputDate,
  LoaderContainer,
  ImgBrandContainer,
  ImgBrand,
} from './styles';
import { ITransaction } from './interfaces';

const POSTransactions: FC = () => {
  const { user } = useAuth();

  const [initialDate, setInitialDate] = useState(
    format(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd'),
  );
  const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [transactions, setTransactions] = useState<ITransaction[]>();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    setLoadingData(true);

    setTransactions([]);

    const timer = setTimeout(async () => {
      setLoadingData(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finalDate, user.id]);

  const handleInputDateBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>, type: 'date-init' | 'date-final') => {
      const { value } = e.target;

      if (type === 'date-final') {
        setFinalDate(value);
      } else {
        setInitialDate(value);
      }
    },
    [],
  );

  const handleExportToExcel = useCallback(() => {
    if (!transactions || transactions.length === 0) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const transactionsQuantity = transactions.length;

    const worksheetCells = Array.from(Array(transactionsQuantity).keys()).map(
      item => {
        const transaction = transactions[item];

        const worksheetCell = {
          Estabelecimento: transaction.establishment,
          Cartão: transaction.card,
          Bandeira: transaction.brand,
          Data: transaction.date,
          Serviço: transaction.service,
          'Valor cobrado': numberFormat(transaction.chargedValue),
          'Valor Base': numberFormat(transaction.baseValue),
        };

        return worksheetCell;
      },
    );

    exportFromJSON({
      data: worksheetCells,
      exportType: 'xls',
      fileName: 'extrato',
    });
  }, [transactions]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Comprador',
        selector: 'search',
      },
      {
        name: 'Banderia/Cartão',
        selector: 'flag',
      },
      {
        name: 'Serviço',
        selector: 'service',
      },
      {
        name: 'Data',
        selector: 'date',
      },
      {
        name: 'Valor base',
        selector: 'valueBase',
      },
      {
        name: 'Valor cobrado',
        selector: 'valueCharged',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    const data = transactions?.map(item => {
      const imgBrand = getCardFlag(item.brand);
      const id = v4();
      const numberFormat = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;

      return {
        id,
        search: item.establishment,
        flag: (
          <ImgBrandContainer>
            <ImgBrand src={imgBrand} alt={item.card} />
            {item.card}
          </ImgBrandContainer>
        ),
        service: item.service,
        date: format(new Date(item.date), 'dd/MM/yyyy'),
        valueBase: numberFormat(item.baseValue),
        valueCharged: numberFormat(item.chargedValue),
      };
    });

    return data || [];
  }, [transactions]);

  return (
    <>
      {!transactions ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Vendas', 'Suas transações']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Transações</h1>

                <DatePickerContainer>
                  <span>De</span>
                  <InputDate
                    type="date"
                    onBlur={e => handleInputDateBlur(e, 'date-init')}
                    defaultValue={initialDate}
                  />
                  <span>até</span>
                  <InputDate
                    type="date"
                    onBlur={e => handleInputDateBlur(e, 'date-final')}
                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                  />
                </DatePickerContainer>
              </CardHeader>

              <CardContent>
                {loadingData ? (
                  <LoaderContainer>
                    <Loader
                      type="TailSpin"
                      height={60}
                      width={60}
                      color="${({ theme }) => theme.colors.secondary}"
                    />
                  </LoaderContainer>
                ) : (
                  <>
                    <DataTable
                      columns={tableColumns}
                      data={tableData}
                      subHeaderComponent
                    />

                    <Button
                      type="button"
                      styleType="info"
                      onClick={handleExportToExcel}
                    >
                      Exportar para excel
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { POSTransactions };
