import { IOption } from '@utils/interfaces';

import { IClient } from './interfaces';

export const getClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(client => ({
    value: client.id,
    label: client.name,
  }));

  return options;
};
