import { FC, useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useAuth } from '@hooks/auth';

import { Route } from './Route';
import { adminRoutes } from './admin.routes';
import { clientRoutes } from './client.routes';
import { franchiseesRoutes } from './franchisee.routes';
import { sellerRoutes } from './seller.routes';
import { publicRoutes } from './public.routes';
import { sharedRoutes } from './shared.routes';

const Routes: FC = () => {
  const { push } = useHistory();
  const { user, loading } = useAuth();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (!loading) {
      if (user) {
        if (pathname !== '/') {
          push(pathname + search);
          return;
        }

        push(`/dashboard${search}`);
      }

      if (!user) push(pathname);
    }
  }, [user, push, pathname, loading, search]);

  return (
    <Switch>
      {publicRoutes}

      {clientRoutes}

      {user && (
        <>
          <Header />

          <Aside />

          <Main>
            <CardMenuContainer />

            {sharedRoutes}
            {sellerRoutes}
            {adminRoutes}
            {franchiseesRoutes}
          </Main>

          <Footer />
        </>
      )}

      <Route path="*" />
    </Switch>
  );
};

export { Routes };
