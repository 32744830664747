import { shade } from 'polished';
import styled from 'styled-components';

export const Header = styled.header`
  position: fixed;
  top: 0;

  width: 100%;
  height: 80px;

  display: flex;

  border-bottom: 1px solid #ebedf3;

  padding: 10px;

  background-color: #fff;

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 405px) {
      display: none;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1350px;
    margin: 0 auto;

    @media (max-width: 405px) {
      align-items: center;
      justify-content: center;
    }

    > div {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      h1,
      svg {
        color: #7e8299;
      }

      @media (max-width: 768px) {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;

    @media (max-width: 505px) {
      font-size: 18px;
    }
  }

  > button,
  > a {
    padding: 5px 10px;

    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;

    font-size: 16px;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${({ theme }) => shade(0.25, theme.colors.secondary)};
    }
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const Container = styled.main`
  margin-top: 80px;

  padding: 20px 10px;
`;

export const Content = styled.div`
  max-width: 1350px;
  margin: 0 auto;
`;
