import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Toast } from '@components/alerts/Toast';

import themes, { ThemeNames } from './styles/themes';
import GlobalStyles from './styles/global';
import { Routes } from './routes';
import { Providers } from './hooks';

const themeName =
  (process.env.REACT_APP_THEME as ThemeNames) || 'themePasstopay';
const theme = themes[themeName];

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Providers>
          <Routes />
          <Toast />
        </Providers>
      </BrowserRouter>
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default App;
