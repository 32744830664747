import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Button } from '@components/elements/Button';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

interface ICredit {
  id: string;
  cnpj: string;
  email: string;
  created_at: string;
  status: string;
  responsible_name?: string;
  owner: {
    document: string;
    email: string;
    name: string;
  };
}

interface ITableData {
  id: string;
  search: string;
  email: string;
  created_at: string;
  status: JSX.Element;
  actions: JSX.Element;
}

const ListFranchiseePendingCredits: FC = () => {
  const { push } = useHistory();
  const [credits, setCredits] = useState<Array<ICredit>>([]);

  useEffect(() => {
    async function loadCredits() {
      const { data } = await api.get('/indicated-credits/pending');

      setCredits(data.credits);
    }

    loadCredits();
  }, []);

  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'CPF/CNPJ',
        selector: 'search',
      },
      {
        name: 'Nome do responsável',
        selector: 'responsible_name',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Data da solicitação',
        selector: 'created_at',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo<Array<ITableData>>(() => {
    return credits.map(credit => {
      return {
        id: credit.id,
        created_at: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        email: credit.email || credit.owner.email,
        search: credit.cnpj || credit.owner.document,
        responsible_name: credit.responsible_name || credit.owner.name,
        actions: (
          <Button
            styleType="success"
            onClick={() => push(`/franchisees/cred-cash/view/${credit.id}`)}
          >
            Ver detalhes
          </Button>
        ),
        status: <Badge type="warning">{credit.status}</Badge>,
      };
    });
  }, [credits, push]);

  return (
    <>
      <Row>
        <URLPath paths={['Créditos', 'Pendentes']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Crédito pendentes</h1>
          </CardHeader>
          <CardContent>
            <DataTable
              columns={tableColumns}
              data={tableData}
              filterPlaceholder="Filtrar por CPF/CNPJ"
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { ListFranchiseePendingCredits };
