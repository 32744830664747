import { FC, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { OptionTypeBase } from 'react-select';
import { addMonths, format } from 'date-fns';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { FiDollarSign } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Select } from '@components/elements/Form/Select';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  IInvestmentPercentage,
  IClient,
  IFormData,
  IIndication,
} from './interfaces';
import { formValidation } from './validations';
import { getInvestmentPercentageOptions, periodOptions } from './selectOptions';

const NewInvestment: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const { search } = useLocation();

  const [clients, setClients] = useState<IClient>();
  const [dateInit] = useState(new Date().toLocaleDateString());
  const [dateEnd, setDateEnd] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [percentage, setPercentage] = useState<IInvestmentPercentage>();
  const [indications, setIndications] = useState<IIndication[]>([]);
  const [investmentPercentages, setInvestmentsPercentages] = useState<
    IInvestmentPercentage[]
  >();

  useEffect(() => {
    const percentageIdFromQuery = search.replace('?percentageId=', '');

    async function getData() {
      const { data: clientsResponse } = await api.get(`/users/me`);

      if (percentageIdFromQuery) {
        const { data: percentageResponse } = await api.get(
          `/investments/percentages/${percentageIdFromQuery}`,
          {
            params: {
              relations: ['investment'],
            },
          },
        );

        setInvestmentsPercentages([percentageResponse]);
        setPercentage(percentageResponse);
        setDateEnd(
          format(
            addMonths(new Date(), percentageResponse.period),
            'dd/MM/yyyy',
          ),
        );
      }

      if (user.role === 'master' || user.role === 'business') {
        const { data: indicationsResponse } = await api.get(
          `users-indicated/${user.id}`,
        );

        setIndications(indicationsResponse);
      }

      setClients(clientsResponse);
    }

    const timer = setTimeout(() => getData(), 1500);

    return () => clearInterval(timer);
  }, [search, user]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const { investmentPercentageId, clientId, quota } = data;

        const formData = {
          clientId,
          investmentPercentageId,
          dateInit,
          dateEnd,
          quota,
        };

        await api.post('/investments', formData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Seu investimento foi cadastrado foi computado com sucesso!',
        });

        formRef.current?.clearField('clientId');
        formRef.current?.clearField('dateEnd');
        formRef.current?.clearField('investmentPercentageId');
        formRef.current?.clearField('period');
        formRef.current?.clearField('percentage');
        formRef.current?.clearField('quota');
        formRef.current?.clearField('income');
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [dateEnd, dateInit, addToast],
  );

  // const clientOptions = useMemo(() => {
  //   if (!clients) {
  //     return [];
  //   }

  //   return getClientsOptions(clients);
  // }, [clients]);

  const investmentPercentageOptions = useMemo(() => {
    if (!investmentPercentages) {
      return [];
    }

    return getInvestmentPercentageOptions(investmentPercentages);
  }, [investmentPercentages]);

  const loadInvestmentPercentages = useCallback(
    async (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value: periodNumber } = option as { value: number };

      const { data } = await api.get(`/investments/percentages/period`, {
        params: {
          periodNumber,
        },
      });

      const valueToDateEnd = format(
        addMonths(new Date(), periodNumber),
        'dd/MM/yyyy',
      );

      setInvestmentsPercentages(data);
      setDateEnd(valueToDateEnd);
      setPercentage(undefined);
    },
    [],
  );

  const handleQuotaOnChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option || !percentage) return;

      const { value: quota } = option;

      const incomeFieldRef = formRef.current?.getFieldRef('income');
      const percentageFieldRef = formRef.current?.getFieldRef('percentage');
      const valueFieldRef = formRef.current?.getFieldRef(
        'investmentPercentageId',
      );

      const { value, percentage: selectedPercentage } = percentage;

      const quotaValue = quota * value;
      const newIncome = quotaValue * (selectedPercentage * 0.01);

      const numberFormat = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;

      incomeFieldRef.value = numberFormat(newIncome);
      percentageFieldRef.value = selectedPercentage;
      valueFieldRef.select.setValue({
        value: percentage.id,
        label: `${percentage.name} - ${numberFormat(quotaValue)}`,
      });
    },
    [percentage],
  );

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Invest', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Preencha abaixo para um novo investimento</h1>
              </CardHeader>

              <CardContent>
                <Form
                  ref={formRef}
                  initialData={{ dateInit }}
                  onSubmit={handleFormSubmit}
                >
                  {user.role === 'business' || user.role === 'master' ? (
                    <FormRow>
                      <InputGroup>
                        <label>Seus clientes</label>
                        <Select
                          name="clientId"
                          options={(() => {
                            const opts = [
                              { value: user.id, label: `${user.name} (Você)` },
                            ];

                            indications.forEach(item =>
                              opts.push({
                                value: item.id,
                                label: item.name,
                              }),
                            );

                            return opts;
                          })()}
                        />
                      </InputGroup>
                    </FormRow>
                  ) : (
                    <FormRow>
                      <InputGroup>
                        <label>Seu nome</label>
                        <Input name="name" readOnly />
                      </InputGroup>
                    </FormRow>
                  )}

                  <FormRow>
                    <InputGroup>
                      <label>Período</label>
                      <Select
                        name="period"
                        options={periodOptions}
                        onChange={loadInvestmentPercentages}
                        defaultValue={
                          percentage
                            ? {
                                label: `${percentage.period} meses`,
                                value: percentage.period,
                              }
                            : undefined
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor</label>
                      <Select
                        name="investmentPercentageId"
                        options={investmentPercentageOptions}
                        onChange={option => {
                          if (!option) return;

                          const findValue = investmentPercentages?.find(
                            item => item.id === option.value,
                          );

                          setPercentage(findValue);
                        }}
                        defaultValue={
                          percentage
                            ? {
                                value: percentage.id,
                                label: `${
                                  percentage.name
                                } - ${new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(percentage.value)}`,
                              }
                            : undefined
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cotas</label>
                      <Select
                        name="quota"
                        options={Array.from(Array(100).keys()).map(item => ({
                          value: item + 1,
                          label: item + 1,
                        }))}
                        onChange={handleQuotaOnChange}
                        isDisabled={!percentage}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Percentual</label>
                      <Input
                        name="percentage"
                        defaultValue={
                          percentage?.percentage
                            ? `${percentage?.percentage}%`
                            : 'Aguradando seleção...'
                        }
                        disabled
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Rendimento</label>
                      <Input
                        name="income"
                        defaultValue={(() => {
                          if (percentage?.income) {
                            const numberFormatter = new Intl.NumberFormat(
                              'pt-BR',
                              { currency: 'BRL', style: 'currency' },
                            ).format;
                            const parsesValue = numberFormatter(
                              percentage.income,
                            );

                            return parsesValue;
                          }

                          return 'Aguardando seleção...';
                        })()}
                        disabled
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data inicial</label>
                      <Input name="dateInit" defaultValue={dateInit} readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Data final</label>
                      <Input name="dateEnd" defaultValue={dateEnd} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiDollarSign}
                      loading={loadingRequest}
                    >
                      Investir
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewInvestment };
