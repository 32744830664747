import { IOption } from '@utils/interfaces';

import { IInvestmentPercentage, IClient } from './interfaces';

export const getInvestmentPercentageOptions = (
  data: IInvestmentPercentage[],
): IOption[] => {
  const options = data.map(percentage => ({
    value: percentage.id,
    label: new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(percentage.value),
  }));

  return options;
};

export const getClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(client => ({
    value: client.id,
    label: client.name,
  }));

  return options;
};

export const periodOptions: IOption[] = [
  { label: '6 meses', value: 6 },
  { label: '12 meses', value: 12 },
  { label: '18 meses', value: 18 },
  { label: '24 meses', value: 24 },
  { label: '36 meses', value: 36 },
  { label: '48 meses', value: 48 },
  { label: '60 meses', value: 60 },
  { label: '72 meses', value: 72 },
  { label: '84 meses', value: 84 },
  { label: '96 meses', value: 96 },
  { label: '108 meses', value: 108 },
  { label: '120 meses', value: 120 },
  { label: '132 meses', value: 132 },
  { label: '144 meses', value: 144 },
];
