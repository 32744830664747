import { isAfter } from 'date-fns';
import { addSeconds } from 'date-fns/esm';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { IoQrCodeOutline } from 'react-icons/io5';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { Dropdown } from '@components/elements/Dropdown';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import { useToast } from '@hooks/toast';
import useBank from '@hooks/bank';

import { bankApi } from '@services/bankApi';

import { IQRCode } from './interfaces';

export const QRCodes: FC = () => {
  const { addToast } = useToast();
  const { push } = useHistory();
  const { handleVerifyToken } = useBank();

  const [qrCodes, setQrCodes] = useState<IQRCode[]>();

  const getQrCodes = useCallback(async () => {
    const { data } = await bankApi.get('/pix/qr-codes/immediate');

    setQrCodes(data);
  }, []);

  useEffect(() => {
    handleVerifyToken().then(response => {
      if (response) {
        getQrCodes();
      }
    });

    const timer = setTimeout(() => getQrCodes(), 250);

    return () => clearInterval(timer);
  }, [getQrCodes, handleVerifyToken]);

  const handleNavigateToQrCodePage = useCallback(
    (payload: string) => {
      push(`/shared/bwallet/qr-code/${payload}`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!qrCodes) return [];

    const parsed = qrCodes.map(item => {
      const { expiration, created_at } = item;

      const expirationDate = addSeconds(new Date(created_at), expiration);
      const expired = isAfter(new Date(), expirationDate);

      return {
        id: item._id,
        search: item.title,
        emv: (
          <button
            type="button"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100px',
              fontWeight: 700,
            }}
            onClick={() => {
              navigator.clipboard.writeText(item.emv);
              addToast({
                title: 'Copiado!',
                type: 'info',
              });
            }}
          >
            {item.emv}
          </button>
        ),
        expired: (
          <Badge type={expired ? 'danger' : 'success'}>
            {expired ? 'Expirado' : 'Válido'}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              icon={IoQrCodeOutline}
              title="Gerar código!"
              onClick={() => handleNavigateToQrCodePage(item.emv)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return parsed;
  }, [qrCodes, addToast, handleNavigateToQrCodePage]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Identificador',
        selector: 'search',
      },
      {
        name: 'EMV',
        selector: 'emv',
      },
      {
        name: 'Expirado',
        selector: 'expired',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  return (
    <>
      <AuthModal onAuth={getQrCodes} />

      {!qrCodes ? (
        <LoadingPage />
      ) : (
        <>
          <URLPath
            paths={['Bwallet', 'QR Codes', 'QR Codes dinâmicos gerados']}
          />

          <Card>
            <CardHeader>
              <h1>QR Codes gerados</h1>

              <Dropdown
                styles={{
                  textColor: '#fff',
                  togglerBackground: `${({ theme }: any) =>
                    theme.colors.secondary}`,
                }}
                icon={FiMoreHorizontal}
              >
                <li>
                  <button
                    type="button"
                    onClick={() =>
                      push('/shared/bwallet/pix/qr-code/dynamic/new')
                    }
                  >
                    <FiPlusCircle />
                    Criar novo QR Code
                  </button>
                </li>
              </Dropdown>
            </CardHeader>

            <CardContent>
              <DataTable data={tableData} columns={tableColumns} />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
