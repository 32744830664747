import * as Yup from 'yup';

import { IForm } from './interfaces';

export async function validateFields(data: IForm): Promise<void> {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
}
