import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    password: Yup.string()
      .transform((va, ov) => {
        if (ov === '') {
          return undefined;
        }

        return va;
      })
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Precisa conter 8 caracteres, 1 letra maiúscula, minúscula, caractere especial e número!',
      ),
    confirmPassword: Yup.string().when({
      is: (_: string) => data.password !== '',
      then: Yup.string()
        .required('Preencha este campo!')
        .oneOf([Yup.ref('password')], 'As senhas não conferem!'),
      otherwise: Yup.string(),
    }),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
