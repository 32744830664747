import { FC } from 'react';

import { AsideProvider } from '../contexts/aside';
import { MenuProvider } from '../contexts/menu';
import { ToastProvider } from '../contexts/toast';
import { AuthProvider } from '../contexts/auth';
import { TermsOfUseProvider } from '../contexts/termsOfUse';
import { BankProvider } from '../contexts/bank';

const Providers: FC = ({ children }) => (
  <ToastProvider>
    <BankProvider>
      <AuthProvider>
        <TermsOfUseProvider>
          <AsideProvider>
            <MenuProvider>{children}</MenuProvider>
          </AsideProvider>
        </TermsOfUseProvider>
      </AuthProvider>
    </BankProvider>
  </ToastProvider>
);

export { Providers };
