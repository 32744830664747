import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

import { IParams, IConsignedFile } from './interfaces';

export const ListConsignedFiles: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [consignedFiles, setConsignedFiles] = useState<IConsignedFile[]>();

  useEffect(() => {
    async function loadConsignedFiles() {
      const { data } = await api.get(
        `/consigned/files/consigned/${params.consignedId}`,
      );

      setConsignedFiles(data);
    }

    const timer = setTimeout(() => loadConsignedFiles(), 1500);

    return () => clearInterval(timer);
  }, [params.consignedId]);

  const handleDownloadFile = useCallback((fileUrl: string) => {
    window.open(fileUrl, '_blank');
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Arquivo referente a',
        selector: 'search',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!consignedFiles) {
      return [];
    }

    const data = consignedFiles.map(file => ({
      id: file.id,
      search: file.title,
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="success"
            icon={FiDownload}
            onClick={() => handleDownloadFile(file.file_url)}
            title="Fazer o download"
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [consignedFiles, handleDownloadFile]);

  return (
    <>
      {!consignedFiles ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Arquivos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos disponíveis para o consignado selecionado</h1>

                <Badge
                  type={consignedFiles.length === 0 ? 'danger' : 'success'}
                >
                  {consignedFiles.length === 0
                    ? 'Nenhum arquivo foi encontrado'
                    : `Foram encontrados ${consignedFiles.length} arquivos`}
                </Badge>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
