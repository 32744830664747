import { useContextSelector } from 'use-context-selector';

import { BankContext, IBankContext } from '../contexts/bank';

export default function useBank(): IBankContext {
  const handleAuth = useContextSelector(
    BankContext,
    bankContext => bankContext.handleAuth,
  );
  const handleLogOut = useContextSelector(
    BankContext,
    bankContext => bankContext.handleLogOut,
  );
  const handleVerifyToken = useContextSelector(
    BankContext,
    bankContext => bankContext.handleVerifyToken,
  );

  return {
    handleAuth,
    handleLogOut,
    handleVerifyToken,
  };
}
