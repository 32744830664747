import { v4 } from 'uuid';
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { FiAlertCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { InputFile } from '@components/elements/Form/InputFile';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { validation } from './validations';
import { IFormData } from './interfaces';
import { URLPath, Card, CardHeader, CardContent } from './styles';

const CreateSliders: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleImagePreview = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }

      if (e.target.files.length === 0) {
        return;
      }

      const filesFromInput = e.target.files[0];

      const fileExtension = filesFromInput.name.split('.').reverse()[0];
      if (
        fileExtension !== 'png' &&
        fileExtension !== 'jpg' &&
        fileExtension !== 'jpeg'
      ) {
        addToast({
          type: 'error',
          title: 'Arquivo invalido!',
          message: 'São permitidos apenas arquivos de imagem (PNG, JPG, JPEG)',
        });
        e.target.files = null;
      }
    },
    [addToast],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await validation(data);

        const { files: filesFromData, names } = data;

        const formData = new FormData();

        formData.append('names[]', names);
        formData.append('files', filesFromData);

        await api.post('/galleries', formData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'As informações enviadas foram salvas com sucesso.',
        });
        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }
        }
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>Sliders</li>
          <li>{'>'}</li>
          <li>Nova Imagem</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova Imagem</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Nome da imagem</label>
                  <Input name="names[0]" />
                </InputGroup>
                <InputGroup>
                  <label>Insira um arquivo</label>
                  <InputFile name="files" onChange={handleImagePreview} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success" icon={FiSave}>
                  Salvar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { CreateSliders };
