import * as Yup from 'yup';

export const formValidation = async (data: {
  [key: string]: string;
}): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
    email: Yup.string().required('Preencha este campo!'),
    password: Yup.string()
      .required('Preencha este campo!')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Precisa conter 8 caracteres, 1 letra maiúscula, minúscula, caractere especial e número!',
      ),
    confirmPassword: Yup.string()
      .required('Preencha este campo!')
      .oneOf([Yup.ref('password')], 'As senhas não correspondem!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    cellphone: Yup.string().required('Preencha este campo!'),
    person_type: Yup.string().required('Preencha este campo!'),
    document: Yup.string().required('Preencha este campo!'),
    role: Yup.string().required('Selecione uma opção!'),
    status: Yup.string().required('Selecione uma opção!'),
    indication: Yup.string().when({
      is: (_: string) => data.role !== 'admin',
      then: Yup.string().required('Selecione uma indicação'),
    }),
    nickname: Yup.string().when({
      is: (_: string) =>
        data.role === 'master' ||
        data.role === 'business' ||
        data.role === 'seller',
      then: Yup.string().required('Preencha este campo!'),
    }),
    birthdate: Yup.string()
      .required('Preencha este campo!')
      .min(10, 'Insira uma data válida!')
      .max(10, 'Insira uma data válida!'),
    selfie: Yup.string(),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
