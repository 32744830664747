import {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  ChangeEvent,
} from 'react';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import {
  FiFlag,
  FiGift,
  FiHash,
  FiHome,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSave,
  FiSmartphone,
  FiSmile,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';
import { Alert } from '@components/alerts/Alert';
import { InputFile } from '@components/elements/Form/InputFile';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { IZipcode } from '@utils/interfaces';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { IMe, IFormData } from './interfaces';
import { statesOptions } from './selectOptions';
import { formValidation } from './validations';

export const CompleteMyInformation: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user, completedData, handleCompleteData } = useAuth();

  const [info, setInfo] = useState<IMe>();

  useEffect(() => {
    async function loadProfile() {
      const { data } = await api.get(`/users/me`);

      const parsedData = parseObjectPropertiesToCamelCase<IMe>(data);

      setInfo(parsedData);
    }

    const timer = setTimeout(() => loadProfile(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const { selfie } = data;

        const fieldsToRemove = [
          'document',
          'personType',
          'confirmPassword',
          'selfie',
        ];

        const parsedFormData = data;

        fieldsToRemove.forEach(field => delete parsedFormData[field]);

        const formData = new FormData();

        Object.entries(parsedFormData).forEach(([name, value]) => {
          formData.append(name, value);
        });

        const { data: responseData } = await api.put(
          `/users/me`,
          parsedFormData,
        );

        const filesFormData = new FormData();

        filesFormData.append('file', selfie);
        filesFormData.append('userId', user.id);
        filesFormData.append('title[]', 'Selfie');

        await api.post(`/user-files`, filesFormData);

        const parsedResponseData = parseObjectPropertiesToCamelCase<IMe>(
          responseData,
        );

        setInfo(parsedResponseData);
        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Suas informações foram atualizadas com sucesso!',
        });

        if (!completedData) {
          handleCompleteData();
        }
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: `Solicitação não processada!`,
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Um erro desconhecido aconteceu! Por favor, contate o administrador do sistema e reporte o erro',
            });
          }
        }
      }
    },
    [addToast, user.id, completedData, handleCompleteData],
  );

  const handleFindZipcode = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const { data: zipcodeData } = await viaCepApi.get<IZipcode>(
        `/${value}/json`,
      );

      if (zipcodeData.erro) {
        return;
      }

      const addressInputRef = formRef.current?.getFieldRef('address');
      const cityInputRef = formRef.current?.getFieldRef('city');
      const stateInputRef = formRef.current?.getFieldRef('state');

      const stateValue = statesOptions.find(
        item => item.value === zipcodeData.uf,
      );

      addressInputRef.value = zipcodeData.logradouro;
      cityInputRef.value = zipcodeData.localidade;
      stateInputRef.select.setValue(stateValue);
    },
    [],
  );

  const handleInputFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { target } = event;

      if (!target.files || target.files.length < 1) {
        addToast({
          title: 'Nenhum arquivo selecionado!',
          type: 'info',
          message: 'Por favor, selecione um arquivo.',
        });
        throw new Error('No files attached!');
      }

      const file = target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];

      if (
        fileExtension !== '.png' &&
        fileExtension !== '.jpg' &&
        fileExtension !== '.jpeg' &&
        fileExtension !== '.pdf'
      ) {
        addToast({
          title: 'Arquivo inválido!',
          type: 'info',
          message: 'São aceitos apenas arquivos em PNG, JPG, JPEG, e PDF.',
        });
        throw new Error('Invalid file!');
      }

      addToast({
        title: 'Arquivos adicionados com sucesso!',
        type: 'info',
      });
    },
    [addToast],
  );

  return (
    <>
      {!info ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Meu perfil']} />
          </Row>

          <Row>
            <Alert type="info">
              Complete os campos vazios antes de continuar
            </Alert>
          </Row>

          <Row>
            <Card>
              <Card>
                <CardHeader>
                  <h1>Suas informações cadastradas</h1>
                </CardHeader>

                <CardContent>
                  <Form
                    onSubmit={handleFormSubmit}
                    initialData={info}
                    ref={formRef}
                  >
                    <FormRow>
                      <InputGroup>
                        <label>Nome</label>
                        <Input name="name" upperCase={false} icon={FiUser} />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de aniversário</label>
                        <InputMask
                          name="birthdate"
                          defaultValue={
                            info.birthdate
                              ? format(new Date(info.birthdate), 'dd/MM/yyyy')
                              : ''
                          }
                          mask="99/99/9999"
                          icon={FiGift}
                          noUnmask
                        />
                      </InputGroup>

                      {user.role === 'master' && (
                        <InputGroup>
                          <label>Apelido (franqueados)</label>
                          <Input
                            name="nickname"
                            maxLength={50}
                            icon={FiSmile}
                            upperCase={false}
                          />
                        </InputGroup>
                      )}

                      {user.role === 'business' && (
                        <InputGroup>
                          <label>Apelido (franqueados)</label>
                          <Input
                            name="nickname"
                            maxLength={50}
                            icon={FiSmile}
                          />
                        </InputGroup>
                      )}
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Tipo de cadastro</label>
                        <Input name="personType" disabled icon={FiHash} />
                      </InputGroup>

                      <InputGroup>
                        <label>Documento</label>
                        <Input name="document" disabled icon={FiTag} />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Email</label>
                        <Input name="email" icon={FiMail} upperCase={false} />
                      </InputGroup>

                      <InputGroup>
                        <label>Celular</label>
                        <InputMask
                          mask="(99) 99999-9999"
                          name="cellphone"
                          icon={FiSmartphone}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Telefone</label>
                        <InputMask
                          mask="(99) 9999-9999"
                          name="phone"
                          icon={FiPhone}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Endereço</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Código postal (CEP)</label>
                        <InputMask
                          mask="99999999"
                          name="zipcode"
                          icon={FiMapPin}
                          onBlur={handleFindZipcode}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Endereço</label>
                        <Input name="address" maxLength={45} icon={FiHome} />
                      </InputGroup>

                      <InputGroup>
                        <label>Cidade</label>
                        <Input name="city" maxLength={45} icon={FiMap} />
                      </InputGroup>

                      <InputGroup>
                        <label>Estado</label>
                        <Select
                          name="state"
                          options={statesOptions}
                          icon={FiFlag}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Selfie</label>
                        <InputFile
                          name="selfie"
                          onChange={handleInputFileChange}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow buttonWrapper>
                      <Button styleType="success" icon={FiSave}>
                        Salvar alterações
                      </Button>
                    </FormRow>
                  </Form>
                </CardContent>
              </Card>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
