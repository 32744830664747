import { states } from '@helpers/getAllStates';

export const statesOptions = Object.entries(states).map(([value, label]) => ({
  value,
  label: value,
}));

export const clientOldOptions = Object.entries(states).map(([key, value]) => {
  return { value: key, label: value };
});
