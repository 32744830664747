import styled, { css } from 'styled-components';

interface IContainerProps {
  isChecked: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.7rem;
  height: 1.7rem;

  border-radius: 50%;

  background-color: #ebedf3;

  position: relative;

  transition: background-color 0.2s;

  input {
    opacity: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;

    z-index: 5;

    border-radius: 50%;

    &:checked {
      & + svg {
        opacity: 1;
        visibility: visible;

        color: white;
      }
    }
  }

  svg {
    color: #ebedf3;

    opacity: 0;
    visibility: hidden;

    position: absolute;

    transition: visibility 0.2s, opacity 0.2s, color 0.2s;
  }

  ${props =>
    props.isChecked
      ? css`
          background-color: ${({ theme }) => theme.colors.primary};
        `
      : css`
          background-color: #ebedf3;
        `}
`;
