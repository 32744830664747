import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { OptionTypeBase } from 'react-select';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { URLPath } from '@components/layouts/UrlPath';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Textarea } from '@components/elements/Form/Textarea';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { consoleLog } from '@helpers/consoleLog';

import { IDepartment, IPlatform, IFormData } from './interfaces';
import { formValidation } from './validations';
import { getDepartmentsOptions, getPlatformsOptions } from './selectOptions';

export const CreateTickets: FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const [platforms, setPlatforms] = useState<IPlatform[]>();
  const [departments, setDepartments] = useState<IDepartment[]>();
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    async function loadPlatforms() {
      const { data } = await api.get('/platforms');

      setPlatforms(data);
    }

    const timer = setTimeout(() => loadPlatforms(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setLoadingRequest(true);

        await formValidation(data);

        await api.post('/tickets', {
          ...data,
          ownerId: user.id,
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, user.id],
  );

  const platformsOptions = useMemo(() => {
    if (!platforms) {
      return [];
    }

    return getPlatformsOptions(platforms);
  }, [platforms]);

  const departmentsOptions = useMemo(() => {
    if (!departments) {
      return [];
    }

    return getDepartmentsOptions(departments);
  }, [departments]);

  const handleFindDepartmentsByPlatformId = useCallback(
    async (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value } = option;

      try {
        setLoadingRequest(true);

        const { data } = await api.get<any[]>(
          `/platform-departments/platforms/${value}`,
          {
            params: {
              relations: ['department'],
            },
          },
        );

        const parsedData = data.map(item => item.department);

        const departmentsFieldRef = formRef.current?.getFieldRef(
          'departmentId',
        );

        departmentsFieldRef.select.clearValue();

        setDepartments(parsedData);
      } catch (err) {
        consoleLog(err);
      } finally {
        setLoadingRequest(false);
      }
    },
    [],
  );

  return (
    <>
      {!platforms ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Tickets', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo ticket</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Preencha todos os dados para continuar</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Titulo</label>
                      <Input name="title" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Plataforma</label>
                      <Select
                        options={platformsOptions}
                        name="platformId"
                        onChange={handleFindDepartmentsByPlatformId}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Departamento</label>
                      <Select
                        options={departmentsOptions}
                        name="departmentId"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Conteúdo</label>
                      <Textarea name="content" rows={6} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      loading={loadingRequest}
                      styleType="success"
                      icon={FiSave}
                    >
                      Salvar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
