import * as Yup from 'yup';

import { IForm } from './interfaces';

export const validateIsolatedTaxesFormFields = async (data: IForm) => {
  const shape = Yup.object().shape({
    type: Yup.string().required('Preencha este campo!'),
    product: Yup.string().required('Preencha este campo!'),
    value: Yup.number()
      .transform((va, ov) => {
        if (!ov) return undefined;

        return va;
      })
      .required('Preencha este campo!')
      .typeError('Preencha um número!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};

export const validateTableTaxesFormFields = async (data: IForm) => {
  const shape = Yup.object().shape({
    taxesGroupId: Yup.string()
      .required('Preencha este campo!')
      .uuid('Invalido!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
