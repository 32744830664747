import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    clientId: Yup.string()
      .uuid('Valor inválido!')
      .required('Preencha este campo!'),
    investmentPercentageId: Yup.string()
      .uuid('Valor inválido!')
      .required('Preencha este campo!'),
    period: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
    dateInit: Yup.string()
      .max(10, 'Insira uma data completa!')
      .min(10, 'Insira uma data completa!')
      .required('Preencha este campo!'),
    dateEnd: Yup.string()
      .max(10, 'Insira uma data completa!')
      .min(10, 'Insira uma data completa!')
      .required('Preencha este campo!'),
    quota: Yup.number()
      .transform((va, ov) => {
        if (ov === '') return undefined;

        return va;
      })
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
