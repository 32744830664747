import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';

import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Card, CardHeader, CardContent } from '@components/layouts/Card';

import api from '@services/bbankApi';

const NewPaygamentPix: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [pix, setPix] = useState();

  useEffect(() => {
    async function loadPix() {
      const { data } = await api.get('/users-indicated');

      setPix(data);
    }

    loadPix();
  }, []);

  const handleFormSubmit = useCallback(async () => {
    // CODE
  }, []);

  return (
    <>
      {!pix ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Pix', 'Novo PIX']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo Pix</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Preencha todos dados para receber PIX</h1>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewPaygamentPix };
